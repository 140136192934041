import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../../assets/images/Location.svg";
import {
  formatDateYYYYMMDD,
  formatDiffDuration,
  formatGetMonth,
  formatGetMonthWithValue,
  formatGetTime,
  formatGetTimeWithValue,
  formatGetYearsDiff,
} from "../../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  convertToB64,
  getAddressError,
  getMoment,
} from "../../../../common/Utils/commonFunction";
import {
  useAddStaffGeneralManagerDataMutation,
  useDeleteStaffGeneralManagerMutation,
  useEditStaffGeneralManagerDataMutation,
  useReportToListStaffMutation,
} from "../../../../request/Staff/Manager";
import { useLazyGetCompanyListQuery } from "../../../../request/company";
import Translate from "../../../../translation/utils/translateFunction";
import AlertPermission from "../../../AlertPermission";
import Dropzone from "../../../CommonFileUpload/Dropzone";
import FormikDatePicker from "../../../FormikForm/component/FormikDatePicker";
import FormikMultiSelectDropDown from "../../../FormikForm/component/FormikMultiSelectDropDown";
import { FormikPhoneInput } from "../../../FormikForm/component/FormikPhoneInput";
import FormikSearchableDropDown from "../../../FormikForm/component/FormikSearchableDropDown";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import { Persona } from "../../../Persona";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import RadioButtons from "../../../RadioButtons";
import PageHeader from "../../../common/pageHeader";
import Loader from "../../Owner/LoadingComp";
import { GMUser, InitialValues, radioOptions } from "./constants";
import { AddGeneralManagerSchema } from "./utils";
import { FormikPhoneInputWithExtField } from "../../../FormikForm/component/FormikPhoneInputWithExt";
import { handleEventKeySubmit } from "../../../../common/Utils/commonFunction";
import { CustomUserItem } from "../../../common/CustomCellRender";

const AddGeneralManager = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { validations } = useSelector((state) => state?.formanagement);

  const [addMoreGeneralManager, setAddMoreGeneralManager] = useState(false);
  const [show, setshow] = useState(false);
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const initialImage = [state?.profileImageDetails];
  const [showRemoveProfileAlert, setShowRemoveProfileAlert] = useState(false);
  const [base64Img, setBase64Imge] = useState("");
  const [requestTo, setrequestTo] = useState("");
  const [requestToList, setRequestToList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [reportToSearch, setReportToSearch] = useState("");
  const [reporter, setReporter] = useState({});
  const [onSelect, setOnSelect] = useState(false);
  const [showReportToError, setShowReportToError] = useState(false);

  const [
    addGeneralManagerApi,
    {
      data: generalManagerData,
      isSuccess: successfullyAddedGeneralManager,
      isLoading: isAddGMLoading,
      isError: errorAddingGeneralManager,
      error: errorGeneralManager,
    },
  ] = useAddStaffGeneralManagerDataMutation();

  const [
    editGeneralManagerApi,
    {
      data: generalManagerEditData,
      isSuccess: successfullyEditGeneralManager,
      isLoading: isEditGMLoading,
      isError: isErrorEditGeneralManager,
      error: errorEditGeneralManager,
    },
  ] = useEditStaffGeneralManagerDataMutation();

  const [
    deleteGeneralManagerApi,
    {
      data: deleteGeneralManagerData,
      isSuccess: successfullyDeleteGeneralManager,
      isLoading: isGMDeleteLoading,
      isError: isErrorDeleteGeneralManager,
      error: errorDeleteGeneralManager,
    },
  ] = useDeleteStaffGeneralManagerMutation();

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse && !state) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );
      setAllCompanyData(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  const [
    reportToListApi,
    {
      data: reportToList,
      isSuccess: isreportToListSuccess,
      isLoading: isreportToListLoading,
      isError: isreportToListError,
      error: reportToListError,
    },
  ] = useReportToListStaffMutation();

  useEffect(() => {
    if (selectedCompany) {
      let requestObject = {
        searchText: reportToSearch,
        companyId: selectedCompany && selectedCompany,
        role: GMUser,
      };
      reportToListApi(requestObject);
    }
    if (state) {
      let requestObject = {
        searchText: reportToSearch,
        companyId: state?.company?.map((item) => item?._id),
        role: GMUser,
      };
      reportToListApi(requestObject);
    }
  }, [reportToSearch, selectedCompany, state]);

  useEffect(() => {
    if (reportToList && isreportToListSuccess) {
      /* if(!reportToList?.success) {
        setShowReportToError(true);
      } else {
        setSearchableData(reportToList);
      } */
      setSearchableData(reportToList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportToList, isreportToListSuccess]);

  const setSearchableData = (res) => {
    const requestToData = [];

    res?.data.length > 0 &&
      res.data.map((item) => {
        const { name, _id, profileUrl, role, email } = item;
        const obj = {
          id: _id,
          value: name,
          name,
          profileUrl,
          role,
          label: (
            <CustomUserItem
              userName={name}
              title={role ? `${name} | ${role}` : name}
              profileUrl={profileUrl}
              email={email}
            />
          ),
        };
        requestToData.push(obj);
      });

    setRequestToList([...requestToData]);
  };

  useEffect(() => {
    if (state && !onSelect) {
      setOnSelect(false);
      const { reportTo } = state;

      const requestOptions = requestToList.find(
        (item) => reportTo && item.id === reportTo.id
      );
      if (requestOptions) {
        if (reportTo) {
          formikRef.current.setFieldValue(
            "reportTo",
            reportTo?.id || reportTo?._id
          );
          setrequestTo({ ...requestOptions });
        }
      }
      setReporter({
        id: reportTo?.id,
        name: reportTo?.name,
        role: reportTo?.role,
        profileUrl: reportTo?.profileUrl ? reportTo?.profileUrl : "",
      });
    }
  }, [requestToList, state, onSelect]);

  useEffect(() => {
    if (state) {
      formikRef.current.setFieldValue("dob", new Date(state?.dob));
      formikRef.current.setFieldValue(
        "companyId",
        state?.company?.map((item) => item?._id)
      );
      formikRef.current.setFieldValue("name", state?.name);

      if (state?.alternateMobile) {
        formikRef.current.setFieldValue(
          "alternateMobile",

          `${state?.alternateMobileCountryCode}-${state?.alternateMobile}`
        );
      }
      formikRef.current.setFieldValue(
        "alternateMobileShortCode",
        state.alternateMobileShortCode ? state?.alternateMobileShortCode : "JO"
      );
      formikRef.current.setFieldValue(
        "alternateMobileCountryCode",
        state?.alternateMobileCountryCode
          ? state?.alternateMobileCountryCode
          : "962"
      );
      formikRef.current.setFieldValue("designation", state?.designation);
      formikRef.current.setFieldValue("department", state?.department);
      formikRef.current.setFieldValue("email", state?.login.email);

      formikRef.current.setFieldValue(
        "mobile",
        `${state?.login?.countryCode}-${state?.login?.mobile}`
      );
      formikRef.current.setFieldValue("countryCode", state?.login.countryCode);

      if (state?.hrMobile) {
        formikRef.current.setFieldValue(
          "hrMobile",

          `${state?.hrMobileCountryCode}-${state?.hrMobile}`
        );
      }

      formikRef.current.setFieldValue(
        "hrMobileCountryCode",
        state?.hrMobileCountryCode ? state?.hrMobileCountryCode : "962"
      );
      formikRef.current.setFieldValue(
        "mobileShortCode",
        state?.login?.mobileShortCode
      );
      formikRef.current.setFieldValue(
        "hrMobileShortCode",
        state?.hrMobileShortCode ? state?.hrMobileShortCode : "JO"
      );
      formikRef.current.setFieldValue("companyNumber", state?.companyNumber);
      formikRef.current.setFieldValue(
        "companyExtension",
        state?.companyExtension
      );
      formikRef.current.setFieldValue("gender", state?.gender);
      formikRef.current.setFieldValue("resFlat", state?.residenceAddress.flat);
      formikRef.current.setFieldValue(
        "resState",
        state?.residenceAddress.state
      );
      formikRef.current.setFieldValue(
        "resCountry",
        state?.residenceAddress.country
      );
      formikRef.current.setFieldValue("resCity", state?.residenceAddress.city);
      formikRef.current.setFieldValue(
        "resZipCode",
        state?.residenceAddress.zipCode
      );
      formikRef.current.setFieldValue(
        "resAddress",
        state?.residenceAddress.address
      );
      formikRef.current.setFieldValue(
        "resLat",
        state?.residenceAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "resLng",
        state?.residenceAddress.latlong.longitude
      );
      formikRef.current.setFieldValue("workFlat", state?.workAddress.flat);
      formikRef.current.setFieldValue("workState", state?.workAddress.state);
      formikRef.current.setFieldValue(
        "workCountry",
        state?.workAddress.country
      );
      formikRef.current.setFieldValue("workCity", state?.workAddress.city);
      formikRef.current.setFieldValue(
        "workZipCode",
        state?.workAddress.zipCode
      );
      formikRef.current.setFieldValue(
        "workAddress",
        state?.workAddress.address
      );
      formikRef.current.setFieldValue(
        "workLat",
        state?.workAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "workLng",
        state?.workAddress.latlong.longitude
      );
      formikRef.current.setFieldValue(
        "reportTo",
        state?.reportTo?.id || state?.reportTo?._id
      );
      if (
        state?.profileImageDetails &&
        Object.keys(state?.profileImageDetails).includes("url")
      ) {
        setfilesAdded([state?.profileImageDetails]);
        setImages([state?.profileImageDetails?.url]);
      }
      if (state?.company.length) {
        let companyData = [...state?.company];
        let dropDownData = companyData?.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        setAllCompanyData(dropDownData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (errorAddingGeneralManager && errorGeneralManager) {
      errorGeneralManager?.data?.error
        ? errorGeneralManager.data.error.length > 0 &&
          errorGeneralManager.data.error.map((errorItem) => {
            const isPresent = getAddressError(
              errorItem.param,
              formikRef.current?.setFieldError
            );
            !isPresent &&
              formikRef.current.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({
            message: errorGeneralManager?.data?.message,
            type: "error",
          });
    }

    if (successfullyAddedGeneralManager && generalManagerData) {
      showToast({ message: generalManagerData.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      if (addMoreGeneralManager) {
        formikRef.current.resetForm();
        InitialValues.mobile = "962";
        InitialValues.alternateMobile = "962";
        InitialValues.hrMobile = "962";
        InitialValues.dob = "";
        setrequestTo("");
        formikRef.current.setFieldValue("dob", "");
        formikRef.current.setFieldValue("gender", "MALE");
      } else {
        navigate("/generalManagerListing");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalManagerData, errorGeneralManager]);

  useEffect(() => {
    if (errorEditGeneralManager && isErrorEditGeneralManager) {
      errorEditGeneralManager?.data?.error
        ? errorEditGeneralManager?.data?.error.length > 0 &&
          errorEditGeneralManager.data.error.map((errorItem) => {
            const isPresent = getAddressError(
              errorItem.param,
              formikRef.current?.setFieldError
            );
            !isPresent &&
              formikRef.current.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({
            message: errorEditGeneralManager?.data?.message,
            type: "error",
          });
    }

    if (successfullyEditGeneralManager && generalManagerEditData) {
      showToast({ message: generalManagerEditData.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      formikRef.current.resetForm();
      navigate("/generalManagerListing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    generalManagerEditData,
    errorGeneralManager,
    errorEditGeneralManager,
    isErrorEditGeneralManager,
  ]);

  useEffect(() => {
    if (errorDeleteGeneralManager && isErrorDeleteGeneralManager) {
      errorDeleteGeneralManager.data.error
        ? errorDeleteGeneralManager.data.error.length > 0 &&
          errorDeleteGeneralManager.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({
            message: errorDeleteGeneralManager?.data?.message,
            type: "error",
          });
    }

    if (successfullyDeleteGeneralManager && deleteGeneralManagerData) {
      showToast({ message: deleteGeneralManagerData.message, type: "success" });
      navigate("/generalManagerListing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDeleteGeneralManager, deleteGeneralManagerData]);

  const onSubmit = (companyFormValues) => {
    const countryCode =
      companyFormValues.countryCode && companyFormValues.countryCode;

    const HRcountryCode =
      companyFormValues.hrMobile && companyFormValues.hrMobileCountryCode;

    let requestObjectGeneralManager = {
      companyId: state
        ? state?.company.map((ele) => ele._id)
        : companyFormValues?.companyId,
      name: companyFormValues.name,
      gender: companyFormValues.gender.toUpperCase(),
      dob:
        moment(companyFormValues.dob).creationData().format === "YYYY-MM-DD"
          ? companyFormValues.dob
          : formatDateYYYYMMDD(companyFormValues.dob),

      designation: companyFormValues.designation,
      department: companyFormValues.department,
      countryCode: countryCode,

      mobile: companyFormValues.mobile?.split("-")[1],

      mobileShortCode: companyFormValues.mobileShortCode,
      companyExtension: companyFormValues.companyExtension,
      companyNumber: companyFormValues.companyNumber,
      // hrMobileCountryCode: HRcountryCode,
      // hrMobile: companyFormValues.hrMobile,
      // hrMobileShortCode: companyFormValues.hrMobileShortCode,
      email: companyFormValues.email,

      residenceAddress: {
        latitude: companyFormValues.resLat,
        longitude: companyFormValues.resLng,
        latlong: {
          latitude: companyFormValues.resLat,
          longitude: companyFormValues.resLng,
        },
        flat: companyFormValues.resFlat,
        address: companyFormValues.resAddress,
        country: companyFormValues.resCountry,
        state: companyFormValues.resState,
        city: companyFormValues.resCity,
        zipCode: companyFormValues.resZipCode,
      },
      workAddress: {
        latitude: companyFormValues.workLat,
        longitude: companyFormValues.workLng,
        latlong: {
          latitude: companyFormValues.workLat,
          longitude: companyFormValues.workLng,
        },

        flat: companyFormValues.workFlat,
        address: companyFormValues.workAddress,
        country: companyFormValues.workCountry,
        state: companyFormValues.workState,
        countryISO: "IN",
        city: companyFormValues.workCity,
        zipCode: companyFormValues.workZipCode,
      },
      // reportTo,
      reportTo: reporter,
      role: state
        ? state?.role
        : {
            type: "GM",
          },
      emailVerified: "YES",
      mobileVerified: "YES",
      primary: "NO",
      system: "NO",
    };

    if (
      companyFormValues.hrMobile.length > 0 &&
      companyFormValues?.hrMobile.includes("-")
    ) {
      requestObjectGeneralManager = {
        ...requestObjectGeneralManager,
        hrMobileCountryCode: companyFormValues.hrMobileCountryCode,
        hrMobileShortCode: companyFormValues.hrMobileShortCode,

        hrMobile: companyFormValues.hrMobile?.split("-")[1],
      };
    } else {
      requestObjectGeneralManager = {
        ...requestObjectGeneralManager,
        hrMobileCountryCode: "",
        hrMobile: "",
        hrMobileShortCode: "",
      };
    }

    if (
      companyFormValues?.alternateMobile?.length > 0 &&
      companyFormValues?.alternateMobile.includes("-")
    ) {
      requestObjectGeneralManager = {
        ...requestObjectGeneralManager,
        alternateMobile: companyFormValues?.alternateMobile?.split("-")[1],
        alternateMobileCountryCode:
          companyFormValues?.alternateMobileCountryCode,
        alternateMobileShortCode: companyFormValues.alternateMobileShortCode,
      };
    } else {
      requestObjectGeneralManager = {
        ...requestObjectGeneralManager,
        alternateMobile: "",
        alternateMobileCountryCode: "",
        alternateMobileShortCode: "",
      };
    }

    if (filesAdded.length > 0) {
      if (filesAdded.every((value) => initialImage?.includes(value))) {
        requestObjectGeneralManager = {
          ...requestObjectGeneralManager,
          profileUrl: state?.profileUrl,
          profileImageDetails: state?.profileImageDetails,
        };
      } else {
        const b64Object = {
          ...filesAdded.map((ele) => ({
            url: ele?.b64,
            type: ele?.type,
            profileFileName: ele?.name,
            profileFileExt: ele?.name.split(".").pop(),
          }))[0],
        };

        requestObjectGeneralManager = {
          ...requestObjectGeneralManager,
          webProfileUrl: b64Object,
        };
      }
    } else {
      requestObjectGeneralManager = {
        ...requestObjectGeneralManager,
      };
    }

    if (state) {
      editGeneralManagerApi({
        body: requestObjectGeneralManager,
        id: state._id,
      });
    } else {
      addGeneralManagerApi(requestObjectGeneralManager);
    }
  };

  const deleteGeneralManager = () => {
    setshow(false);
    deleteGeneralManagerApi(state._id);
  };
  const onCancel = () => setshow(false);

  const addFiles = async (file) => {
    if (file) {
      const base64 = await convertToB64(file);
      setBase64Imge(base64);
    }

    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
        ? URL.createObjectURL(file)
        : null;
    if (url) {
      file.preview = url;
    }
    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setfilesAdded([...filesAdded]);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );
      setImages(newImages);
    }
  }, [filesAdded]);

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };

  const removeFile = (file) => {
    setfilesAdded([]);
    setBase64Imge("");
    onCancelProfile();
  };

  const onCancelProfile = () => setShowRemoveProfileAlert(false);

  const currentLocationData = (places, addressType) => {
    if (addressType) {
      formikRef.current.setFieldValue("resAddress", places.formatted_address);
      formikRef.current.setFieldValue("resLat", places.geometry.location.lat());
      formikRef.current.setFieldValue("resLng", places.geometry.location.lng());

      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("resCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("resState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("resCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("resZipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("resZipCode", "");
        }
      });
    } else {
      formikRef.current.setFieldValue("workAddress", places.formatted_address);
      formikRef.current.setFieldValue(
        "workLat",
        places.geometry.location.lat()
      );
      formikRef.current.setFieldValue(
        "workLng",
        places.geometry.location.lng()
      );

      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("workCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("workState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("workCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("workZipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("workZipCode", "");
        }
      });
    }
  };
  const onDobChange = (value) => {
    const todaysDate = getMoment().toDate();
    const diff = formatDiffDuration(todaysDate, value);
    const getDate = formatGetTime();
    const getSelectedDate = formatGetTimeWithValue(value);
    const getMonth = formatGetMonth();
    const getSelectedMonth = formatGetMonthWithValue(value);
    const getYearsDiff = formatGetYearsDiff(value);
    if (
      diff >= 18 ||
      (getYearsDiff >= 18 &&
        getDate === getSelectedDate &&
        getMonth === getSelectedMonth)
    ) {
      formikRef.current.setFieldValue("dob", new Date(value));
      InitialValues.dob = new Date(value);
    } else {
      showToast({ message: Translate("addManager:ageAlert"), type: "error" });
      formikRef.current.setFieldValue("dob", "");
      InitialValues.dob = "";
    }
  };

  useEffect(() => {
    return () => {
      InitialValues.dob = "";
    };
  }, []);

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddGeneralManagerSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <div className="row container_spacing">
            <div className="card card_spacing">
              <PageHeader
                title={
                  state
                    ? Translate("addManager:editGeneralManagerHeading")
                    : Translate("addManager:addGeneralManagerHeading")
                }
                onClick={() => {
                  state
                    ? navigate("/viewGeneralManager", { state: state._id })
                    : navigate("/generalManagerListing");
                }}
                RightContainer={() =>
                  state?.isDeleteable && (
                    <div className="card-options">
                      <i
                        className="fe fe-trash-2 cursor-pointer"
                        onClick={() => setshow(true)}
                        data-toggle="tooltip"
                        title={Translate("common:deleteToolTip")}
                      ></i>
                    </div>
                  )
                }
              />
              <div
                className="card-body"
                onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
              >
                <div className="row imageDisplay mb-2">
                  <div className="form-group col-md-4 col-sm-8">
                    <Persona
                      isProfile={!state}
                      name={state?.name}
                      isBase64={base64Img ? true : false}
                      image={
                        filesAdded?.length && images?.length
                          ? images[0].includes(VALIDATION_BUCKETURL)
                            ? images[0]
                            : base64Img
                          : ""
                      }
                      size={96}
                    />
                    <Dropzone
                      onfileSelect={(filesArray) => addFiles(filesArray)}
                      acceptedImages={acceptedFileType}
                      maximumFiles={1}
                      fileLength={filesAdded.length}
                      maxFileSize={50 * 1000000}
                      className="staffProfileIcon"
                      isProfile
                      deleteAlert={() => {
                        setShowRemoveProfileAlert(true);
                      }}
                      isShowCameraDeleteIcon={filesAdded?.length}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikMultiSelectDropDown
                      options={allCompanyData && allCompanyData}
                      name="companyId"
                      label={Translate("addManager:selectCompany")}
                      placeholder={Translate("addManager:dropdownPlaceholder")}
                      disabled={state}
                      onSelectChange={(value) => setSelectedCompany(value)}
                      required
                      autoFocus={!state}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="name"
                      label={Translate("addManager:gmName")}
                      placeholder={Translate("addManager:gmName")}
                      autoComplete={"off"}
                      maxLength={validations?.nameLength.MAX}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikDatePicker
                      label={Translate("addManager:dob")}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0 "
                      format="yyyy-MM-dd"
                      name="dob"
                      value={values.dob}
                      onChange={onDobChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="designation"
                      label={Translate("addManager:designation")}
                      placeholder={Translate("addManager:designation")}
                      autoComplete={"off"}
                      maxLength={validations?.designationLength.MAX}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="department"
                      label={Translate("addManager:department")}
                      placeholder={Translate("addManager:department")}
                      autoComplete={"off"}
                      maxLength={validations?.departmentLength.MAX}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    {/* <FormikTextField
                      name="reportTo"
                      value={state ? values?.reportTo : userData?.name}
                      label={Translate("addOwner:reportTo")}
                      placeholder={Translate("addOwner:reportTo")}
                      autoComplete={"off"}
                      disabled={true}
                    /> */}
                    <FormikSearchableDropDown
                      selected={requestTo}
                      onSelect={(value) => {
                        setrequestTo(value);
                        setFieldValue("reportTo", value.id);

                        setReporter({
                          id: value.id,
                          name: value.name,
                          profileUrl: value.profileUrl,
                          role: value.role,
                        });
                        setOnSelect(true);
                      }}
                      options={requestToList}
                      onChange={(text) => setReportToSearch(text.trim())}
                      placeholder={Translate("addManager:reportTo")}
                      label={Translate("addManager:reportTo")}
                      name="reportTo"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      placeholder={Translate(
                        "addManager:contactNumberPlaceHolder"
                      )}
                      label={Translate("addManager:contactNumber")}
                      name="mobile"
                      value={values.mobile}
                      maxLength={validations?.contactPhoneLength.MAX}
                      onChange={(value, country) => {
                        setFieldValue("mobile", value);
                        setFieldValue(
                          "mobileShortCode",
                          country?.countryCode?.toUpperCase()
                        );
                        setFieldValue("countryCode", country?.dialCode);
                      }}
                      autoComplete={"off"}
                      required
                      isLogin
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      label={Translate("addOwner:alternateMobile")}
                      placeholder={Translate(
                        "addOwner:contactNumberPlaceholder"
                      )}
                      name="alternateMobile"
                      value={values.alternateMobile}
                      maxLength={validations?.contactPhoneLength.MAX}
                      onChange={(value, country) => {
                        setFieldValue("alternateMobile", value);
                        setFieldValue(
                          "alternateMobileShortCode",
                          country?.countryCode?.toUpperCase()
                        );
                        setFieldValue(
                          "alternateMobileCountryCode",
                          country?.dialCode
                        );
                      }}
                      autoComplete={"off"}
                      isLogin
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      label={Translate("addManager:hrContactNumber")}
                      name="hrMobile"
                      value={values.hrMobile}
                      placeholder={Translate(
                        "addManager:contactNumberPlaceHolder"
                      )}
                      maxLength={validations?.contactPhoneLength.MAX}
                      onChange={(value, country) => {
                        setFieldValue("hrMobile", value);
                        setFieldValue(
                          "hrMobileShortCode",
                          country?.countryCode?.toUpperCase()
                        );
                        setFieldValue("hrMobileCountryCode", country?.dialCode);
                      }}
                      autoComplete={"off"}
                      isLogin
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInputWithExtField
                      name="companyNumber"
                      extName="companyExtension"
                      label={Translate("addManager:companyExtensionNumber")}
                      placeholder={Translate(
                        "addManager:extensionNumberPlaceholder"
                      )}
                      extPlaceholder={Translate(
                        "addManager:companyPhoneExtensionPlaceholder"
                      )}
                      autoComplete={"off"}
                      maxLength={validations?.telephoneLength.MAX}
                      extMaxLength={validations?.companyExtension.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="email"
                      label={Translate("addManager:email")}
                      placeholder={Translate("addManager:emailPlaceholder")}
                      autoComplete={"off"}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <div className="AddressLabel">
                      <StyledTypography variant="subtitle1">
                        {Translate("addEmployee:address")}
                        <StyledTypography
                          sx={{ color: "red" }}
                          component="span"
                        >
                          {" *"}
                        </StyledTypography>
                      </StyledTypography>
                    </div>
                    <FormikTextField
                      name="resAddress"
                      placeholder={Translate("addManager:addressPlaceholder_1")}
                      autoComplete={"off"}
                      isLocation={true}
                      RenderRightContainer={
                        <LocationIcon className="formikLocationIcon" />
                      }
                      locationValue={(currentLocation) => {
                        currentLocationData(currentLocation, true);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="resFlat"
                      placeholder={Translate("addManager:addressPlaceholder_2")}
                      autoComplete={"off"}
                      maxLength={validations?.landmarkLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="resCountry"
                      placeholder={Translate("addManager:countryPlaceholder")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="resState"
                      placeholder={Translate("addManager:statePlaceholder")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="resCity"
                      placeholder={Translate("addManager:cityPlaceholder")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="resZipCode"
                      placeholder={Translate("addManager:zipcodePlaceholder")}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <div className="AddressLabel">
                      <StyledTypography variant="subtitle1">
                        {Translate("addManager:workAddress")}
                        <StyledTypography
                          sx={{ color: "red" }}
                          component="span"
                        >
                          {" *"}
                        </StyledTypography>
                      </StyledTypography>
                    </div>
                    <FormikTextField
                      name="workAddress"
                      placeholder={Translate("addManager:addressPlaceholder_3")}
                      autoComplete={"off"}
                      isLocation={true}
                      RenderRightContainer={
                        <LocationIcon className="formikLocationIcon" />
                      }
                      locationValue={(currentLocation) => {
                        currentLocationData(currentLocation, false);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="workFlat"
                      placeholder={Translate("addManager:addressPlaceholder_4")}
                      autoComplete={"off"}
                      maxLength={validations?.landmarkLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="workCountry"
                      maxLength={validations?.cityLimit.MAX}
                      placeholder={Translate("addManager:countryPlaceholder")}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="workState"
                      maxLength={validations?.cityLimit.MAX}
                      placeholder={Translate("addManager:statePlaceholder")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="workCity"
                      placeholder={Translate("addManager:cityPlaceholder")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="workZipCode"
                      placeholder={Translate("addManager:zipcodePlaceholder")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <RadioButtons
                      radioOptions={radioOptions}
                      defaultChecked={
                        state?.gender ? (state?.gender === "MALE" ? 0 : 1) : 0
                      }
                      label={Translate("addManager:gender")}
                      onPress={(value) => {
                        setFieldValue("gender", value.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="submit"
                    className="btn btn-primary saveButton"
                    onClick={() => {
                      handleSubmit();
                      setAddMoreGeneralManager(false);
                    }}
                  >
                    {Translate("addManager:save")}
                  </button>

                  {!state && (
                    <button
                      type="submit"
                      className="btn btn-white addButton"
                      onClick={() => {
                        handleSubmit();
                        setAddMoreGeneralManager(true);
                      }}
                    >
                      {Translate("addManager:addMore")}
                    </button>
                  )}
                </div>
              </div>
              <Loader
                loading={isGMDeleteLoading || isAddGMLoading || isEditGMLoading}
              />
              <AlertPermission
                show={show}
                subTitle={Translate("addManager:alertGM")}
                onOk={deleteGeneralManager}
                onCanel={onCancel}
              />
              <AlertPermission
                show={showRemoveProfileAlert}
                subTitle={Translate("common:deleteProfileAlert")}
                onOk={removeFile}
                onCanel={onCancelProfile}
              />
              {showReportToError && (
                <AlertPermission
                  show={showReportToError}
                  subTitle={reportToList?.message}
                  onOk={() => setShowReportToError(false)}
                  isBtnAlignLeft
                  hideCancel
                />
              )}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default AddGeneralManager;
