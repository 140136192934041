import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { colors } from "../../../../common/Theme/Colors";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  convertStringForHRManager,
  convertToB64,
} from "../../../../common/Utils/commonFunction";
import { useLazyGetCompanyListQuery } from "../../../../request/company";
import Translate from "../../../../translation/utils/translateFunction";
import AlertPermission from "../../../AlertPermission";
import Dropzone from "../../../CommonFileUpload/Dropzone";
import { Persona } from "../../../Persona";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import Loader from "../../Owner/LoadingComp";
import EmployeeBasicForm from "./EmployeeBasicForm";
import { EmployeeUser } from "./constants";
import PageHeader from "../../../common/pageHeader";
import { useReportToListStaffMutation } from "../../../../request/Staff/Manager";
import { handleEventKeySubmit } from "../../../../common/Utils/commonFunction";
import { CustomUserItem } from "../../../common/CustomCellRender";

const EmployeeForm = ({
  isEdit,
  isView,
  filesAdded,
  setfilesAdded,
  images,
  isLoading,
  values,
  handleSubmit,
  showAlert,
  deleteEmployee,
  onAddMore,
  setShowAlert,
  employeeData,
  isAdd,
  isRating,
  setIsRating,
  isRedZone,
  setIsRedZone,
  setReportTo,
  setSelectedCompanyLabel,
}) => {
  const navigate = useNavigate();
  const [addMoreEmployee, setAddMoreEmployee] = useState(false);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [base64Img, setBase64Imge] = useState("");
  const [showRemoveProfileAlert, setShowRemoveProfileAlert] = useState(false);
  const { setFieldValue } = useFormikContext();
  const [requestTo, setrequestTo] = useState("");
  const [requestToList, setRequestToList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [reportToSearch, setReportToSearch] = useState("");
  const [reporter, setReporter] = useState({});
  const [onSelect, setOnSelect] = useState(false);
  const [showReportToError, setShowReportToError] = useState(false);

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );
      setAllCompanyData(companyData);
    }
  }, [companyResponse]);

  const [
    reportToListApi,
    {
      data: reportToList,
      isSuccess: isreportToListSuccess,
      isLoading: isreportToListLoading,
      isError: isreportToListError,
      error: reportToListError,
    },
  ] = useReportToListStaffMutation();

  useEffect(() => {
    if (selectedCompany && !isView) {
      let requestObject = {
        searchText: reportToSearch,
        companyId: [selectedCompany] || [],
        role: EmployeeUser,
      };
      reportToListApi(requestObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportToSearch, selectedCompany, isEdit]);

  useEffect(() => {
    if (employeeData?._id && isView) {
      const { name, profileUrl, role, email } = employeeData?.reportTo;
      setrequestTo({
        value: name,
        label: (
          <CustomUserItem
            userName={name}
            title={role ? `${name} | ${convertStringForHRManager(role)}` : name}
            profileUrl={profileUrl}
            email={email}
          />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData, isView]);

  useEffect(() => {
    if (reportToList && isreportToListSuccess) {
      /* if(!reportToList?.success) {
        setShowReportToError(true);
      } else {
        setSearchableData(reportToList);
      } */
      if (!selectedCompany?.length) {
        resetOnCompanyChange();
      }

      setSearchableData(reportToList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportToList, isreportToListSuccess]);

  const resetOnCompanyChange = () => {
    setFieldValue("reportTo", "");
    setrequestTo("");
    setReportTo && setReportTo({});
  };

  const setSearchableData = (res) => {
    const requestToData = [];

    res?.data.length > 0 &&
      res.data.map((item) => {
        const { name, _id, profileUrl, role, email } = item;
        const obj = {
          id: _id,
          value: name,
          name,
          profileUrl,
          role,
          label: (
            <CustomUserItem
              userName={name}
              title={
                role ? `${name} | ${convertStringForHRManager(role)}` : name
              }
              profileUrl={profileUrl}
              email={email}
            />
          ),
        };
        requestToData.push(obj);
      });

    setRequestToList([...requestToData]);
  };

  useEffect(() => {
    if (isEdit && !onSelect && employeeData) {
      setOnSelect(false);
      const { reportTo } = employeeData;

      const requestOptions = requestToList.find(
        (item) => reportTo && item.id === reportTo.id
      );
      if (requestOptions) {
        if (reportTo) {
          setFieldValue("reportTo", reportTo?.id || reportTo?._id);
          setrequestTo({ ...requestOptions });
        }
      }
      setReporter({
        id: reportTo?.id,
        name: reportTo?.name,
        role: reportTo?.role,
        profileUrl: reportTo?.profileUrl ? reportTo?.profileUrl : "",
      });
      setReportTo({
        id: reportTo?.id,
        name: reportTo?.name,
        role: reportTo?.role,
        profileUrl: reportTo?.profileUrl ? reportTo?.profileUrl : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestToList, employeeData, onSelect, isEdit]);

  useEffect(() => {
    if (employeeData) {
      const companies = employeeData?.company?.map((item) => item?._id);

      setFieldValue("companyId", companies[0]);
      setSelectedCompany(companies[0]);
      setFieldValue("name", employeeData?.name);
      setFieldValue("dob", new Date(employeeData?.dob));
      setFieldValue("designation", employeeData?.designation);
      setFieldValue("department", employeeData?.department);
      setFieldValue(
        "mobile",
        `${employeeData?.login?.countryCode}-${employeeData?.login?.mobile}`
      );
      setFieldValue("mobileShortCode", employeeData?.login.mobileShortCode);
      setFieldValue("countryCode", employeeData?.login.countryCode);

      if (employeeData?.alternateMobile) {
        setFieldValue(
          "alternateMobile",

          `${employeeData?.alternateMobileCountryCode}-${employeeData?.alternateMobile}`
        );
      }
      setFieldValue(
        "alternateMobileCountryCode",
        employeeData?.alternateMobileCountryCode
          ? employeeData?.alternateMobileCountryCode
          : "962"
      );
      setFieldValue(
        "hrMobileCountryCode",
        employeeData?.hrMobileCountryCode
          ? employeeData?.hrMobileCountryCode
          : "962"
      );
      // setFieldValue("hrMobile", employeeData?.hrMobile);
      if (employeeData?.hrMobile) {
        setFieldValue(
          "hrMobile",

          `${employeeData?.hrMobileCountryCode}-${employeeData?.hrMobile}`
        );
      }
      setFieldValue(
        "hrMobileShortCode",
        employeeData?.hrMobileShortCode ? employeeData?.hrMobileShortCode : "JO"
      );

      setFieldValue(
        "alternateMobileShortCode",
        employeeData?.alternateMobileShortCode
          ? employeeData?.alternateMobileShortCode
          : "JO"
      );
      setFieldValue("companyNumber", employeeData?.companyNumber);
      setFieldValue("companyExtension", employeeData?.companyExtension);
      setFieldValue("email", employeeData?.login.email);
      setFieldValue("resFlat", employeeData?.residenceAddress.flat);
      setFieldValue("resState", employeeData?.residenceAddress.state);
      setFieldValue("resCountry", employeeData?.residenceAddress.country);
      setFieldValue("resCity", employeeData?.residenceAddress.city);
      setFieldValue("resZipCode", employeeData?.residenceAddress.zipCode);
      setFieldValue("resAddress", employeeData?.residenceAddress.address);
      setFieldValue("resLat", employeeData?.residenceAddress.latlong.latitude);
      setFieldValue("resLng", employeeData?.residenceAddress.latlong.longitude);
      setFieldValue("workFlat", employeeData?.workAddress.flat);
      setFieldValue("workState", employeeData?.workAddress.state);
      setFieldValue("workCountry", employeeData?.workAddress.country);
      setFieldValue("workCity", employeeData?.workAddress.city);
      setFieldValue("workZipcode", employeeData?.workAddress.zipCode);
      setFieldValue("workAddress", employeeData?.workAddress.address);
      setFieldValue("workLat", employeeData?.workAddress.latlong.latitude);
      setFieldValue("workLng", employeeData?.workAddress.latlong.longitude);
      setFieldValue("gender", employeeData?.gender);
      setFieldValue(
        "reportTo",
        employeeData?.reportTo?.id || employeeData?.reportTo?._id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData]);

  const addFiles = async (file) => {
    if (file) {
      const base64 = await convertToB64(file);
      setBase64Imge(base64);
    }
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
        ? URL.createObjectURL(file)
        : null;
    if (url) {
      file.preview = url;
    }
    if (filesAdded?.length < 1) {
      filesAdded.push(file);
      setfilesAdded([...filesAdded]);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };

  const removeFile = () => {
    setBase64Imge("");

    setfilesAdded([]);
    onCancelProfile();
  };

  const onCancel = () => setShowAlert(false);
  const onCancelProfile = () => setShowRemoveProfileAlert(false);
  const getPageTitle = () => {
    if (isView) {
      return Translate("addEmployee:viewEmployee");
    }
    if (isEdit) {
      return Translate("addEmployee:editEmployee");
    }
    return Translate("addEmployee:employeeTitle");
  };

  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <PageHeader
            title={getPageTitle()}
            onClick={() => {
              isEdit
                ? navigate("/viewEmployee", { state: employeeData?._id })
                : navigate("/employeeListing");
            }}
            RightContainer={() => (
              <>
                {isView && employeeData?.isEditable && (
                  <div className="card-options">
                    <i
                      className="icon-color ml-3 fa fa-pencil-square-o cursor-pointer"
                      onClick={() =>
                        navigate("/editEmployee", {
                          state: employeeData,
                        })
                      }
                      data-toggle="tooltip"
                      title={Translate("common:editToolTip")}
                    ></i>
                  </div>
                )}
                {isEdit && employeeData?.isDeleteable && (
                  <div className="card-options">
                    <i
                      className="fe fe-trash-2 cursor-pointer"
                      onClick={() => setShowAlert(true)}
                      data-toggle="tooltip"
                      title={Translate("common:deleteToolTip")}
                    ></i>
                  </div>
                )}
              </>
            )}
          />
          <div
            className="card-body"
            onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
          >
            <div className="row imageDisplay mb-2">
              <div className="form-group col-md-4 col-sm-8">
                <Persona
                  isProfile={isAdd}
                  name={employeeData?.name}
                  isBase64={base64Img ? true : false}
                  image={
                    filesAdded?.length && images?.length
                      ? images[0].includes(VALIDATION_BUCKETURL)
                        ? images[0]
                        : base64Img
                      : ""
                  }
                  size={96}
                />
                {!isView && (
                  <Dropzone
                    onfileSelect={(filesArray) => addFiles(filesArray)}
                    acceptedImages={acceptedFileType}
                    maximumFiles={1}
                    fileLength={filesAdded.length}
                    maxFileSize={50 * 1000000}
                    className="staffProfileIcon"
                    isProfile
                    deleteAlert={() => {
                      setShowRemoveProfileAlert(true);
                    }}
                    isShowCameraDeleteIcon={
                      (isAdd || isEdit) && filesAdded?.length
                    }
                  />
                )}
              </div>
            </div>
            <EmployeeBasicForm
              allCompanyData={allCompanyData}
              setFieldValue={setFieldValue}
              isView={isView}
              values={values}
              isEdit={isEdit}
              employeeData={employeeData}
              isAdd={isAdd}
              requestTo={requestTo}
              setrequestTo={setrequestTo}
              setReporter={setReporter}
              setOnSelect={setOnSelect}
              requestToList={requestToList}
              setReportToSearch={setReportToSearch}
              setReportTo={setReportTo}
              setSelectedCompany={setSelectedCompany}
              selectedCompany={selectedCompany}
              resetOnCompanyChange={resetOnCompanyChange}
              setSelectedCompanyLabel={setSelectedCompanyLabel}
            />

            {!isView && (
              <div className="card-footer text-right">
                <button
                  type="submit"
                  className="btn btn-primary saveButton"
                  onClick={() => {
                    handleSubmit();
                    setAddMoreEmployee(false);
                  }}
                >
                  {Translate("addEmployee:save")}
                </button>
                {!isEdit && (
                  <button
                    type="submit"
                    className="btn btn-white addButton"
                    onClick={() => {
                      onAddMore(true);
                      handleSubmit();
                      setAddMoreEmployee(true);
                    }}
                  >
                    {Translate("addEmployee:addMore")}
                  </button>
                )}
              </div>
            )}

            {isView && isRating && (
              <div>
                <div>
                  <span style={{ color: colors.primary_003 }}>
                    {Translate("common:rate")}
                  </span>
                </div>
                <div className="row">
                  <h7
                    className="nav-link"
                    style={{
                      color: isRedZone ? colors.red : colors.green,
                      paddingBottom: 10,
                    }}
                  >
                    {isRedZone ? "Red zone" : "Green zone"}
                  </h7>
                  <Switch
                    onChange={() => {
                      setIsRedZone((prev) => !prev);
                    }}
                    checked={isRedZone}
                    height={25}
                    width={50}
                    onHandleColor={colors.primary}
                    onColor={colors.primary_002}
                  />
                </div>
              </div>
            )}

            {isView &&
              (employeeData?.isRateable ||
                employeeData?.isPerformanceAccess) && (
                <div className="card-footer text-right">
                  {employeeData?.isPerformanceAccess && (
                    <button
                      type="submit"
                      className={`btn btn-primary saveButton ${
                        isRating && "staffPerformanceBtn"
                      }`}
                      onClick={() =>
                        navigate("/staff/performance", {
                          state: { userData: employeeData },
                        })
                      }
                    >
                      {Translate("common:performance")}
                    </button>
                  )}
                  {employeeData?.isRateable &&
                    (!isRating ? (
                      <button
                        type="submit"
                        className="btn btn-white addButton"
                        onClick={() => {
                          setIsRating((prev) => !prev);
                        }}
                      >
                        {Translate("common:rate")}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary saveButton"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {Translate("addManager:save")}
                      </button>
                    ))}
                </div>
              )}
          </div>
        </div>
      </div>
      <AlertPermission
        show={showAlert}
        subTitle={Translate("addEmployee:deleteErrorMsg")}
        onOk={deleteEmployee}
        onCanel={onCancel}
      />
      <AlertPermission
        show={showRemoveProfileAlert}
        subTitle={Translate("common:deleteProfileAlert")}
        onOk={removeFile}
        onCanel={onCancelProfile}
      />
      {showReportToError && (
        <AlertPermission
          show={showReportToError}
          subTitle={reportToList?.message}
          onOk={() => setShowReportToError(false)}
          isBtnAlignLeft
          hideCancel
        />
      )}
      <Loader loading={isLoading} />
    </>
  );
};
export default EmployeeForm;
