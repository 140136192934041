import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetCompanyListQuery } from "../../../../request/company";
import {
  useDeleteEmployeeDataMutation,
  useEditEmployeeMutation,
} from "../../../../request/Staff/Employee";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { InitialValues } from "../Components/constants";
import { formatDateYYYYMMDD } from "../../../../common/Utils/DateFormater";
import { AddEmployeeSchema } from "../Components/utils";
import EmployeeForm from "../Components/EmployeeForm";
import { getAddressError } from "../../../../common/Utils/commonFunction";

const EditEmployee = () => {
  const { userData } = useSelector((state) => state?.formanagement);
  const formikRef = useRef(null);

  const navigate = useNavigate();
  const [reportTo, setReportTo] = useState({});
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const { state } = useLocation();
  const initialImage = [state?.profileImageDetails];
  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );
      setAllCompanyData(companyData);
    }
  }, [companyResponse]);

  const [
    editEmployeeApi,
    {
      data: editEmployeeData,
      isSuccess: successfullyEditEmployee,
      isError: isErrorEditEmployee,
      error: errorEditEmployee,
      isLoading: isLoadingEditEmployee,
    },
  ] = useEditEmployeeMutation();
  const [
    deleteEmployeeApi,
    {
      data: employeeDeleteData,
      isSuccess: isDeleteSuccess,
      isLoading: isDeleteEmployeeLoading,
      isError: isDeleteError,
      error: errorDeleteEmployee,
    },
  ] = useDeleteEmployeeDataMutation();

  useEffect(() => {
    if (errorDeleteEmployee && isDeleteError) {
      isDeleteError?.data?.error
        ? isDeleteError.data.error.length > 0 &&
          isDeleteError.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({
            message: isDeleteError?.data?.message,
            type: "error",
          });
    }

    if (isDeleteSuccess && employeeDeleteData) {
      showToast({
        message: employeeDeleteData.message,
        type: "success",
      });
      navigate("/employeeListing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteError, employeeDeleteData]);

  useEffect(() => {
    if (isErrorEditEmployee && errorEditEmployee) {
      errorEditEmployee?.data?.error
        ? errorEditEmployee?.data?.error.length > 0 &&
          errorEditEmployee.data.error.map((errorItem) => {
            const isPresent = getAddressError(
              errorItem.param,
              formikRef.current?.setFieldError
            );
            !isPresent &&
              formikRef.current.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({
            message: errorEditEmployee?.data?.message,
            type: "error",
          });
    }

    if (successfullyEditEmployee && editEmployeeData) {
      showToast({ message: editEmployeeData.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);

      navigate("/employeeListing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEmployeeData, errorEditEmployee]);

  useEffect(() => {
    if (state) {
      setReportTo(state?.reportTo);
      formikRef.current.setFieldValue("reportTo", state?.reportTo?.id);

      if (
        state?.profileImageDetails &&
        Object.keys(state?.profileImageDetails).includes("url")
      ) {
        setfilesAdded([state?.profileImageDetails]);
        setImages([state?.profileImageDetails?.url]);
      }
    }
  }, [state]);

  const onSubmit = (companyFormValues) => {
    const countryCode = companyFormValues.countryCode
      ? companyFormValues.countryCode
      : "962";

    let requestObjectEmployee = {
      companyId: [companyFormValues?.companyId],

      name: companyFormValues.name,
      gender: companyFormValues.gender.toUpperCase(),
      dob: formatDateYYYYMMDD(companyFormValues.dob),

      designation: companyFormValues.designation,
      department: companyFormValues.department,
      countryCode: countryCode,
      // mobile: companyFormValues.mobile,
      mobile: companyFormValues.mobile?.split("-")[1],

      mobileShortCode: companyFormValues?.mobileShortCode,

      companyExtension: companyFormValues.companyExtension,
      companyNumber: companyFormValues.companyNumber,

      email: companyFormValues.email,

      residenceAddress: {
        latitude: companyFormValues.resLat,
        longitude: companyFormValues.resLng,
        latlong: {
          latitude: companyFormValues.resLat,
          longitude: companyFormValues.resLng,
        },
        flat: companyFormValues.resFlat,
        address: companyFormValues.resAddress,
        country: companyFormValues.resCountry,
        state: companyFormValues.resState,
        city: companyFormValues.resCity,
        zipCode: companyFormValues.resZipCode,
      },
      workAddress: {
        latitude: companyFormValues.workLat,
        longitude: companyFormValues.workLng,
        latlong: {
          latitude: companyFormValues.workLat,
          longitude: companyFormValues.workLng,
        },

        flat: companyFormValues.workFlat,
        address: companyFormValues.workAddress,
        country: companyFormValues.workCountry,
        state: companyFormValues.workState,
        countryISO: "IN",
        city: companyFormValues.workCity,
        zipCode: companyFormValues.workZipCode,
      },
      role: state?.role,
      primary: userData?.primary,
      emailVerified: "YES",
      system: "YES",
      mobileVerified: "YES",
      reportTo,
    };

    if (filesAdded.length > 0) {
      if (filesAdded.every((value) => initialImage?.includes(value))) {
        requestObjectEmployee = {
          ...requestObjectEmployee,
          profileUrl: state?.profileUrl,
          profileImageDetails: state?.profileImageDetails,
        };
      } else {
        const b64Object = {
          ...filesAdded.map((ele) => ({
            url: ele?.b64,
            type: ele?.type,
            profileFileName: ele?.name,
            profileFileExt: ele?.name.split(".").pop(),
          }))[0],
        };

        requestObjectEmployee = {
          ...requestObjectEmployee,
          webProfileUrl: b64Object,
        };
      }
    } else {
      requestObjectEmployee = {
        ...requestObjectEmployee,
      };
    }

    if (
      companyFormValues?.alternateMobile?.length > 0 &&
      companyFormValues?.alternateMobile?.includes("-")
    ) {
      requestObjectEmployee = {
        ...requestObjectEmployee,
        alternateMobileCountryCode:
          companyFormValues.alternateMobileCountryCode,
        alternateMobile: companyFormValues.alternateMobile?.split("-")[1],
        alternateMobileShortCode: companyFormValues?.alternateMobileShortCode,
      };
    } else {
      requestObjectEmployee = {
        ...requestObjectEmployee,
        alternateMobileCountryCode: "",
        alternateMobile: "",
        alternateMobileShortCode: "",
      };
    }
    if (
      companyFormValues?.hrMobile?.length > 0 &&
      companyFormValues?.hrMobile?.includes("-")
    ) {
      requestObjectEmployee = {
        ...requestObjectEmployee,
        hrMobileCountryCode: companyFormValues.hrMobileCountryCode,

        hrMobile: companyFormValues.hrMobile?.split("-")[1],
        hrMobileShortCode: companyFormValues?.hrMobileShortCode,
      };
    } else {
      requestObjectEmployee = {
        ...requestObjectEmployee,
        hrMobileCountryCode: "",

        hrMobile: "",
        hrMobileShortCode: "",
      };
    }

    editEmployeeApi({
      body: requestObjectEmployee,
      id: state?._id,
    });
  };

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );
      setImages(newImages);
    }
  }, [filesAdded]);

  const deleteEmployee = () => {
    setShowAlert(false);
    deleteEmployeeApi(state._id);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddEmployeeSchema}
    >
      {({ values, handleSubmit }) => {
        return (
          <EmployeeForm
            values={values}
            handleSubmit={handleSubmit}
            filesAdded={filesAdded}
            images={images}
            setfilesAdded={setfilesAdded}
            setImages={setImages}
            isLoading={isLoadingEditEmployee || isDeleteEmployeeLoading}
            employeeData={state}
            isEdit
            showAlert={showAlert}
            deleteEmployee={deleteEmployee}
            setShowAlert={setShowAlert}
            setReportTo={setReportTo}
          />
        );
      }}
    </Formik>
  );
};
export default EditEmployee;
