/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";

export const Ability = () => {
  const location = useLocation();
  const { userData } = useSelector((state) => state.formanagement);

  // const abilityArray = [
  //   {
  //     label: "Inbox",
  //     to: "/inbox/inboxList",
  //     show: true,
  //     content:[   {
  //       label: "InboxListing",
  //       to: "/inbox/inboxList",
  //       show: true,
  //     },
  //     {
  //       label: "SentMailListing",
  //       to: "/inbox/sentMail",
  //       show: true,
  //     },
  //     {
  //       to: "/inbox/viewEmailDetails",
  //       label: "View email details",
  //       show: true,
  //     },]
  //   },

  //   {
  //     label: "Message",
  //     to: "/message",
  //     show: true,
  //   },
  //   {
  //     label: "Calendar",
  //     to: "/calendar",
  //     show: true,
  //     content:[{
  //       label: "Calendar",
  //       to: "/calendar/view",
  //       show: true,
  //     },
  //     {
  //       label: "CreateEvent",
  //       to: "/calendar/createEvent",
  //       show: true,
  //     },
  //     {
  //       label: "ViewEvent",
  //       to: "/calendar/viewEvent",
  //       show: true,
  //     },
  //     {
  //       label: "EditEvent",
  //       to: "/calendar/editEvent",
  //       show: true,
  //     },
  //     {
  //       label: "CalendarReminder",
  //       to: "/calendar/reminder",
  //       show: true,
  //     },]
  //   },

  //   {
  //     label: "manager",
  //     to: "/managerListing",
  //     show: true,
  //     content:[{
  //       label: "addManager",
  //       to: "/addManager",
  //       show: true,
  //     },
  //     {
  //       label: "viewManager",
  //       to: "/viewManager",

  //       show: true,
  //     },]
  //   },
  //   {
  //     label: "owner",
  //     to: "/ownerListing",
  //     show: true,
  //     content:[  {
  //       label: "addOwner",
  //       to: "/addOwner",
  //       show: true,
  //     },
  //     {
  //       label: "viewOwner",
  //       to: "/viewOwner",
  //       show: true
  //     },]
  //   },

  //   {
  //     label: "gm",
  //     to: "/generalManagerListing",
  //     show: true,
  //     content:[
  //       {
  //         label: "addGeneralManager",
  //         to: "/addGeneralManager",
  //         show: true,
  //       },
  //       {
  //         label: "viewGeneralManager",
  //         to: "/viewGeneralManager",

  //         show: true
  //       },
  //     ]},
  //     {
  //       label: "pa",
  //       to: "/personalAssistantListing",
  //       show: true,
  //       content:[
  //         {
  //           label: "addPersonalAssistant",
  //           to: "/staff/pa/add",
  //           show: true,
  //         },
  //         {
  //           label: "editPersonalAssistant",
  //           to: "/staff/pa/edit",
  //           show: true,
  //         },
  //         {
  //           label: "viewPersonalAssistant",
  //           to: "/staff/pa/view",

  //           show: true,
  //         },
  //       ]},

  //       {
  //         label: "employee",
  //         to: "/employeeListing",
  //         show: true,
  //         content:[ {
  //           label: "addEmployee",
  //           to: "/addEmployee",
  //           show: true,
  //         },
  //         {
  //           label: "viewEmployee",
  //           to: "/viewEmployee",

  //           show: true,
  //         },
  //         {
  //           label: "editEmployee",
  //           to: "/editEmployee",
  //           show: true,
  //         },]
  //       },

  //   {
  //     label: "staffPerformance",
  //     to: "/staff/performance",
  //     show: true,
  //   },

  //   {
  //     label: "Vendors/suppliers",
  //     to: "/vendors",
  //     show: true,
  //     content:[    {
  //       label: "addVendor",
  //       to: "/vendors/add",
  //       show: true,
  //     },
  //     {
  //       label: "viewVendor",
  //       to: "/vendors/view",
  //       show: true,
  //     },
  //     {
  //       label: "editVendor",
  //       to: "/vendors/edit",
  //       show: true,
  //     },]
  //   },

  //   {
  //     label: "Dashboard",
  //     to: "/",
  //     show: true,
  //   },
  //   {
  //     label: "Manage Task",
  //     to: "/manageTask",
  //     show: true,
  //     content:[
  //       {
  //         label: "Task Details",
  //         to: "/taskDetails",
  //         show: true,
  //       },
  //   {
  //     label: "addTask",
  //     to: "/addTask",
  //     show: true,   },
  //   {
  //     label: "editTask",
  //     to: "/editTask",
  //     show: true,  },
  //   {
  //     label: "Task Details",
  //     to: "/taskDetails",
  //     show: true,
  //   },
  //   {
  //     label: "Task details",
  //     to: "/task",
  //     show: true,
  //   },
  //   {
  //     label: "Add subtask",
  //     to: "/subtask/add",
  //     show: true,
  //   },
  //   {
  //     label: "Linked subtasks",
  //     to: "/subtask/list",
  //     show: true,
  //   },
  //   {
  //     label: "View subtask",
  //     to: "/subtask/view",
  //     show: true,
  //   },
  //   {
  //     label: "View subtask",
  //     to: "/subtask",
  //     show: true,
  //   },
  //   {
  //     label: "Edit subtask",
  //     to: "/subtask/edit",
  //     show: true,
  //   },
  //   {
  //     label: "Task Reminder",
  //     to: "/taskReminder",
  //     show: true,
  //   },
  //       {
  //         label: "RelatedTaskListing",
  //         to: "/relatedTaskListing",
  //         show: true,
  //       },
  //       {
  //         label: "AssignedByPATaskListing",
  //         to: "/assignedByPATaskListing",
  //         show: true,
  //       },
  //       {
  //         label: "RejectedTaskListing",
  //         to: "/rejectedTaskListing",
  //         show: true,
  //       },
  //       {
  //         label: "PendingApprovalTask",
  //         to: "/pendingApprovalTask",
  //         show: true,
  //       },
  //       {
  //         label: "ReAllocationRequestTask",
  //         to: "/reAllocationRequestTask",
  //         show: true,
  //       },
  //       {
  //         label: "reportedByMeTask",
  //         to: "/reportedByMeTask",
  //         show: true,
  //       },
  //       {
  //         label: "SelfAssignedTask",
  //         to: "/selfAssignedTask",
  //         show: true,
  //       },
  //       {
  //         label: "AssignedToMeTaskListing",
  //         to: "/assignedToMeTaskListing",
  //         show: true,
  //       },
  //       {
  //         label: "PinnedListing",
  //         to: "/pinnedListing",
  //         show: true,
  //       },
  //       {
  //         label: "ReportedByPA",
  //         to: "/reportedByPA",
  //         show: true,
  //       },
  //       {
  //         label: "EmployeeRequest",
  //         to: "/employeeRequestTask",
  //         show: true,
  //       },
  //     ]
  //   },
  //   {
  //     label: "company",
  //     to: "/addCompany",
  //     show: true,
  //     content:[
  //       {
  //         label: "viewOfficeLocation",
  //         to: "/viewOfficeLocation",
  //         show: true,
  //       },
  //       {
  //         label: "addCompany",
  //         to: "/addCompany",
  //         show: true,
  //       },
  //       {
  //         label: "editOfficeLocation",
  //         to: "/editOfficeLocation",

  //         show: true,
  //       },
  //     ]
  //   },

  //   {
  //     to: "/profile",
  //     label: "profile",
  //     show: true,
  //   },
  //   {
  //     label: "Notification",
  //     to: "/notifications",
  //     show: true,
  //     content:[{
  //       label: "Notifications",
  //       to: "/notifications",
  //       show: true,
  //     },
  //     {
  //       label: "Notifications",
  //       to: "/notifications/view",
  //       show: true,
  //     },]
  //   },

  //   {
  //     label: "Staff",
  //     to: "#!",
  //     show: true,
  //     content: [{
  //             label: "Owner",
  //             to: "/ownerListing",
  //             show: true,
  //           },{
  //             label: "Manager",
  //             to: "/managerListing",
  //             show: true,
  //           }, {
  //             label: "Employee",
  //             to: "/employeeListing",
  //             show: true,
  //           }, {
  //             label: "PA",
  //             to: "/staff/pa",
  //             show: true,
  //           },{
  //             label: "GM",
  //             to: "/generalManagerListing",
  //             show: true,
  //           }, {
  //             label: "Vendor",
  //             to: "#!",
  //             show: true,
  //           }]
  //   },
  //   {
  //     label: "Contact repository",
  //     to: "#!",
  //     show: true,
  //     content: [
  //       {
  //         label: "Private listing",
  //         to: "/contactRepository/privateListing",

  //         show: true,
  //       },
  //       {
  //         label: "View Business Card",
  //         to: "/contactRepository/privateListing/viewBusinessCard",

  //         show: true,
  //       },
  //       {
  //         label: "Access Logs",
  //         to: "/contactRepository/privateListing/accessLogs",
  //         show: true,
  //       },
  //       {
  //         label: "User Filter Types",
  //         to: "/contactRepository/privateListing/selectMember",

  //         show: true,
  //       },
  //       {
  //         label: "User Filter Types",
  //         to: "/contactRepository/sharedListing/selectMember",
  //         show:true
  //       },
  //       {
  //         label: "User Filter Types",
  //         to: "/contactRepository/sharedListing/roles",
  //         show:true,
  //       },
  //       {
  //         label: "User Filter Types View",
  //         to: "/contactRepository/viewSelectShareMemberBusinessCard",
  //         show:true,
  //       },
  //       {
  //         label: "User Filter Types View",
  //         to: "/contactRepository/viewSelectMemberBusinessCard",

  //         show: true,
  //       },

  //       {
  //         label: "Add new contact private list",
  //         to: "/contactRepository/privateListing/addNewContact",
  //         show: true,

  //       },
  //       {
  //         label: "Add new contact public list",
  //         to: "/contactRepository/publicListing/addNewContact",
  //         show: true,

  //       },
  //       {
  //         label: "Public listing",
  //         to: "/contactRepository/publicListing",

  //         show: true,
  //       },

  //       {
  //         label: "Edit contact private list",
  //         to: "/contactRepository/privateList/editContact",
  //         show: true,

  //       },
  //       {
  //         label: "Edit contact private list",
  //         to: "/contactRepository/publicList/editContact",
  //         show: true,

  //       },
  //       {
  //         label: "Public listing",
  //         to: "/contactRepository/publicListing/viewBusinessCard",
  //         show: true,
  //       },
  //       {
  //         label: "Public listing",
  //         to: "/contactRepository/publicListing/BusinessCard",
  //         show: true,
  //       },
  //       {
  //         label: "Public listing",
  //         to: "/contactRepository/publicListing/accessLogs",
  //         show: true,
  //       },
  //       {
  //         label: "Shared listing",
  //         to: "/contactRepository/sharedListing",

  //         show: true,
  //       },
  //       {
  //         label: "Business card",
  //         to: "/contactRepository/sharedListing/viewBusinessCard",

  //         show: true,
  //       },
  //       {
  //         label: "Private contacts",
  //         to: "/contactRepository/privateContacts",

  //         show: true,
  //       },
  //     ],
  //   },

  //   {
  //     label: "Voice note",
  //     to: "#!",
  //     show: true,
  //     content: [
  //       {
  //         label: "My voice notes",
  //         to: "/voiceNotes/myVoiceNotes",
  //         show: true,
  //       },
  //       {
  //         label: "share voice note access log",
  //         to: "/voiceNotes/myVoiceNotes/accessLogs",
  //         show: true,
  //       },
  //       { label: "Set Voice notes Reminder", to: "/setReminder", show: true },
  //       {
  //         label: "Shared with me",
  //         to: "/voiceNotes/sharedWithMe",
  //         show: true,
  //       },
  //     ],
  //   },

  //   {
  //     to: "#!",
  //     show: userData?.documentRepository,
  //     content: [
  //       {
  //         label: "My documents",
  //         to: "/documentRepository/myDocuments",
  //         show: true,
  //       },
  //       {
  //         label: "Shared with me",
  //         to: "/documentRepository/sharedWithMe",
  //         show: true,
  //       },
  //       {
  //         label: "myDocuments/edit",
  //         to: "/documentRepository/myDocuments/add",
  //         show: true,
  //       },
  //       {
  //         label: "myDocuments/edit",
  //         to: "/documentRepository/myDocuments/edit",
  //         show: true,
  //       },
  //       {
  //         label: "myDocuments/edit",
  //         to: "/documentRepository/myDocuments/view",
  //         show: true,
  //       },
  //       {
  //         label: "myDocuments/edit",
  //         to: "/documentRepository/myDocuments/accessLogs",
  //         show: true,
  //       },
  //       {
  //         label: "myDocuments/edit",
  //         to: "/documentRepository/sharedWithMe/view",
  //         show: true,
  //       },
  //       {
  //         label: "myDocuments/edit",
  //         to: "/documentRepository/sharedWithMe/accessLogs",
  //         show: true,
  //       },
  //     ],
  //   },
  //   {
  //     label: "Renewals",
  //     to: "/renewals",
  //     show: true,
  //     content:[  {
  //       label: "addRenewals",
  //       to: "/renewals/add",
  //       show: true,
  //     },
  //     {
  //       label: "viewRenewals",
  //       to: "/renewals/view",
  //       show: true,
  //     },
  //     {
  //       label: "editRenewals",
  //       to: "/renewals/edit",
  //       show: true,
  //     },]
  //   },
  //   {
  //     label: "Profile",
  //     to: "/profile",
  //     show: true,
  //   },
  //   {
  //     label: "Activity logs",
  //     to: "#!",
  //     show: true,
  //     content: [{
  //             label: "Owner",
  //             to: "/activityLogs/owner",
  //             show: true,
  //           },{
  //             label: "Manager",
  //             to: "/activityLogs/manager",
  //             show: true,
  //           },{
  //             label: "Employee",
  //             to: "/activityLogs/employee",
  //             show: true,
  //           }, {
  //             label: "PA",
  //             to: "/activityLogs/pa",
  //             show: true,
  //           },{
  //             label: "GM",
  //             to: "/activityLogs/gm",
  //             show: true,
  //           },{
  //             label: "Vendor",
  //             to: "/activityLogs/vendor",
  //             show: true,
  //           }]
  //   },
  //   {
  //     label: "Report",
  //     to: "#!",
  //     show: true,
  //     content: [{
  //             label: "Task report",
  //             to: "/reports/task",
  //             show: true,
  //           }, {
  //             label: "Performance report",
  //             to: "/reports/performance",
  //             show: true,
  //           },{
  //             label: "Workload report",
  //             to: "/reports/workload",
  //             show: true,
  //           }, {
  //             label: "Staff report",
  //             to: "/reports/staff",
  //             show: true,
  //           },{
  //             label: "Attendance report",
  //             to: "/reports/attendance",
  //             show: true,
  //           },
  //           {
  //             label: "PerformanceReportDetails",
  //             to: "/performanceDetails",
  //             show: true,
  //           },
  //           {
  //             label: "WorkloadReportDetails",
  //             to: "/workloadDetails",
  //             show: true,
  //           },
  //           {
  //             label: "Configuration",
  //             to: "/reports/configure",
  //             show: true,
  //           },
  //           {
  //             label: "AttendanceReportDetail",
  //             to: "/reports/attendance/view",
  //             show: true,
  //           },
  //           {
  //             label: "AttendanceReportLeaveRequest",
  //             to: "/reports/attendance/leaveRequest",
  //             show: true,
  //           },
  //           {
  //             label: "StaffReportDetails",
  //             to: "/reports/staff/view",
  //             show: true,
  //           },]
  //   },
  //   {
  //     label: "Project",
  //     to: "#!",
  //     show: true,
  //     content:[
  //       {
  //         label: "Project report",
  //         to: "/reports/project",
  //         show: true,
  //       },
  //       {
  //         label: "Add project",
  //         to: "/addProject",
  //         show: true,
  //       },
  //     ]
  //   },

  //   {
  //     label: "Organization structure",
  //     to: "/organizationStructure",
  //     show: true,
  //     content: [
  //       {
  //         label: "Company structure",
  //         to: "/organizationStructure/company",
  //       },
  //       {
  //         label: "Reporting structure",
  //         to: "/organizationStructure/report",
  //       },
  //     ],
  //   },
  //   {
  //     label: "About us",
  //     to: "/aboutUs",
  //     show: true,
  //   },
  //   {
  //     label: "Privacy policy",
  //     to: "/privacy",
  //     show: true,
  //   },
  //   {
  //     label: "Terms & conditions",
  //     to: "/termsConditions",
  //     show: true,
  //   },
  //   {
  //     label: "Change timezone",
  //     to: "/settings/changeTimezone",
  //     show: true,
  //   },
  //   {
  //     label: "Change password",
  //     to: "/settings/changePassword",
  //     show: true,
  //   },
  //   { label: "Task dependancy", to: "/taskDetails/taskdependancy", show: true },
  //   { label: "Task dependancy", to: "/taskdependancy", show: true },
  //   {
  //     label: "MOM listing",
  //     to: "#!",
  //     show: true,
  //     content: [
  //       {
  //         label: "My events",
  //         to: "/mom/myevent",
  //         show: true,
  //       },
  //       {
  //         label: "Shared with me",
  //         to: "/mom/sharedWithMe",
  //         show: true,
  //       },
  //       {
  //         label: "View MOM",
  //         to: "/mom/myevent/view",
  //         show: true,
  //       },
  //       {
  //         label: "Edit MOM",
  //         to: "/mom/myevent/editEvent",
  //         show: true,
  //       },
  //       {
  //         label: "Add MOM",
  //         to: "/calendar/addMom",
  //         show: true,
  //       },
  //       {
  //         label: "View MOM",
  //         to: "/mom/myevent/view",
  //         show: true,
  //       },
  //       {
  //         label: "View linked events",
  //         to: "/mom/myevent/linked",
  //         show: true,
  //       },
  //       {
  //         label: "View MOM",
  //         to: "/mom/sharedWithMe/view",
  //         show: true,
  //       },
  //       {
  //         label: "Shared Users",
  //         to: "/mom/myevent/sharedUsers",
  //         show: true,
  //       },
  //       {
  //         label: "Shared Users",
  //         to: "/mom/sharedWithMe/sharedUsers",
  //         show: true,
  //       },
  //     ],
  //   },

  // ];

  return (
    // <AuthRoutes
    //   isAuthorised={userData?.menuAccess?.find((item) => {
    //     const menu = Object.values(item)[0];
    //     const { authRoutes, parent, isEnable } = menu;
    //     return authRoutes?.length
    //       ? authRoutes?.find(
    //         (subItem) =>
    //           subItem?.navigation === location?.pathname && subItem?.isEnable
    //       )
    //       : parent?.navigation === location.pathname && isEnable;
    //   })}
    // />
    <AuthRoutes isAuthorised={true} />
  );
};
