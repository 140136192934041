import React from "react";
import { colors } from "../../../../../common/Theme/Colors";
import { convertString } from "../../../../../common/Utils/commonFunction";
import { StyledTypography } from "../../../../../common/Utils/StyledComponents";
import Translate from "../../../../../translation/utils/translateFunction";
import NoDataFound from "../../../../ManageTask/NoDataFound";

const ProcurementStatus = ({ statusData = [] }) => {
  return statusData?.length <= 0 ? (
    <NoDataFound message={Translate("manageTask:noHistory")} isCenter />
  ) : (
    <div className="card pb-4">
      <div className="card-header">
        <StyledTypography
          variant="subtitle1"
          sx={{ fontWeight: "500", color: colors.black }}
        >
          {Translate("leaveRequest:status")}
        </StyledTypography>
      </div>
      <div className="card-body">
        <ul className="new_timeline mt-3">
          {statusData?.map((statusItem, idx) => {
            const { date, statusMessage, status } = statusItem;
            return (
              <li key={idx}>
                <div>
                  <div
                    className={`bullet primary ${
                      status === "Approved" || status === "Disapproved"
                        ? "shadow"
                        : "inactiveBullet"
                    }`}
                  />
                  <div className="desc pt-2">
                    <h3>{convertString(statusMessage) || ""}</h3>
                    <span className="time text-pink">{date || ""}</span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProcurementStatus;
