import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import moment from "moment-timezone";
import * as yup from "yup";
import { UserTypes } from "../../../components/ContactRepository/component/constant";
import Translate from "../../../translation/utils/translateFunction";
import { Regex } from "../ApiConstants";
import { showToast } from "../ToastMessage";
import { userTypes } from "../../users/userTypes";

export const convertToB64 = (acceptedFile) => {
  return new Promise((resolve) => {
    const file = acceptedFile;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
};

const units = ["B", "KB", "MB"];

export const toConvertKB = (x) => {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const getSentenseCase = (str) => {
  if (!str) return "";

  const parsedString = str.replace(/[A-Z]/g, (char) => ` ${char}`).split(" ");
  const word1 = parsedString[1];
  const word2 = parsedString[2].replace(
    /[A-Z]/,
    (char) => `${char.toLowerCase()}`
  );
  return `${word1} ${word2}`;
};

export const getNameAndExtFromFile = (str) => {
  if (!str) return [];

  return str.split(/\.(?=[^\.]+$)/);
};

export const isObjectEmpty = (objectName) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

export const getSentenseCaseforSingleWord = (str) => {
  if (!str) return "";

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const convertToSentenceCase = (input) => {
  const words = input.split(" ");
  const formattedWords = words.map((word, index) => {
    if (index === 0) {
      // Capitalize the first letter of the first word
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      // Convert the rest of the words to lowercase
      return word.toLowerCase();
    }
  });
  return formattedWords.join(" ");
};

export const convertCamelToTitleCase = (str) => {
  if (!str) return "";

  const res = str
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());

  return res;
};

export const validateDuplicateFiles = (file, files) => {
  const isDuplicate = files?.some(
    (item) => item?.name === file?.name && item?.size === file?.size
  );

  if (isDuplicate) {
    return { isDuplicate: true, fileName: file?.name };
  }

  return false;
};

export const isRtl = () => {
  const rtl = localStorage.getItem("isRtl");

  return rtl === "true";
};

export const getDayJs = (data) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const userData = JSON.parse(localStorage.getItem("userData"));
  return userData?.login ? dayjs(data).tz(userData?.login?.timezone) : dayjs();
};
export const getMoment = (data) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return userData?.login
    ? moment(data).tz(userData?.login?.timezone)
    : moment();
};
export const getMomentDate = (data) => {
  return moment(data);
};

export const getFileExtFromUrlAndB64 = (str) => {
  if (!str) return "";

  // for b64
  if (str?.startsWith("data:")) {
    const matches = str?.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);

    if (matches && matches?.length === 3) {
      const contentType = matches[1];
      const ext = contentType.split("/")[1].split(";")[0];

      return ext;
    }
    // for url
  } else if (str?.startsWith("http://") || str.startsWith("https://")) {
    const urlParts = str?.split(".");
    const ext = urlParts[urlParts?.length - 1];

    return ext;
  }

  return "";
};
export const getAddressError = (field, setFieldError) => {
  switch (field) {
    case "workAddress.zipCode":
      setFieldError("workZipCode", Translate("addManager:zipcodeError_2"));
      return true;
    case "residenceAddress.zipCode":
      setFieldError("resZipCode", Translate("addManager:zipcodeError_2"));
      return true;
    case "residenceAddress.country":
      setFieldError("resCountry", Translate("addManager:countryError"));
      return true;
    case "residenceAddress.state":
      setFieldError("resState", Translate("addManager:stateError"));
      return true;
    case "workAddress.country":
      setFieldError("workCountry", Translate("addManager:countryError"));
      return true;
    case "workAddress.state":
      setFieldError("workState", Translate("addManager:stateError"));
      return true;
    case "officeAddress.country":
      setFieldError("country", Translate("addManager:countryError"));
      return true;
    case "officeAddress.state":
      setFieldError("state", Translate("addManager:stateError"));
      return true;
    case "officeAddress.zipcode":
      setFieldError("zipcode", Translate("addManager:zipcodeError_2"));
      return true;
    default:
      return false;
  }
};
export const getDeliveryInformationError = (
  field,
  setFieldError,
  errorMessage
) => {
  switch (field) {
    case "deliveryInformation.startTime":
      setFieldError("startTime", errorMessage);
      return true;
    case "deliveryInformation.dueTime":
      setFieldError("dueTime", errorMessage);
      return true;
    case "deliveryInformation.preferredDeliveryDate":
      setFieldError("preferredDeliveryDate", errorMessage);
      return true;
    case "deliveryInformation.startDate":
      setFieldError("startDate", errorMessage);
      return true;
    case "deliveryInformation.dueDate":
      setFieldError("dueDate", errorMessage);
      return true;

    case "vendorInformation.name":
      setFieldError("name", errorMessage);
      return true;
    case "vendorInformation.contactPerson":
      setFieldError("contactPerson", errorMessage);
      return true;
    case "vendorInformation.contactNumber":
      setFieldError("contactNumber", errorMessage);
      return true;
    case "vendorInformation.email":
      setFieldError("email", errorMessage);
      return true;

    case "requestDetails.description":
      setFieldError("description", errorMessage);
      return true;
    case "requestDetails.quantity":
      setFieldError("quantity", errorMessage);
      return true;
    case "requestDetails.unitPrice":
      setFieldError("unitPrice", errorMessage);
      return true;
    case "requestDetails.totalCost":
      setFieldError("totalCost", errorMessage);
      return true;
    case "requestDetails.justification":
      setFieldError("justification", errorMessage);
      return true;
    case "deliveryInformation.deliveryAddress.zipCode":
      setFieldError("zipCode", Translate("addManager:zipcodeError_2"));
      return true;
    case "deliveryInformation.deliveryAddress.country":
      setFieldError("country", Translate("addManager:countryError"));
      return true;
    case "deliveryInformation.deliveryAddress.state":
      setFieldError("state", Translate("addManager:stateError"));
      return true;

    default:
      return false;
  }
};

export const validateMobileSchema = (fieldName, msg) => {
  return {
    [fieldName]: yup
      .string()
      .trim()
      .required(msg)
      .test("noEmptyVal", msg, (value) => {
        if (!value) return true;

        // Check for empty parts within a string containing a dash
        return !value.split("-").some((part) => part === "");
      }),
  };
};

export const validateUrl = (str = "") => {
  const urlPattern = Regex?.urlRegex;

  return urlPattern.test(str);
};

export const handleEventKeySubmit = (event, onSubmit) => {
  if (event?.key === "Enter" || event?.keyCode === 13) {
    const isRestrictedEvent =
      event?.target?.tagName === "LI" ||
      event?.target?.className === "search-box" ||
      event?.target?.className.includes("MuiSelect-select") ||
      (event?.target?.className.includes("MuiList-padding MuiMenu-list") &&
        event?.target?.id === ":r9:") ||
      event?.target?.id === "react-select-3-input" ||
      event?.target?.id === "mui-component-select-reminder" ||
      event?.target?.id === "react-select-5-input";

    event.preventDefault();

    if (!isRestrictedEvent) {
      onSubmit(event);
    }
  }
};

export const stopEventPropogation = (event) => event.stopPropagation();

export const checkModuleAccess = (data, moduleName) => {
  const keyName = moduleName?.toLowerCase();

  const moduleAccess = data?.find((item) => {
    return Object.keys(item).some(
      (key) => key.toLowerCase() === keyName && item[key]?.isEnable
    );
  });

  if (!moduleAccess) return false;

  return true;
};
export const checkModuleAccessOfficer = (data, moduleName, UserType) => {
  const keyName = moduleName.toLowerCase();
  const keyName2 = UserType.toLowerCase();
  let moduleAccess = false;

  data?.map((item) => {
    const isEnabled = Object.keys(item).some(
      (key) => key.toLowerCase() === keyName2 && item[key]?.isEnable
    );
    if (isEnabled && item[keyName2]?.children?.length) {
      moduleAccess = item[keyName2]?.children?.find(
        (itemm) => itemm?.label?.toLowerCase() === keyName && itemm?.isEnable
      );
    } else if (isEnabled) {
      moduleAccess = isEnabled;
    }
  });
  if (!moduleAccess) return false;

  return true;
};

export const getDropdownOptions = (
  data,
  labelKey,
  valueKey,
  noKeys = false
) => {
  if (!data?.length) return [];

  const options = data.map((item) => ({
    label: noKeys ? item : item[labelKey],
    value: noKeys ? item : item[valueKey],
  }));
  return options;
};
export const getMenuAccessObject = (menuList, label) => {
  const getData = menuList?.find((item) => item[label] === label);
  return getData[label];
};
// utility to capitalize the roles of users
export function convertString(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      if (word === "hrmanager") return "Hr Manager";
      if (word === "hrofficer") return "Hr Officer";
      if (word === "hr") return "Hr";
      if (word === "by") return "by";
      if (word === "pa") return "PA";
      if (word === "gm") return "GM";
      if (word === "po") return "Procurement officer";
      if (word === "with") return "with";
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
export const moduleTypes = {
  Profile: "profile",
  Managetask: "managetask",
  Contactprivate: "contactprivate",
  Contactshare: "contactshare",
  TaskReport: "taskreport",
  AttendanceReport: "attendancereport",
  performanceReport: "performancereport",
  workloadReport: "workloadreport",
  staffReport: "staffreport",
  viewCalendar: "viewcalendar",
};

export const convertStringForHRManager = (str) => {
  if (str.toLowerCase() === "hrmanager") {
    return "HR MANAGER";
  }
  if (str.toLowerCase() === "hrofficer") {
    return "HR OFFICER";
  }

  return str;
};
export const getFilterValue = (user) => {
  switch (user) {
    case "all":
      return userTypes.all.toUpperCase();
    case "self":
      return userTypes.Self.toUpperCase();
    case "owner":
      return userTypes.Owner.toUpperCase();
    case "pa":
    case "personalAssistant":
      return userTypes.persoalAssistant.toUpperCase();
    case "generalManager":
      return userTypes.GeneralManager.toUpperCase();
    case "manager":
      return userTypes.Manager.toUpperCase();
    case "employee":
      return userTypes.Employee.toUpperCase();
    case "vendor":
      return userTypes.Vendor.toUpperCase();
  }
};
export const getFilterNavigate = ({
  selectedFilter,
  navigate,
  companies,
  isShare = false,
}) => {
  if (companies.length) {
    switch (selectedFilter?.label) {
      case UserTypes.OWNER: {
        navigate(selectedFilter?.navigation, {
          state: {
            userType: UserTypes.OWNER,
            selectedCompany: companies,
            isShare: isShare,
          },
        });
        break;
      }
      case UserTypes.PA: {
        navigate(selectedFilter?.navigation, {
          state: {
            userType: UserTypes.PA,
            selectedCompany: companies,
            isShare: isShare,
          },
        });
        break;
      }
      case UserTypes.GM: {
        navigate(selectedFilter?.navigation, {
          state: {
            userType: UserTypes.GM,
            selectedCompany: companies,
            isShare: isShare,
          },
        });
        break;
      }
      case UserTypes.MANAGER: {
        navigate(selectedFilter?.navigation, {
          state: {
            userType: UserTypes.MANAGER,
            selectedCompany: companies,
            isShare: isShare,
          },
        });
        break;
      }
      case UserTypes.EMPLOYEE: {
        navigate(selectedFilter?.navigation, {
          state: {
            userType: UserTypes.EMPLOYEE,
            selectedCompany: companies,
            isShare: isShare,
          },
        });
        break;
      }
      default:
        break;
    }
  } else {
    showToast({
      type: "info",
      message: Translate("contacts:selectComapnyMessage"),
    });
  }
};
