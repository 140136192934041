export default {
  head: "الحساب",
  shareContact: "شارك جهة الإتصال",
  shareContacts: "شارك جهات الإتصال",
  personalDetails: "المعلومات الشخصية",
  name: "الإسم",
  email: "ايميل العمل ",
  contact: "رقم جهة الإتصال",
  alternateContact: "رقم جهة اتصال بديل",
  dob: "تاريخ الميلاد",
  officeLocation: "موقع الشركة (المكتب)",
  owner: "الشركات المسجلة للمالك",
  companyNamePlaceholder: "اسم الشركة الأول",
  URL: "موقع الشركة الالكتروني URL",
  URLPlaceholder: "www.companyurl.com",
  address: "العنوان المسجّل",
  addressPlaceholder: "العنوان",
  landmark: "معلم قريب",
  landmarkPlaceholder: "المعلم",
  country: "الدولة",
  dropdownPlaceholder: "اختر الدولة",
  shareHead: "شارك جهة الإتصال الى",
  managers: "المدراء",
  employees: "الموظفين",
  profileIcon: "الصورة الشخصية",
  removeIconAlert: "حذف الصورة الشخصية؟",
  askShare: "اطلب، للمشاركة",
  request: "اسأل المدير لمشاركة جهة اتصال",
  request1: "اطلب لمشاركة جهة اتصال:",
  addDetailsPlaceholder: "أضف تفاصيل",
  companyName: "اسم الشركة",
  select: "اختر",
  contacts: "جهات الإتصال",
  residenceAddress: "عنوان السكن",
  designation: "الوظيفة",
  company: "الشركة",
  markAttendance: "تأكيد الحضور",
  nameError: "حقل الاسم مطلوب.",
  leaveRequest: "تطبيق الإجازة",
  laveType: "اختر نوع الإجازة",
  leaveReason: "اترك السبب",
  fromDateError: "من التاريخ مطلوب",
  toDateError: "مطلوب حتى الآن",
  leaveReasonError: "سبب الإجازة مطلوب",
  attachmentText: "إرفاق المستندات الداعمة",
};
