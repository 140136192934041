import { Methods, Urls } from "../../../common/Utils/ApiConstants";
import { CreateApi } from "../../CreateApi";

const procurementOfficerCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getPOListing: builder.mutation({
      query: ({ requestBody, pageNo }) => {
        return {
          url: Urls.getProcurements,
          method: Methods.post,
          body: requestBody,
          params: { pageNo },
        };
      },
    }),
    addPOData: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addPO,
          method: Methods.post,
          body: body,
        };
      },
    }),
    viewPOData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.viewPo}${body}`,
          method: Methods.get,
        };
      },
    }),
    editPOData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editPo}${body?.id}`,
          method: Methods.put,
          body: body?.body,
        };
      },
    }),
  }),
});

export const {
  useGetPOListingMutation,
  useEditPODataMutation,
  useAddPODataMutation,
  useViewPODataMutation,
} = procurementOfficerCollection;
