import { CircularProgress, Grid, Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompanyHeader from "../../../common/CompanyHeader";
import { colors } from "../../../common/Theme/Colors";
import {
  DateTimeFormats,
  formatDateYYYYMMDD,
  formatFullMonthYear,
} from "../../../common/Utils/DateFormater";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  getFilterValue,
  getMoment,
} from "../../../common/Utils/commonFunction";
import { moduleTypes } from "../../../common/users/userTypes";
import {
  useExportAttendanceReportMutation,
  useGetAttendanceReportListMutation,
} from "../../../request/Report";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import DropDownField from "../../TextField/DropdownField";
import Searchbar from "../../common/Searchbar";
import { getAttendanceReportColumns } from "./column.data";

const AttendanceReport = ({ fixNavbar }) => {
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRole, setSelectedRole] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [staffOptions, setStaffOptions] = useState([]);
  const [attendanceReport, setAttendanceReport] = useState([]);
  const [navigationFlag, setNavigationFlag] = useState();
  const [dateRange, setDateRange] = useState();
  const lastPage = useRef(0);
  const [csvData, setCSVData] = useState([]);
  const csvLink = useRef();
  const [selectedCompany, setSelectedCompany] = useState([
    selectedCompanyList[0],
  ]);

  const [
    getAttendanceReportApi,
    {
      data: AttendanceReportData,
      isLoading: isAttendanceLoading,
      isSuccess: isAttendanceSuccess,
    },
  ] = useGetAttendanceReportListMutation();

  const [
    exportAttendanceReportApi,
    {
      data: exportAttendanceReport,
      isLoading: isExportLoading,
      error: exportError,
    },
  ] = useExportAttendanceReportMutation();
  const clearFilter = () => {
    setDateRange(undefined);
    setCurrentPage(1);
    setSelectedRole([staffOptions[0].value]);
  };

  const showFilters = () => setShowFilter(!showFilter);
  const requestObj = useMemo(() => {
    const companies = selectedCompany?.length
      ? selectedCompany?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companies,
      searchText: searchText,
      pageNo: currentPage,
      role: selectedRole.length
        ? selectedRole[0] === "ALL"
          ? []
          : selectedRole
        : [],
      year: moment(dateRange).add("d", 1).format(DateTimeFormats.year),
      month: moment(dateRange).format(DateTimeFormats.longMonth),
      date: dateRange
        ? moment(dateRange).add("d", 1).toISOString()
        : getMoment().toDate(),
    };
    return companies?.length ? finalReq : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText, selectedCompany, selectedRole, dateRange]);
  useEffect(() => {
    if (userData) {
      let filter = [];
      const filterData = userData?.moduleAccess?.find(
        (item) => Object.keys(item)[0] === moduleTypes.AttendanceReport
      );
      Object?.values(filterData)[0]?.map((item) => {
        item?.isEnable &&
          filter.push({
            label: Translate(`taskReport:${item?.label}`),
            value: getFilterValue(item.label),
          });
      });
      setStaffOptions(filter);
      setSelectedRole([filter[0].value]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  useEffect(() => {
    getAttendanceReportApi(requestObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);
  useEffect(() => {
    if (isAttendanceSuccess && AttendanceReportData?.data) {
      const { pageInfo, nodes, navigationFlags } = AttendanceReportData?.data;
      lastPage.current = pageInfo?.lastPageNo;
      setAttendanceReport(nodes);
      setNavigationFlag(navigationFlags);
    }
  }, [isAttendanceSuccess]);
  const customActionCellRender = (params) => {
    const obj = {
      companyId: selectedCompany?.map((item) => item?.value),
      // date: getMoment().toDate(), //nned to change with selectedDate
      date: dateRange
        ? moment(dateRange).add("d", 1).toISOString()
        : getMoment().toDate(),
    };
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
          onClick={() =>
            navigate("view", {
              state: { data: params?.row, requestData: obj },
            })
          }></i>
      </td>
    );
  };

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };
  const setFilterValue = (item, placeholder) => {
    setCurrentPage(1);
    // eslint-disable-next-line default-case
    switch (placeholder) {
      case Translate("workloadReport:staffPlaceHolder"):
        // eslint-disable-next-line no-lone-blocks
        {
          item.target.value !== placeholder
            ? setSelectedRole([item.target.value])
            : setSelectedRole([]);
        }
        break;
    }
  };
  useEffect(() => {
    if (exportAttendanceReport?.length > 0) {
      const rows = exportAttendanceReport?.split("\n");
      const rowArray = rows.map((item) => item.trim().split(","));

      setCSVData(rowArray);
    }

    if (exportError) {
      const errorResponse = JSON.parse(exportError?.data);

      exportError?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(errorResponse?.message) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
    }
  }, [exportAttendanceReport, exportError]);

  const handleExportTask = () => {
    exportAttendanceReportApi(requestObj);
  };
  useEffect(() => {
    if (csvData?.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      csvLink?.current?.link?.click();
    }
  }, [csvData]);

  const handleSearchText = (value) => {
    selectedCompany?.length > 0 && setCurrentPage(1);
    setSearchText(value);
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="card cardHeight">
              <div className="row">
                <div className="col-12">
                  <div className="card-header fixedHeight">
                    <CompanyHeader
                      setShowModal={setShowModal}
                      selectedCompany={
                        selectedCompany[0]?.label ||
                        Translate("common:selectCompany")
                      }
                    />
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      className="card-options">
                      {navigationFlag?.previous && (
                        <IconifyIcon
                          className="cursor-pointer"
                          icon={"ant-design:left-circle-outlined"}
                          fontSize="24"
                          style={{ color: colors.black }}
                          onClick={() =>
                            setDateRange(
                              moment(dateRange).subtract("month", 1).format()
                            )
                          }
                        />
                      )}
                      <StyledTypography
                        variant="h6"
                        px={2}
                        color={colors.primary}
                        sx={{ fontWeight: "500" }}>
                        {formatFullMonthYear(dateRange)}
                      </StyledTypography>
                      {navigationFlag?.next && (
                        <IconifyIcon
                          className="cursor-pointer"
                          icon={"ant-design:right-circle-outlined"}
                          style={{ color: colors.black }}
                          fontSize="24"
                          onClick={() =>
                            setDateRange(
                              moment(dateRange).add("month", 1).format()
                            )
                          }
                        />
                      )}
                    </Stack>
                    <div className="card-options">
                      <div className="sort_stat commentTextField">
                        {showFilter ? (
                          <div style={{ marginTop: "9px" }} className="mr-3">
                            <StyledTypography
                              sx={{ color: colors.primary_009 }}
                              component="span">
                              {Translate("workloadReport:staffLabel")}
                            </StyledTypography>
                            <div className="multiselect_div selectWidth">
                              <div className="form-group cursor-pointer">
                                <DropDownField
                                  options={staffOptions}
                                  className={
                                    "form-control textFieldHeight cursor-pointer"
                                  }
                                  placeholder={Translate(
                                    "workloadReport:staffPlaceHolder"
                                  )}
                                  onChange={(item) =>
                                    setFilterValue(
                                      item,
                                      Translate(
                                        "workloadReport:staffPlaceHolder"
                                      )
                                    )
                                  }
                                  value={selectedRole}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="filterIconBox headerMargin mx-2"
                          onClick={() => {
                            showFilter && clearFilter();
                            showFilters(!showFilter);
                          }}>
                          {showFilter ? (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:clearFilterToolTip")}>
                              <IconifyIcon
                                icon="mdi:filter-off"
                                width="19"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          ) : (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:showFilterToolTip")}>
                              <IconifyIcon
                                icon="fa:filter"
                                width="14"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          )}
                        </div>
                        <form
                          className="headerMargin"
                          style={{ display: "flex" }}>
                          <div className="input-group mr-2">
                            <Searchbar
                              handleSearchText={handleSearchText}
                              setSearch={setSearchText}
                            />
                          </div>
                          <div className="header-action pull-right">
                            <button
                              type="button"
                              className="btn btn-primary saveButton py-2"
                              onClick={handleExportTask}
                              disabled={!attendanceReport?.length}>
                              {isExportLoading ? (
                                <div className="align-self-center">
                                  <CircularProgress size={22} color="inherit" />
                                </div>
                              ) : (
                                <>
                                  <i className="fe fe-download" />
                                  <StyledTypography
                                    className="mx-1"
                                    component="span">
                                    {Translate("common:exportLabel")}
                                  </StyledTypography>
                                </>
                              )}
                            </button>

                            {csvData?.length > 0 && (
                              <CSVLink
                                data={csvData}
                                ref={csvLink}
                                filename={`${Translate(
                                  "taskReport:exportAttendanceFileName"
                                )}_${formatDateYYYYMMDD(
                                  getMoment().toDate()
                                )}.csv`}
                              />
                            )}
                          </div>
                        </form>
                        {userData?.isHrManager && (
                          <div className="d-flex ml-2">
                            <div className="mt-2 headerMargin">
                              <a
                                href="/#"
                                className="nav-link dropdown-toggle "
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false">
                                <i
                                  className="fa fa-ellipsis-v manageIconColor filterIconSize"
                                  data-toggle="tooltip"
                                  title={Translate("contacts:filter")}
                                />
                              </a>
                              <div className="dropdown-menu dropdownAlignment">
                                <div
                                  onClick={() =>
                                    navigate("/reports/configure")
                                  }>
                                  <a className="dropdown-item cursor-pointer">
                                    <IconifyIcon
                                      icon={"ic:outline-settings"}
                                      fontSize="24"
                                      className="mr-2"
                                    />
                                    {Translate("reports:configure")}
                                  </a>
                                </div>
                                <div
                                  onClick={() =>
                                    navigate("/reports/attendance/leaveRequest")
                                  }>
                                  <a className="dropdown-item cursor-pointer">
                                    <IconifyIcon
                                      icon={"mingcute:calendar-time-add-line"}
                                      fontSize="24"
                                      className="mr-2"
                                    />
                                    {Translate("reports:leaveRequest")}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ListView
                  columns={getAttendanceReportColumns(
                    customActionCellRender,
                    customSrCellRender
                  )}
                  rows={attendanceReport}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isAttendanceLoading}
                  showLoader={isAttendanceLoading}
                  totalPageCount={lastPage.current}
                />
              </div>
            </div>
          </div>
        </div>
        <CompanyListing
          handleModal={(value) => {
            setCurrentPage(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompany}
          onSelect={(value) => {
            setSelectedCompany(value);
          }}
          isFilter
          isSingleSelect
        />
      </StyledCard>
    </Grid>
  );
};
export default AttendanceReport;
