import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReAllocationTaskColumns } from "./Components/column.data";
import { Grid, Stack } from "@mui/material";
import ListView from "../../ListView";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useLazyGetReAllocationRequestDataQuery } from "../../../request/ManageTaskFilters/ReAllocationRequestTask";
import Translate from "../../../translation/utils/translateFunction";
import { SUBTASK_PREFIX } from "../constants";
import { useSelector } from "react-redux";
import { MarkCriticalAndPinTask } from "../Components/utils";
import { taskStatusType } from "../../AddTask/constants";
import {
  useCriticalTaskMutation,
  usePinTaskMutation,
} from "../../../request/Task";

const ReAllocationRequestListing = ({ fixNavbar }) => {
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [reAllocationRequests, setReAllocationRequests] = useState([]);

  const [
    fetchReAllocationTaskList,
    {
      data: reAllocationList,
      isLoading: isReAllocationLoading,
      isError: isReAllocationError,
      error: reAllocationError,
      isFetching: isReAllocationFetching,
    },
  ] = useLazyGetReAllocationRequestDataQuery();

  const [pinTask, { data: pinTaskData }] = usePinTaskMutation({
    fixedCacheKey: "pinnedTask",
  });

  const [markCritical, { data: markCriticalData }] = useCriticalTaskMutation({
    fixedCacheKey: "criticalTask",
  });

  useEffect(() => {
    setIsLoading(true);
    fetchReAllocationTaskList(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pinTaskData, markCriticalData]);

  useEffect(() => {
    if (reAllocationList?.nodes) {
      const { nodes, pageInfo } = reAllocationList;
      setReAllocationRequests(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }
    if (reAllocationError && isReAllocationError) {
      showToast({
        message:
          JSON.stringify(reAllocationError?.data) ||
          Translate("common:unknownServerError"),
        type: "error",
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reAllocationList, reAllocationError]);

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomActionCellRender = (params) => {
    const { taskNumber } = params?.row;
    const isSubtask = taskNumber?.startsWith(SUBTASK_PREFIX);

    const isCompleted = params?.row?.taskStatus === taskStatusType?.COMPLETED;

    const showCriticalFlag = isCompleted
      ? false
      : params?.row?.userDetails?.some(
        (details) => details?._id === userData?._id
      );

    return (
      <div className="headerMargin">
        <a
          href="/#"
          className="nav-link dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v manageIconColor" />
        </a>
        <div className="dropdown-menu dropdownAlignment dropdownIconMenu">
          <MarkCriticalAndPinTask
            taskId={params?.row?.taskId || params?.row?._id}
            showCriticalFlag={showCriticalFlag}
            hasFlagged={params?.row?.hasFlagged}
            isCompleted={isCompleted}
            hasPinned={params?.row?.hasPinned}
            markAsCritical={markCritical}
            pinTask={pinTask}
          />
          <div
            className="manageIconColor ml-3 cursor-pointer dropdown-item"
            onClick={() =>
              navigate(isSubtask ? "/subtask/view" : "/taskDetails", {
                state: {
                  taskId: params?.row?.taskId,
                  isTaskFilter: true,
                  navigateFrom: "/reAllocationRequestTask",
                },
              })
            }
          >
            <span className="manageIconColor fa fa-eye" />
            {Translate("manageTask:viewTask")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${fixNavbar ? "marginTop" : ""
        } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing formTitle">
                  <Stack flexDirection={"row"} alignItems={"center"}>
                    <i
                      className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                      onClick={() => navigate("/manageTask")}
                      title={Translate("common:backToolTip")}
                    />
                    <h5 className="page-title">
                      {Translate("manageTask:reallocationRequest")}
                    </h5>
                  </Stack>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getReAllocationTaskColumns(
                      CustomSrCellRender,
                      CustomActionCellRender
                    )}
                    rows={reAllocationRequests}
                    autoHeight
                    onPaginationChange={setCurrentPage}
                    currentPage={currentPage}
                    showShimmer={isReAllocationFetching}
                    showLoader={isLoading || isReAllocationLoading}
                    totalPageCount={lastPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};
export default ReAllocationRequestListing;
