export default {
  head: "إدارة المهمة",
  pendingApprovals: "الموافقات في انتظار",
  viewLinkedTask: "عرض المهمة الفرعية المرتبطة",
  linkedTask: "مهمة فرعية مرتبطة",
  assignedToMe: "تعيين لي",
  reportedByMe: "ذكرت من قبلي",
  reallocationRequest: "طلب إعادة التخصيص",
  reallocate: "إعادة التخصيص",
  reAllocateTo: "إعادة التخصيص إلى:",
  sendForApproval: "إرسال للموافقة",
  searchPlaceholder: "مهمة البحث",
  selfAssignedTask: "المهمة المعينة ذاتيا",
  pinned: "مهمة مثبتة",
  title: "يكتب",
  titlePlaceHolder: "اختر صنف",
  priority: "أولوية",
  priorityPlaceHolder: "حدد الأولوية",
  status: "حالة",
  statusPlaceHolder: "اختر الحالة",
  selfAssigned: "تعيين ذاتي",
  assignToMe: "تعيين لي",
  reportByMe: "ذكرت من قبلي",
  reportByPa: "أفادت السلطة الفلسطينية",
  pinnedTask: "مهمة مثبتة",
  rejectedTask: "مهمة مرفوضة",
  pendingApproval: "الموافقات في انتظار",
  reallocationRequest: "طلب إعادة التخصيص",
  taskIdTitle: "معرف المهمة",
  taskTypeTitle: "نوع المهمة",
  taskNameTitle: "اسم المهمة",
  companyNameTitle: "اسم الشركة",
  dueDateTitle: "تاريخ الاستحقاق",
  dueTimeTitle: "و الوقت",
  dueDateAndTimeTitle: "تاريخ ووقت الاستحقاق",
  teamTitle: "فريق",
  statusTitle: "حالة",
  progressBar: "شريط التقدم",
  actiontitle: "فعل",
  priorityTitle: "أولوية",
  selectCompanyMessage: "الرجاء اختيار شركة واحدة على الأقل",
  selectCompany: "اختر الشركة",
  noCompany: "لم يتم العثور على أي شركة",
  voiceNoteTitle: "ملاحظة صوتية",
  noVoiceNote: "لم يتم العثور على ملاحظة صوتية",
  commentTitle: "تعليق",
  loadMoreTitle: "تحميل المزيد",
  attacheMenttitle: "مرفق",
  noAttachmentMsg: "لم يتم العثور على أي مرفقات",
  historyTitle: "تاريخ المهمة",
  noHistory: "لم يتم العثور على سجل المهام",
  assignedByPA: "المعينة من قبل السلطة الفلسطينية",
  relatedTask: "المهام ذات الصلة",
  assigneeNameTitle: "اسم المكلف",
  reAllocateToTitle: "إعادة التخصيص ل",
  markasCritical: "وضع علامة حرجة",
  removeCritical: "قم بإلغاء تحديد الأهمية",
  pinTask: "مهمة التثبيت",
  viewTask: "عرض المهمة",
  moduleTypePlaceholder: "نوع الوحدة",
  reportedByPA: "أفادت السلطة الفلسطينية",
  unPinTask: "إزالة تثبيت المهمة",
  date: "التاريخ",
  approveTask: "الموافقات الذاتية المعلقة",
  projectTitle: "مشروع",
};
