export default {
  head: "Account",
  shareContact: "Share contact",
  shareContacts: "Share contacts",
  personalDetails: "Personal details",
  name: "Name",
  email: "Work email ",
  contact: "Contact number",
  alternateContact: "Alternate contact number",
  dob: "Date of birth",
  officeLocation: "Office location",
  owner: "Registered companies of owner",
  companyNamePlaceholder: "First company name",
  URL: "Company URL",
  URLPlaceholder: "www.companyurl.com",
  address: "Registered address",
  addressPlaceholder: "Registered address line",
  landmark: "Landmark",
  landmarkPlaceholder: "Given landmark",
  country: "Country",
  dropdownPlaceholder: "Select country",
  shareHead: "Share contact to",
  managers: "Managers",
  employees: "Employees",
  profileIcon: "Profile photo",
  removeIconAlert: "Remove profile photo?",
  askShare: "Ask to share",
  request: "Request owner to share contact:",
  request1: "Request to share contact:",
  addDetailsPlaceholder: "Add details",
  companyName: "Company name",
  select: "Select",
  contacts: "Contacts",
  residenceAddress: "Residence address",
  designation: "Designation",
  company: "Company",
  markAttendance: "Mark attendance",
  nameError: "Name is required",
  leaveRequest: "Apply leave",
  laveType: "Choose leave type",
  leaveReason: "Leave reason",
  fromDateError: "From date is required",
  toDateError: "To date is required",
  leaveReasonError: "Leave reason is required",
  attachmentText: "Attach support documents  ",
};
