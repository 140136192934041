import React, { useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { StyledTypography } from "../../common/Utils/StyledComponents";
import Translate from "../../translation/utils/translateFunction";
import { colors } from "../../common/Theme/Colors";
import { handleEventKeySubmit } from "../../common/Utils/commonFunction";

const AlertPermission = ({
  show = false,
  hideCancel,
  subTitle = "",
  onOk,
  onCanel,
  okTitle,
  isAlertText = false,
  title,
  link,
  cancelTitle,
  isBtnAlignLeft,
  isLoading = false,
  approveDisapproveTitle = false,
}) => {
  const defaultBtnRef = useRef(null);

  useEffect(() => {
    if (show) {
      defaultBtnRef.current.focus();
    }
  }, [show]);

  const Renderbuttons = () => {
    return (
      <>
        <div>
          <button
            type="submit"
            className="btn btn-primary saveButton"
            onClick={onOk}
            ref={defaultBtnRef}
            onKeyDown={(e) => handleEventKeySubmit(e, onOk)}
          >
            {isLoading
              ? Translate("common:loadingText")
              : okTitle || Translate("common:ok")}
          </button>
        </div>
        {!hideCancel && (
          <div>
            <button
              type="button"
              className="btn btn-white addButton"
              onClick={onCanel}
              disabled={isLoading}
            >
              {cancelTitle || Translate("common:cancel")}
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <Modal
      show={show}
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        alignItems: "center",
        justifyContent: "center",
      }}
      onHide={onCanel}
    >
      <Modal.Body style={{ padding: "1.5rem 2rem" }}>
        {!isAlertText && title && (
          <StyledTypography
            variant="h5"
            sx={{ fontSize: "1.1rem", fontWeight: 600, color: colors.primary }}
            className="mb-3"
          >
            {title}
          </StyledTypography>
        )}

        <div className="mb-3">
          <StyledTypography
            variant="subtitle1"
            sx={{ fontSize: "1.1rem", fontWeight: 500, color: colors.black }}
          >
            {subTitle}
          </StyledTypography>

          {link && (
            <div>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <StyledTypography variant="subtitle1">
                  {Translate("common:referThis")}
                </StyledTypography>
              </a>
            </div>
          )}
        </div>
        {!isAlertText ? (
          <div
            className="modalStylingLogin"
            style={{ justifyContent: isBtnAlignLeft ? "end" : "flex-start" }}
          >
            <Renderbuttons />
          </div>
        ) : (
          <div
            className="modalStylingLogin"
            style={{ paddingBottom: 15, paddingRight: 25 }}
          >
            <Renderbuttons />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default AlertPermission;
