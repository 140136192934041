export default {
  addHrOfficerTitle: "إضافة ضابط موارد بشرية",
  editHrOfficerTitle: "تحرير ضابط الموارد البشرية",
  viewHrOfficerTitle: "عرض ضابط الموارد البشرية",
  hrOfficerName: "اسم ضابط الموارد البشرية",
  staff: "طاقم عمل",
  staffplaceholder: "حدد الموظفين",
  staffError: "مطلوب موظفين",
  nameError: "مطلوب اسم موظف الموارد البشرية",
};
