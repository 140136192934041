import React from "react";
import { StyledTypography } from "../../common/Utils/StyledComponents";
import { isRtl as validateRtl } from "../../common/Utils/commonFunction";
import { centerContent } from "../AddTask/constants";
import { Persona } from "../Persona";
import { VALIDATION_BUCKETURL } from "../Persona/constants";
import TextWrapWithTooltip from "../TextWrapWithTooltip";

const isRtl = validateRtl();

export const CustomTitleCellRender = (
  title,
  customClassName = "chatUserTitle",
  hasPinned = false,
  hasFlagged = false
) => (
  <TextWrapWithTooltip
    textVariant="span"
    customClassName={customClassName}
    label={title}
    customStyle={isRtl && { direction: "ltr" }}
    hasFlagged={hasFlagged}
    hasPinned={hasPinned}
  />
);

export const CustomTagCellRender = (value, className) => (
  <StyledTypography className={`tag ${className}`}>{value}</StyledTypography>
);

export const CustomDateTimeCellRender = (date, time) => (
  <div>
    <div className="text-info">{date || ""}</div>
    <div className={`text-pink ${isRtl && "leftDirection"}`}>{time || ""}</div>
  </div>
);

export const CustomUserTitleCellRender = (props) => {
  const { profileUrl, name, contactName, contactProfile } = props.row;

  const profile = profileUrl || contactProfile;
  const userName = name || contactName;

  return (
    <div style={{ ...centerContent, gap: "8px" }}>
      <Persona
        name={userName}
        size={28}
        image={profile?.includes(VALIDATION_BUCKETURL) ? profile : ""}
      />
      <div className="customUserItemDetails">
        {CustomTitleCellRender(userName, "maxwidth-350 text-info")}
      </div>
    </div>
  );
};
export const CustomUserItem = (props) => {
  const { profileUrl, userName, title, email, customClass, role } = props;

  return (
    <div style={{ ...centerContent, gap: "2px" }}>
      <Persona name={userName} size={32} image={profileUrl} />
      <div className="customUserItemDetails">
        {customClass ? (
          <TextWrapWithTooltip
            textVariant="span"
            customClassName={
              customClass ? customClass : "shareUserEllipsisTitle"
            }
            label={title}
            role={role}
          />
        ) : (
          <span>{title}</span>
        )}
        {email && (
          <StyledTypography
            variant="span"
            className="ellipsisStyle customUserItemTitle addtaskUserEllipsisTitle"
            title={email}
          >
            {email}
          </StyledTypography>
        )}
      </div>
    </div>
  );
};
