import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PersonalAssistantForm from "../Components/PersonalAssistantForm";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { InitialValues } from "../Components/constants";
import Translate from "../../../../translation/utils/translateFunction";
import { useAddPersonalAssistantDataMutation } from "../../../../request/Staff/PersonalAssistant";
import { AddPersonalAssistantSchema } from "../Components/validators";
import { formatDateYYYYMMDD } from "../../../../common/Utils/DateFormater";
import { Formik } from "formik";
import moment from "moment";
import { useSelector } from "react-redux";
import { getAddressError } from "../../../../common/Utils/commonFunction";

const AddPersonalAssistant = () => {
  const { state } = useLocation();
  const { userData } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const [reportTo, setReportTo] = useState({});
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);

  const [addMorePersonalAssistant, setAddMorePersonalAssistant] =
    useState(false);

  const [
    addPersonalAssistantApi,
    {
      data: personalAssistantData,
      isSuccess: isAddedSuccess,
      isLoading: isAddPALoading,
      isError: isAddedError,
      error: errorAddPA,
    },
  ] = useAddPersonalAssistantDataMutation();

  useEffect(() => {
    if (isAddedError && errorAddPA) {
      const paError = errorAddPA?.data;

      if (!paError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        paError?.error?.length
          ? paError.error.map((errorItem) => {
              const isPresent = getAddressError(
                errorItem.param,
                formikRef.current?.setFieldError
              );
              !isPresent &&
                formikRef.current.setFieldError(errorItem.param, errorItem.msg);
              /* if (!InitialValues.hasOwnProperty(errorItem?.param)) {
                const tempParam = validateAddressFields(errorItem?.param);

                if (tempParam) {
                  return formikRef.current.setFieldError(
                    tempParam,
                    errorItem?.msg
                  );
                }

                return showToast({ message: errorItem?.msg, type: "error" });
              }

              return formikRef.current.setFieldError(
                errorItem?.param,
                errorItem?.msg
              ); */
            })
          : showToast({
              message:
                paError?.message || Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }

    if (isAddedSuccess && personalAssistantData) {
      if (!personalAssistantData?.success) {
        showToast({ message: personalAssistantData?.message, type: "error" });
      } else {
        showToast({ message: personalAssistantData?.message, type: "success" });
      }

      formikRef.current.resetForm();
      InitialValues.dob = "";
      InitialValues.alternateMobile = "962";
      InitialValues.mobile = "962";
      setFilesAdded([]);
      setImages([]);

      if (!addMorePersonalAssistant) {
        navigate("/staff/pa");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalAssistantData, errorAddPA]);

  const onSubmit = (formValues) => {
    const {
      companyId,
      dob,
      countryCode,
      alternateMobile,
      alternateMobileCountryCode,
    } = formValues;

    let reqObj = {
      companyId,
      name: formValues.name,
      gender: formValues?.gender.toUpperCase(),
      dob:
        moment(dob).creationData().format === "YYYY-MM-DD"
          ? dob
          : formatDateYYYYMMDD(dob),
      designation: formValues.designation,
      department: formValues.department,
      reportTo,
      countryCode: countryCode ? countryCode : "962",
      // mobile: formValues?.mobile,
      mobile: formValues?.mobile?.split("-")[1],

      mobileShortCode: formValues.mobileShortCode,

      companyExtension: formValues.companyExtension,
      companyNumber: formValues.companyNumber,
      email: formValues.email,
      residenceAddress: {
        latitude: formValues.resLat,
        longitude: formValues.resLng,
        latlong: {
          latitude: formValues.resLat,
          longitude: formValues.resLng,
        },
        flat: formValues.resFlat,
        address: formValues.resAddress,
        country: formValues.resCountry,
        state: formValues.resState,
        city: formValues.resCity,
        zipCode: formValues.resZipCode,
      },
      workAddress: {
        latitude: formValues.workLat,
        longitude: formValues.workLng,
        latlong: {
          latitude: formValues.workLat,
          longitude: formValues.workLng,
        },
        flat: formValues.workFlat,
        address: formValues.workAddress,
        country: formValues.workCountry,
        state: formValues.workState,
        countryISO: "IN",
        city: formValues.workCity,
        zipCode: formValues.workZipCode,
      },
      emailVerified: "YES",
      mobileVerified: "YES",
      primary: userData?.primary,
      system: "NO",
      role: {
        type: "PA",
      },
      profileUrl: "",
    };

    let b64Object = {};

    if (filesAdded?.length > 0) {
      b64Object = {
        ...filesAdded.map((ele) => ({
          url: ele?.b64,
          type: ele?.type,
          profileFileName: ele?.name,
          profileFileExt: ele?.name.split(".").pop(),
        }))[0],
      };
      reqObj = {
        ...reqObj,
        webProfileUrl: b64Object,
      };
    }

    if (
      formValues?.alternateMobile?.length > 0 &&
      formValues?.alternateMobile?.includes("-")
    ) {
      reqObj = {
        ...reqObj,

        alternateMobile: formValues?.alternateMobile?.split("-")[1],

        alternateMobileCountryCode: formValues?.alternateMobileCountryCode,
        alternateMobileShortCode: formValues.alternateMobileShortCode,
      };
    } else {
      reqObj = {
        ...reqObj,

        alternateMobile: "",

        alternateMobileCountryCode: "",
        alternateMobileShortCode: "",
      };
    }

    addPersonalAssistantApi(reqObj);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddPersonalAssistantSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <PersonalAssistantForm
            formikRef={formikRef}
            isAdd
            isLoading={isAddPALoading}
            values={values}
            setFieldValue={setFieldValue}
            paData={state}
            handleSubmit={handleSubmit}
            setReportTo={setReportTo}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
            images={images}
            setImages={setImages}
            setAddMorePersonalAssistant={setAddMorePersonalAssistant}
          />
        );
      }}
    </Formik>
  );
};

export default AddPersonalAssistant;
