import { useField } from "formik";
import React, { useEffect, useState } from "react";
import PhoneInputField from "../../staff/Owner/Components/PhoneInputText";

export const FormikPhoneInput = ({
  name,
  onSelect,
  onChange,
  onCountryChange,
  shortCodeChange,
  currentCountyCode,
  isLogin = false,
  required = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = { ...meta };
  const { value } = { ...field };

  const onChangeInput = (textValue, country) => {
    if (!isNaN(textValue) || textValue !== country?.dialCode) {
      const reducedPhone = isLogin && textValue.replace(country?.dialCode, "");
      helpers.setValue(textValue);
      onChange &&
        onChange(
          isLogin ? country.dialCode + "-" + reducedPhone : textValue,

          country
        );
    } else {
      helpers.setValue("");
      onChange && onChange("");
    }
  };

  return (
    <PhoneInputField
      {...props}
      {...field}
      isLogin={isLogin}
      isError={touched && error !== undefined}
      touched={touched}
      error={error}
      required={required}
      onChange={(textValue, country) => {
        !isNaN(isLogin ? textValue : textValue.target.value) &&
          onChangeInput(isLogin ? textValue : textValue.target.value, country);
      }}
      shortCodeChange={(item) => shortCodeChange(item)}
      onCountryChange={onCountryChange}
    />
  );
};
