import Translate from "../../../../translation/utils/translateFunction";
import {
  CustomDueDateCellRender,
  CustomPriorityCellRender,
} from "../../../ManageTask/Components/utils";
import { CustomTitleCellRender } from "../../../common/CustomCellRender";

export const getProcurementPendingApprovalColumns = (
  CustomSrCellRender,
  CustomActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("addManager:srNoTableHeading"),
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: CustomSrCellRender,
    },
    {
      field: "requestId",
      headerName: Translate("procurementRequest:requestID"),
      minWidth: 190,
      maxWidth: 190,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.requestId),
    },
    {
      field: "title",
      headerName: Translate("procurementRequest:requestTitle"),
      minWidth: 240,
      maxWidth: 240,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.title),
    },
    {
      field: "companyName",
      headerName: Translate("procurementRequest:companyName"),
      minWidth: 230,
      maxWidth: 230,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTitleCellRender(params?.row?.companyDetails?.name),
    },
    {
      field: "email",
      headerName: Translate("procurementRequest:requestBy"),
      minWidth: 230,
      maxWidth: 230,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTitleCellRender(params?.row?.requestBy?.name),
    },
    {
      field: "dueDate",
      headerName: Translate("procurementRequest:dueDateAndTime"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <div className="text-info">
              {params?.row?.deliveryInformation.dueDate}
            </div>
            <div className="text-pink">
              {params?.row?.deliveryInformation.dueTime}
            </div>
          </div>
        );
      },
    },

    {
      field: "priority",
      headerName: Translate("manageTask:priorityTitle"),
      flex: 0.25,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomPriorityCellRender,
    },
    {
      field: "Action",
      headerName: Translate("addManager:actionTableHeading"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomActionCellRender,
    },
  ];
  return columns;
};
