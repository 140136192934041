import { CircularProgress, Grid, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompanyFilter from "../../../common/CompanyFilter";
import CompanyHeader from "../../../common/CompanyHeader";
import { colors } from "../../../common/Theme/Colors";
import { formatDateYYYYMMDD } from "../../../common/Utils/DateFormater";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  getFilterValue,
  getMoment,
} from "../../../common/Utils/commonFunction";
import { moduleTypes } from "../../../common/users/userTypes";
import {
  useExportWorkloadReportMutation,
  useGetWorkloadReportListMutation,
} from "../../../request/Report";
import Translate from "../../../translation/utils/translateFunction";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import DropDownField from "../../TextField/DropdownField";
import Searchbar from "../../common/Searchbar";
import Columnchart from "../../common/columnchart";
import Piechart from "../../common/piechart";
import Loader from "../../staff/Owner/LoadingComp";
import { getWorkloadReportColumns } from "./components/columns.data";

const WorkloadReport = ({ fixNavbar }) => {
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [reportData, setReportData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [graphDetails, setGraphDetails] = useState({});
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [lessData, setLessData] = useState(false);
  const [occupiedFreeCount, setOccupiedFreeCount] = useState();
  const [csvData, setCSVData] = useState([]);
  const csvLink = useRef();
  const [allFilter, setAllFilters] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([
    selectedCompanyList[0],
  ]);
  const [companyLength, setCompanyLength] = useState();
  const [
    getWorkloadReportApi,
    {
      data: workloadReportData,
      isLoading: isWorkloadLoading,
      isError: isWorkloadError,
      error: workloadError,
    },
  ] = useGetWorkloadReportListMutation();

  const requestObj = useMemo(() => {
    const companies = selectedCompany?.length
      ? selectedCompany?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companies,
      searchText: searchText,
      pageNo: currentPage,

      // role: allFilter ? allFilter : [],
      role: selectedRole && selectedRole[0] === "ALL" ? [] : selectedRole,
    };
    return companies?.length ? finalReq : null;
  }, [currentPage, searchText, selectedCompany, selectedRole, allFilter]);
  const [
    exportWorkloadReportApi,
    {
      data: exportWorkloadReport,
      isLoading: isExportLoading,
      error: exportError,
    },
  ] = useExportWorkloadReportMutation();

  useEffect(() => {
    getWorkloadReportApi(requestObj);
  }, [requestObj]);

  const clearFilter = () => {
    setCurrentPage(1);
    setSelectedRole([staffOptions[0]?.value]);
    setAllFilters(staffOptions.slice(1));
  };

  const showFilters = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    if (userData) {
      let filter = [];
      const filterData = userData?.moduleAccess?.find(
        (item) => Object.keys(item)[0] === moduleTypes.workloadReport
      );
      Object?.values(filterData)[0]?.map((item) => {
        item?.isEnable &&
          filter.push({
            label: Translate(`taskReport:${item?.label}`),
            value: getFilterValue(item.label),
          });
      });
      setStaffOptions(filter);
      setSelectedRole([filter[0]?.value]); //need to uncomment
      setAllFilters(filter?.slice(1));
    }
  }, [userData]);

  const rowsPerPage = 10;
  const startSerialNumber = (currentPage - 1) * rowsPerPage + 1;

  const rowsWithSerialNumbers = reportData?.map((row, index) => ({
    ...row,
    serialNumber: startSerialNumber + index,
  }));

  //   api response render and show error

  useEffect(() => {
    if (workloadReportData?.data?.nodes) {
      const { nodes, pageInfo } = workloadReportData.data;
      setReportData(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
      setIsGraphLoading(false);
    }

    if (workloadError && isWorkloadError) {
      workloadError?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(workloadError.data) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
      setIsLoading(false);
      setIsGraphLoading(false);
    }
  }, [workloadReportData, workloadError, isWorkloadError]);

  useEffect(() => {
    if (workloadReportData?.data) {
      const { graphData } = workloadReportData.data;

      const freeData = [];
      const occupiedData = [];

      const xValues = graphData?.flatMap((entry) =>
        entry?.occupied?.map((item) => item.x)
      );

      for (const graphItem of graphData) {
        const { free, occupied } = graphItem;

        freeData.push([...free?.map((item) => item.y)]);
        occupiedData.push([...occupied?.map((item) => item.y)]);
      }

      let finalObj = [
        {
          name: Translate("workloadReport:occupied"),
          data: occupiedData?.flat(),
        },
        {
          name: Translate("workloadReport:free"),
          data: freeData?.flat(),
        },
      ];

      workloadReportData?.data?.graphType === "donut" &&
        setOccupiedFreeCount({
          free: finalObj[1]?.data[0],
          occupied: finalObj[0]?.data[0],
        });
      if (finalObj?.length > 0 && finalObj[0]?.data?.length < 6) {
        setLessData(true);
      } else {
        setLessData(false);
      }
      if (workloadReportData?.data?.graphType === "donut") {
        const optionsList = [];
        workloadReportData?.data?.graphData.map((item) => {
          item?.occupied?.map((occupiedItem) =>
            optionsList.push({
              ...occupiedItem,
              color: "#7372B8",
              type: "occupied",
            })
          );
          item?.free?.map((freeItem) =>
            optionsList.push({
              ...freeItem,
              color: "#D7D7D7",
              type: "free",
            })
          );
        });
        setGraphDetails({ optionsList: optionsList });
      } else {
        setGraphDetails({
          xValues: xValues,
          series: finalObj,
          options: {
            chart: {
              type: "bar",
              id: "basic-bar",
              toolbar: {
                show: true,
                tools: {
                  download: false, // <== line to add
                },
              },
            },

            tooltip: {
              y: {
                formatter: function (val) {
                  const hoveredVal = isRtl ? "% " + val : val + " %";
                  return hoveredVal;
                },
              },
            },
            legend: {
              markers: {
                fillColors: Object.values(
                  workloadReportData?.data?.legendsColor
                ),
              },
              itemMargin: { vertical: 30 },
            },
            xaxis: {
              categories: xValues,
              position: "bottom",
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              crosshairs: {
                show: false, // <--- HERE
              },
              tooltip: {
                enabled: false,
              },
              labels: {
                show: true,
                formatter: (val) => {
                  return val?.length > 15
                    ? val?.substr(0, 15 - 1) + "..."
                    : val;
                },
              },
            },
            stroke: {
              show: true,
              width: 0,
              colors: ["transparent"],
            },
            fill: {
              opacity: 1,
              colors: Object.values(workloadReportData?.data?.legendsColor),
            },
            plotOptions: {
              bar: {
                columnWidth:
                  finalObj?.length && finalObj[0]?.data?.length < 6
                    ? "33%"
                    : "80%",
                endingShape: "rounded",
                dataLabels: {
                  position: "top", // top, center, bottom
                },
              },
            },
          },
        });
      }

      setIsGraphLoading(false);
    }
  }, [workloadReportData]);

  const customActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
          onClick={() =>
            navigate("/workloadDetails", {
              state: { id: params?.row?._id, nameValue: params?.row?.name },
            })
          }></i>
      </td>
    );
  };

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const setFilterValue = (item, placeholder) => {
    setCurrentPage(1);
    // eslint-disable-next-line default-case
    switch (placeholder) {
      case Translate("workloadReport:staffPlaceHolder"):
        // eslint-disable-next-line no-lone-blocks
        {
          item.target.value !== placeholder
            ? setSelectedRole([item.target.value])
            : setSelectedRole([]);
        }
        if (item.target.value !== "ALL") {
          {
            item.target.value !== placeholder
              ? setAllFilters([item.target.value])
              : setAllFilters([]);
          }
        } else {
          {
            item.target.value !== placeholder
              ? setAllFilters(staffOptions.slice(1))
              : setAllFilters([]);
          }
        }

        break;
    }
  };
  const requestObject = useMemo(() => {
    const companyIdArray = [];
    selectedCompany?.length > 0 &&
      selectedCompany.map((idItem) => companyIdArray.push(idItem?.value));

    const reqObj = {
      companyId: companyIdArray,
      // role: allFilter ? allFilter : [],
      role: selectedRole && selectedRole[0] === "ALL" ? [] : selectedRole,

      searchText: searchText,
      pageNo: currentPage,
    };

    return companyIdArray?.length ? reqObj : null;
  }, [
    currentPage,
    userData,
    selectedCompany,
    selectedRole,
    searchText,
    allFilter,
  ]);

  useEffect(() => {
    if (exportWorkloadReport?.length > 0) {
      const rows = exportWorkloadReport?.split("\n");
      const rowArray = rows.map((item) => item.trim().split(","));

      setCSVData(rowArray);
    }

    if (exportError) {
      const errorResponse = JSON.parse(exportError?.data);

      exportError?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(errorResponse?.message) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
    }
  }, [exportWorkloadReport, exportError]);

  useEffect(() => {
    if (csvData?.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      csvLink?.current?.link?.click();
    }
  }, [csvData]);

  const handleExportTask = () => {
    exportWorkloadReportApi(requestObject);
  };

  const handleSearchText = (value) => {
    selectedCompany?.length > 0 && setCurrentPage(1);
    setSearchText(value);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="card cardHeight">
              <div className="row">
                <div className="col-12">
                  <div
                    className="card-header fixedHeight"
                    style={{ height: "100px !important" }}>
                    <CompanyHeader
                      setShowModal={setShowModal}
                      selectedCompany={`${
                        selectedCompany.length === companyLength
                          ? Translate("common:allCompanySelect")
                          : selectedCompany[0]?.label
                      } ${
                        selectedCompany.length !== companyLength &&
                        selectedCompany.length > 1
                          ? `+ ${selectedCompany.length - 1}`
                          : ""
                      }`}
                    />

                    <div className="card-options">
                      <div className="sort_stat commentTextField">
                        {showFilter ? (
                          <div className="row">
                            <div
                              className="col-lg-4 col-md-4 col-sm-6"
                              style={{ marginTop: "9px" }}>
                              <StyledTypography
                                className="selectWidth"
                                sx={{ color: colors.primary_009 }}>
                                {Translate("workloadReport:staffLabel")}
                              </StyledTypography>
                              <div className="multiselect_div selectWidth">
                                <div className="form-group cursor-pointer">
                                  <DropDownField
                                    options={staffOptions}
                                    className={
                                      "form-control textFieldHeight cursor-pointer"
                                    }
                                    placeholder={Translate(
                                      "workloadReport:staffPlaceHolder"
                                    )}
                                    onChange={(item) =>
                                      setFilterValue(
                                        item,
                                        Translate(
                                          "workloadReport:staffPlaceHolder"
                                        )
                                      )
                                    }
                                    value={selectedRole}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="filterIconBox mx-2"
                          onClick={() => {
                            showFilter && clearFilter();
                            showFilters(!showFilter);
                          }}>
                          {showFilter ? (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:clearFilterToolTip")}>
                              <IconifyIcon
                                icon="mdi:filter-off"
                                width="19"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          ) : (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:showFilterToolTip")}>
                              <IconifyIcon
                                icon="fa:filter"
                                width="14"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          )}
                        </div>
                        <form
                          // className="headerMargin"
                          style={{ display: "flex" }}>
                          <div className="input-group mr-2">
                            <Searchbar
                              handleSearchText={handleSearchText}
                              setSearch={setSearchText}
                            />
                          </div>

                          <div className="header-action pull-right">
                            <button
                              type="button"
                              className="btn btn-primary saveButton py-2"
                              onClick={handleExportTask}
                              disabled={!rowsWithSerialNumbers?.length}>
                              {isExportLoading ? (
                                <div className="align-self-center">
                                  <CircularProgress size={22} color="inherit" />
                                </div>
                              ) : (
                                <>
                                  <i className="fe fe-download" />
                                  <StyledTypography
                                    className="mx-1"
                                    component="span">
                                    {Translate("common:exportLabel")}
                                  </StyledTypography>
                                </>
                              )}
                            </button>

                            {csvData?.length > 0 && (
                              <CSVLink
                                data={csvData}
                                ref={csvLink}
                                filename={`${Translate(
                                  "taskReport:exportWorkdloadFileName"
                                )}_${formatDateYYYYMMDD(
                                  getMoment().toDate()
                                )}.csv`}
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {!!selectedCompany.length && (
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      className="reportNote">
                      <StyledTypography
                        mx={1}
                        style={{
                          fontSize: "0.9rem",
                          color: colors.primary,
                          fontWeight: "600",
                        }}>
                        {`${
                          selectedCompany.length === companyLength
                            ? Translate("common:allCompanySelectworkload")
                            : selectedCompany[0]?.label
                        } ${
                          selectedCompany.length !== companyLength &&
                          selectedCompany.length > 1
                            ? `+ ${selectedCompany.length - 1}`
                            : ""
                        }`}
                      </StyledTypography>
                      <StyledTypography
                        sx={{ fontSize: "0.8rem" }}>{`${Translate(
                        "workloadReport:defaultSelected"
                      )} ${Translate(
                        "workloadReport:filterOptions"
                      )}`}</StyledTypography>
                    </Stack>
                  )}
                </div>
              </div>
              <div className="card-body">
                <div className="taskReportGraph mb-40">
                  {isWorkloadLoading || isGraphLoading ? (
                    <Loader isListLoader />
                  ) : workloadReportData?.data?.nodes?.length ? (
                    workloadReportData?.data?.graphType === "bar" ? (
                      <Columnchart
                        optionsData={graphDetails?.options}
                        seriesData={graphDetails?.series}
                        xValues={graphDetails.xValues}
                        graphHeight={"400px"}
                        strokeWidth={10}
                        barWidth={lessData ? "33%" : "80%"}
                      />
                    ) : (
                      <Piechart
                        optionsList={graphDetails?.optionsList}
                        legends={workloadReportData?.data?.legendsColor}
                        graphHeight={"400px"}
                        riskCountList={[
                          {
                            type: "free",
                            count: `${occupiedFreeCount?.free}%`,
                          },
                          {
                            type: "occupied",
                            count: `${occupiedFreeCount?.occupied}%`,
                          },
                        ]}
                        fromReport={true}
                        // selectedFilter={selectedFilterGraph}
                      />
                    )
                  ) : (
                    <div className="taskReportGraphNoData">
                      <p>
                        {workloadReportData?.data?.graphType === "donut"
                          ? Translate("taskReport:donutDataNotFound")
                          : Translate("taskReport:graphDataNotFound")}
                      </p>
                    </div>
                  )}
                </div>

                <ListView
                  columns={getWorkloadReportColumns(
                    customActionCellRender,
                    customSrCellRender
                  )}
                  rows={rowsWithSerialNumbers}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isWorkloadLoading}
                  showLoader={isLoading || isWorkloadLoading}
                  totalPageCount={lastPage}
                />
              </div>
            </div>
          </div>
        </div>

        <CompanyFilter
          handleModal={(value) => {
            setCurrentPage(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompany}
          onSelect={(value, companySize) => {
            setSelectedCompany(value);
            setCompanyLength(companySize);
          }}
        />
      </StyledCard>
    </Grid>
  );
};

export default WorkloadReport;
