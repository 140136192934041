import { Stack } from "@mui/material";
import dayjs from "dayjs";
import { Form, useFormikContext } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { colors } from "../../../common/Theme/Colors";
import { DateTimeFormats } from "../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  getDayJs,
  getMoment,
  getMomentDate,
  handleEventKeySubmit,
  stopEventPropogation,
} from "../../../common/Utils/commonFunction";
import { userTypes } from "../../../common/users/userTypes";
import { useSetInviteeSelectMutation } from "../../../request/Calendar";
import { useGetAllTimezoneCollectionQuery } from "../../../request/MasterValues";
import { useLazyGetCompanyListQuery } from "../../../request/company";
import { setSelectedInviteeList } from "../../../store/Reducer";
import Translate from "../../../translation/utils/translateFunction";
import FormikDatePicker from "../../FormikForm/component/FormikDatePicker";
import { FormikDropdownPicker } from "../../FormikForm/component/FormikDropDownPicker";
import FormikSearchableDropDown from "../../FormikForm/component/FormikSearchableDropDown";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import ShareWithUser from "../../ShareWithUser";
import { shareModuleType } from "../../ShareWithUser/components/constants";
import PageHeader from "../../common/pageHeader";
import Loader from "../../staff/Owner/LoadingComp";
import { allEvents, allReminders } from "../CreateEvent/utils";
import CustomModal from "./CustomModal";
import ParticipantModal from "./ParticipantModal";

const CreateEventForm = ({
  handleSubmit,
  setEventRecurrence,
  eventRecurrence,
  setOffsetValue,
  setStartDateUTCObject,
  setEndDateUTCObject,
  setStartTimeUTCObject,
  startTimeUTCObject,
  setEndTimeUTCObject,
  setPrivateToggle,
  privateToggle,
  onDelete,
  OnAddMOM,
  isView = false,
  eventData,
  isLoading = false,
  setInviteeList,
  eventInviteeList,
  setTimezoneValue,
  timeZoneValue,
  setTimeZoneName,
  error,
  isError,
  currentState,
  isAdd,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData, validations, selectedInviteeList } = useSelector(
    (state) => state.formanagement
  );
  const { data: serverResponse, isSuccess } =
    useGetAllTimezoneCollectionQuery();
  const [
    setInviteeSelect,
    { data: serverResponseInvitee, isSuccess: isInviteeSuccess },
  ] = useSetInviteeSelectMutation();

  const { values, setFieldValue, setFieldError } = useFormikContext();
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [showInviteeModal, setShowInviteeModal] = useState(false);
  const [startTime, setStartTime] = useState(
    getMoment().format(DateTimeFormats.Time)
  );
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [minDate, setMinDate] = useState(getMoment().toDate());
  const [minDateOfTodate, setMinDateOfToDate] = useState(getMoment().toDate());
  const [showParticipantModal, setShowParticipantModal] = useState(false);
  const hideModal = () => setShowCustomModal(false);
  const onCloseInviteeModal = () => setShowInviteeModal(false);
  const onOpenInviteeModal = () => !isView && setShowInviteeModal(true);
  const showParticipant = () => setShowParticipantModal(true);
  const hideParticipant = () => setShowParticipantModal(false);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isTimeError, setIsTimeError] = useState(false);
  const [isTimeDiffError, setIsTimeDiffError] = useState(false);

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();
  const handleInvitee = (invitee) => {
    dispatch(setSelectedInviteeList(invitee));
    setInviteeList([...invitee]);
  };
  useEffect(() => {}, [selectedInviteeList]);
  useEffect(() => {
    if (isInviteeSuccess && serverResponseInvitee?.data) {
      dispatch(setSelectedInviteeList([]));
    }
  }, [isInviteeSuccess]);
  const resetInvitee = () => {
    const requestObj = {
      moduleInvitee: {
        companyId: [],
        users: [],
      },
      moduleName: "EVENT",
      deselectUsers: selectedInviteeList,
    };
    setInviteeSelect(requestObj);
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    const handleload = (event) => {
      resetInvitee();
    };
    window.addEventListener("load", handleload);
    return () => {
      window.removeEventListener("load", handleload);
    };
  }, []);
  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      resetInvitee();
    };
  }, []);
  useEffect(() => {
    if (isError && error) {
      const eventError = error;
      eventError?.data?.error &&
        eventError?.data?.error?.length > 0 &&
        eventError.data.error.map((errorItem) =>
          errorItem?.param === "startDateUTCObject" ||
          errorItem?.param === "endDateUTCObject"
            ? showToast({ message: errorItem?.msg, type: "error" })
            : errorItem.param === "endTime"
            ? setFieldError("endtime", errorItem.msg)
            : setFieldError(errorItem.param, errorItem.msg)
        );
    }
  }, [isError]);
  useEffect(() => {
    if (isSuccess && serverResponse?.data && !isView) {
      const timeZoneArray = [];
      serverResponse.data.map((item) => {
        const obj = {
          id: item?._id,
          value: item,
          label: (
            <Stack flexDirection={"column"}>
              <StyledTypography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: colors.black }}
                mr={1}
              >
                {item?.name}
              </StyledTypography>
              <Stack flexDirection={"column"}>
                {item?.timezones?.map((time) => (
                  <StyledTypography
                    variant="subtitle2"
                    sx={{ marginTop: "5px" }}
                    onClick={() => {
                      setTimezoneValue({
                        id: item?._id,
                        value: time,
                        label: (
                          <Stack flexDirection={"column"}>
                            <StyledTypography variant="subtitle2">
                              {time?.zoneName}
                              {time?.gmtOffsetName}
                            </StyledTypography>
                          </Stack>
                        ),
                      });
                      setOffsetValue(time.gmtOffsetName);
                      setFieldValue("timeZone", item?._id);
                      setTimeZoneName && setTimeZoneName(time?.zoneName);
                    }}
                    className="cursor-pointer"
                  >
                    {time?.zoneName}
                    {time?.gmtOffsetName}
                  </StyledTypography>
                ))}
              </Stack>
            </Stack>
          ),
        };
        item?.timezones?.length && timeZoneArray.push(obj);
      });
      setTimeZoneList([...timeZoneArray]);
      if (eventData) {
        timeZoneArray.map((item) => {
          const timeZone = item.value.timezones.find(
            (time) => time?.zoneName === eventData.timezone
          );
          timeZone &&
            setTimezoneValue({
              id: item?._id,
              value: timeZone,
              label: (
                <Stack flexDirection={"column"}>
                  <StyledTypography variant="subtitle2">
                    {timeZone?.zoneName}
                    {timeZone?.gmtOffsetName}
                  </StyledTypography>
                </Stack>
              ),
            });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, serverResponse, eventData]);

  const setOptionList = (text = "") => {
    return (
      timeZoneList.length &&
      timeZoneList?.filter(
        (user) =>
          user?.value?.name?.toLowerCase().includes(text?.toLowerCase()) ||
          user?.value?.iso2?.toLowerCase().includes(text?.toLowerCase())
      )
    );
  };
  useEffect(() => {
    if (companyResponse) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );
      setAllCompanyData(companyData);
    }
    return () => {
      setAllCompanyData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);
  useEffect(() => {
    if (eventData) {
      const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } =
        eventData?.repeatWeek;
      const custEvent = {
        repeatEveryNumber:
          eventData?.repeatEveryNumber !== null
            ? eventData?.repeatEveryNumber
            : "",
        repeatEvery:
          eventData?.repeatEvery !== null ? eventData?.repeatEvery : "WEEK",
        repeatWeek: eventData?.repeatWeek,
        selectedDay: sunday
          ? "Sun"
          : monday
          ? "Mon"
          : tuesday
          ? "Tue"
          : wednesday
          ? "Wed"
          : thursday
          ? "Thr"
          : friday
          ? "Fri"
          : saturday
          ? "Sat"
          : "",
        repeatFrom: eventData?.repeatFrom !== null ? eventData?.repeatFrom : "",
        repeatFromUTCObject:
          eventData?.repeatFrom !== null ? eventData?.repeatFrom : "",
        repeatEnd: {
          never: eventData?.repeatEnd.never,
          on: eventData?.repeatEnd.on !== null ? eventData?.repeatEnd.on : "",
          occurance:
            eventData?.repeatEnd.occurance !== null
              ? eventData?.repeatEnd.occurance
              : "",
        },
        repeatEndSelected: eventData?.repeatEnd.never
          ? "Never"
          : eventData?.repeatEnd.on
          ? "On"
          : "After",
        pickedDate:
          eventData?.repeatEnd.on !== null ? eventData?.repeatEnd.on : "",
      };
      eventData?.repeatEvent === "CUSTOM" && setEventRecurrence(custEvent);
      setPrivateToggle(eventData?.isPrivate || false);
      setStartDateUTCObject &&
        setStartDateUTCObject(eventData?.startDateUTCObject);
      setEndDateUTCObject && setEndDateUTCObject(eventData?.endDateUTCObject);
      setStartTimeUTCObject &&
        setStartTimeUTCObject(eventData?.startDateUTCObject);
      setEndTimeUTCObject && setEndTimeUTCObject(eventData?.endDateUTCObject);
      setInviteeList && setInviteeList(eventData?.inviteeDetails);
      setOffsetValue && setOffsetValue(eventData?.utcOffset);
      setFieldValue("subject", eventData?.subject);
      setFieldValue("description", eventData?.description);
      setFieldValue("companyId", eventData?.companyDetails?._id);
      setFieldValue(
        "timeZone",
        `${eventData?.timezone} ${eventData?.utcOffset}`
      );
      const isPreviousDate =
        getMoment().toDate() > new Date(eventData?.startDate);
      const isTodatePreviousDate =
        getMoment().toDate() > new Date(eventData?.endDate);
      setMinDate(
        isPreviousDate ? new Date(eventData?.startDate) : getMoment().toDate()
      );
      setStartTime(
        getMoment(eventData?.startDateUTCObject).format(DateTimeFormats.Time)
      );
      setMinDateOfToDate(
        isTodatePreviousDate
          ? new Date(eventData?.endDate)
          : getMoment().toDate()
      );
      setFieldValue("venu", eventData?.venue);
      setFieldValue("reminder", eventData?.reminderType);
      setFieldValue("repeatEvent", eventData?.repeatEvent);
      setFieldValue("fromDate", new Date(eventData?.startDate));
      setFieldValue("toDate", new Date(eventData?.endDate));
      setFieldValue("startTime", dayjs(eventData?.startDateUTCObject));
      setFieldValue("endtime", dayjs(eventData?.endDateUTCObject));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isView, eventData]);

  const updateDate = (value) => {
    setFieldValue("toDate", value);
    setFieldValue("fromDate", value);
    setFieldValue("startTime", getDayJs());
    setFieldValue("endtime", getDayJs().add(15, "minute"));
    setStartDateUTCObject(getMomentDate(value).format("YYYY-MM-DDTHH:mm[Z]"));
    setEndDateUTCObject(getMomentDate(value).format("YYYY-MM-DDTHH:mm[Z]"));
    setStartTime(getMoment().format(DateTimeFormats.Time));
    setStartTimeUTCObject(
      getMoment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    );
    setEndTimeUTCObject(
      getMoment().add(15, "minute").utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    );
  };
  const promiseOptions = (text) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(setOptionList(text.trim()));
      }, 500);
    });

  const handleInviteeEvent = (e) => {
    stopEventPropogation(e);
    handleEventKeySubmit(e, onOpenInviteeModal);
  };

  return (
    <Form>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <PageHeader
            containerClass="page-title formTitle p-3 row align-items-center"
            title={
              isView
                ? Translate("createEvent:viewHead")
                : eventData
                ? Translate("createEvent:edit")
                : Translate("createEvent:head")
            }
            onClick={() =>
              setTimeout(() => {
                navigate(-1);
              }, 500)
            }
            RightContainer={() => {
              return (
                eventData &&
                (eventData?.addedBy === userData?._id ||
                  eventData?.createEventMom) &&
                isView && (
                  <Stack className="card-options">
                    <a
                      href="/#"
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v manageIconColor" />
                    </a>
                    <div className="dropdown-menu dropdownAlignment">
                      {eventData?.addedBy === userData?._id && (
                        <div
                          className="cursor-pointer dropdown-item"
                          onClick={() =>
                            navigate("/calendar/editEvent", {
                              state: {
                                eventData: eventData,
                                edit: true,
                                isMOM: currentState?.isMOM,
                              },
                            })
                          }
                        >
                          {Translate("common:editToolTip")}
                        </div>
                      )}
                      {eventData?.addedBy === userData?._id && (
                        <div className="dropdown-divider" />
                      )}
                      {eventData?.addedBy === userData?._id && (
                        <div
                          className="cursor-pointer dropdown-item"
                          onClick={onDelete}
                        >
                          {Translate("common:deleteToolTip")}
                        </div>
                      )}
                      {eventData?.createEventMom &&
                        eventData?.addedBy === userData?._id &&
                        userData?.role?.type !==
                          userTypes.Vendor?.toUpperCase() && (
                          <div className="dropdown-divider" />
                        )}
                      {eventData?.createEventMom &&
                        userData?.role?.type !==
                          userTypes.Vendor?.toUpperCase() && (
                          <div
                            className="cursor-pointer dropdown-item"
                            onClick={OnAddMOM}
                          >
                            {Translate("common:addmom")}
                          </div>
                        )}
                    </div>
                  </Stack>
                )
              );
            }}
          />

          <div
            className="card-body"
            onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
          >
            <div className="row mt-4">
              <div className="form-group col-md-6 col-sm-12">
                <FormikDropdownPicker
                  options={allCompanyData}
                  value={selectedCompany}
                  name="companyId"
                  label={Translate("addTask:companyDropdownPlaceholder")}
                  placeholder={Translate("addTask:companyDropdownPlaceholder")}
                  onSelect={setSelectedCompany}
                  disabled={isView}
                  required={!isView}
                  autoFocus={isAdd}
                />
              </div>
              <div className="form-group col-md-6 col-sm-12">
                <FormikTextField
                  name="subject"
                  label={Translate("createEvent:subject")}
                  placeholder={Translate("createEvent:subjectPlaceholder")}
                  autoComplete={"off"}
                  maxLength={100}
                  disabled={isView}
                  required={!isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                {isView ? (
                  <FormikTextField
                    name="timeZone"
                    label={Translate("createEvent:timezone")}
                    placeholder={Translate("createEvent:dropdownPlaceholder")}
                    autoComplete={"off"}
                    disabled={isView}
                  />
                ) : (
                  <FormikSearchableDropDown
                    selected={timeZoneValue}
                    name="timeZone"
                    label={Translate("createEvent:timezone")}
                    placeholder={Translate("createEvent:dropdownPlaceholder")}
                    onSelect={(value) => {}}
                    options={timeZoneList}
                    onChange={promiseOptions}
                    disabled={isView}
                    disableOption={true}
                    isTimeZone={isView ? false : true}
                    required={!isView}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <FormikTextField
                  name="description"
                  label={Translate("createEvent:description")}
                  placeholder={Translate("createEvent:descriptionPlaceholder")}
                  autoComplete={"off"}
                  multiline
                  noOfRows={3}
                  maxLength={250}
                  disabled={isView}
                  required={!isView}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <FormikDatePicker
                  label={Translate("createEvent:from")}
                  containerStyle="datePickerContainer"
                  className="form-control bw-0"
                  format="yyyy/MM/dd"
                  name="fromDate"
                  value={values.fromDate}
                  onChange={updateDate}
                  disabled={isView}
                  minDate={minDate}
                  required={!isView}
                />
              </div>
              <div className="form-group col-md-6 col-sm-12">
                <FormikDatePicker
                  label={Translate("createEvent:to")}
                  containerStyle="datePickerContainer"
                  className="form-control bw-0"
                  format="yyyy/MM/dd"
                  name="toDate"
                  value={values.toDate}
                  disabled={isView}
                  minDate={minDateOfTodate}
                  required={!isView}
                  onChange={(value) => {
                    setFieldValue("toDate", value);
                    setFieldValue("startTime", getDayJs());
                    setFieldValue("endtime", getDayJs().add(15, "minute"));
                    setEndDateUTCObject(
                      getMoment(value).format("YYYY-MM-DDTHH:mm[Z]")
                    );
                    setStartTime(getMoment().format(DateTimeFormats.Time));
                    setStartTimeUTCObject(
                      getMoment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                    );
                    setEndTimeUTCObject(
                      getMoment()
                        .add(15, "minute")
                        .utc()
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                    );
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <FormikDatePicker
                  label={Translate("createEvent:startTime")}
                  // className="form-control bw-0"
                  format="HH:mm"
                  name="startTime"
                  value={values.startTime}
                  disabled={isView}
                  required={!isView}
                  onChange={(event) => {
                    if (dayjs(event).format() !== "Invalid Date") {
                      setStartTime(dayjs(event).format(DateTimeFormats.Time));
                      setFieldValue("startTime", dayjs(event));
                      setIsTimeDiffError(false);
                      setIsTimeError(false);
                      setStartTimeUTCObject(
                        getMoment(dayjs(event).toDate())
                          .utc()
                          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                      );
                      setFieldValue("endtime", dayjs(event).add(15, "minute"));
                      setEndTimeUTCObject(
                        getMoment(dayjs(event).toDate())
                          .add(15, "minute")
                          .utc()
                          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                      );
                    }
                  }}
                  isTimePicker
                />
              </div>
              <div className="form-group col-md-6 col-sm-12">
                <FormikDatePicker
                  label={Translate("createEvent:endtime")}
                  format="HH:mm"
                  name="endtime"
                  value={values.endtime}
                  disabled={isView}
                  required={!isView}
                  onChange={(event) => {
                    if (dayjs(event).format() !== "Invalid Date") {
                      let end = dayjs(event).format(DateTimeFormats.Time);
                      let diff = moment(end, DateTimeFormats.Time).diff(
                        moment(startTime, DateTimeFormats.Time),
                        "minutes"
                      );
                      if (
                        // eslint-disable-next-line radix
                        diff < 15 ||
                        moment(end, DateTimeFormats.Time) <
                          moment(startTime, DateTimeFormats.Time)
                      ) {
                        setFieldValue(
                          "endtime",
                          dayjs(values.startTime).add(15, "minute")
                        );
                        setEndTimeUTCObject(
                          getMoment(startTimeUTCObject)
                            .add(15, "minute")
                            .utc()
                            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                        );
                        setIsTimeError(true);
                        diff < 15 && setIsTimeDiffError(true);
                        /* showToast({
                          message:
                            "To time should be 15 min more than from time.",
                          type: "info",
                        }); */
                      } else {
                        //setStartTime(moment(event).format(DateTimeFormats.Time));
                        // setEndTime(moment(event).format(DateTimeFormats.Time));
                        setFieldValue("endtime", dayjs(event));
                        setIsTimeError(false);
                        setIsTimeDiffError(false);
                        setEndTimeUTCObject(
                          getMoment(dayjs(event).toDate())
                            .utc()
                            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                        );
                      }
                    }
                  }}
                  isTimePicker
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <FormikDropdownPicker
                  options={allEvents}
                  name="repeatEvent"
                  label={Translate("createEvent:repeatEvent")}
                  placeholder={Translate("createEvent:repeatEvent")}
                  onSelect={(value) =>
                    value === "CUSTOM" && setShowCustomModal(true)
                  }
                  disabled={isView}
                  required={!isView}
                />
              </div>
              <div className="form-group col-md-6 col-sm-12">
                <FormikDropdownPicker
                  options={allReminders}
                  name="reminder"
                  label={Translate("createEvent:reminder")}
                  placeholder={Translate("createEvent:setReminder")}
                  disabled={isView}
                  required={!isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <FormikTextField
                  name="venu"
                  label={Translate("createEvent:venue")}
                  placeholder={Translate("createEvent:venuePlaceholder")}
                  autoComplete={"off"}
                  multiline
                  noOfRows={2}
                  disabled={isView}
                  maxLength={validations?.taskDescription?.MAX}
                />
              </div>

              <div
                className="form-group col-md-6 col-sm-12"
                style={{ marginTop: "5px" }}
              >
                <StyledTypography className="labelStyling" component="span">
                  {Translate("createEvent:invited")}
                </StyledTypography>
                <StyledTypography
                  tabIndex="0"
                  onKeyDown={handleInviteeEvent}
                  className={`form-control ${!isView && "cursor-pointer"}`}
                  sx={{
                    background: isView ? "#e5e5e58f" : "",
                    color: isView
                      ? "#33303cad"
                      : eventInviteeList?.length > 0
                      ? ""
                      : "#918e8ef2",
                  }}
                  onClick={onOpenInviteeModal}
                >
                  {eventInviteeList?.length > 0
                    ? `${eventInviteeList?.length} ${Translate(
                        "createEvent:participants"
                      )}`
                    : Translate("createEvent:invitePlaceholder")}
                </StyledTypography>
              </div>
            </div>

            <Stack className="row">
              <Stack className="mb-2">
                <StyledTypography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "500",
                    width: "fit-content",
                  }}
                  className="cursor-pointer mx-2"
                  onClick={showParticipant}
                  color={colors.primary}
                >
                  {Translate("createEvent:showParticipants")}
                </StyledTypography>
              </Stack>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                className="mx-2"
              >
                <StyledTypography variant="subtitle1" className="mr-2">
                  {Translate("createEvent:markPrivate")}
                </StyledTypography>
                <Switch
                  onChange={() => {
                    setPrivateToggle((prev) => !prev);
                  }}
                  checked={privateToggle}
                  height={25}
                  width={50}
                  onHandleColor={colors.primary}
                  onColor={colors.primary_002}
                  disabled={isView}
                />
              </Stack>
            </Stack>
            {!isView && (
              <div className="text-right line_spacing">
                <div
                  className="btn btn-primary saveButton cursor-pointer"
                  onClick={() => {
                    const ctdStartDate = moment(values.fromDate);
                    const ctdEndDate = moment(values.toDate);
                    const endTime = dayjs(values.endtime).format(
                      DateTimeFormats.Time
                    );

                    let diff = moment(endTime, DateTimeFormats.Time).diff(
                      moment(startTime, DateTimeFormats.Time),
                      "minutes"
                    );
                    const isEditData = eventData
                      ? getMoment(eventData?.startDateUTCObject).format(
                          DateTimeFormats.Time
                        ) === startTime
                      : false;

                    if (
                      !isEditData &&
                      moment(values.fromDate).format(
                        DateTimeFormats.DayMonthYear
                      ) === getMoment().format(DateTimeFormats.DayMonthYear) &&
                      startTime < getMoment().format(DateTimeFormats.Time)
                    ) {
                      showToast({
                        message:
                          "Start time should not be less than current time.",
                        type: "error",
                      });
                    } else if (isTimeDiffError || diff < 15) {
                      showToast({
                        message:
                          "End time should be 15 min more than start time.",
                        type: "error",
                      });
                    } else if (
                      (values.startTime > values.endtime || isTimeError) &&
                      !ctdEndDate.isAfter(ctdStartDate)
                    ) {
                      showToast({
                        message: "End time should be greater then start time",
                        type: "error",
                      });
                    } else {
                      handleSubmit();
                    }
                  }}
                >
                  {Translate("common:save")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader loading={isLoading} />
      <CustomModal
        show={showCustomModal}
        onCancel={hideModal}
        data={values}
        setEventRecurrence={(value) => {
          setEventRecurrence(value);
          hideModal();
        }}
        eventRecurrence={eventRecurrence}
      />
      {showParticipantModal && (
        <ParticipantModal
          show={showParticipantModal}
          onHide={hideParticipant}
          participantList={eventInviteeList}
          handleInvitee={handleInvitee}
          hideClose={isView}
        />
      )}
      {showInviteeModal && (
        <ShareWithUser
          currentModule={shareModuleType?.EVENT}
          showModal={showInviteeModal}
          closeModal={onCloseInviteeModal}
          onSubmit={handleInvitee}
          selectedCompanyList={eventInviteeList}
        />
      )}
    </Form>
  );
};
export default CreateEventForm;
