import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDropzone } from "react-dropzone";
import { convertToB64 } from "../../common/Utils/commonFunction";
import { showToast } from "../../common/Utils/ToastMessage";
import { colors } from "../../common/Theme/Colors";
import Translate from "../../translation/utils/translateFunction";

const Dropzone = ({
  onfileSelect,
  icon,
  translate,
  maxFileSize,
  maximumFiles,
  acceptedImages,
  fileLength,
  deleteAlert,
  isProfile,
  isShowCameraDeleteIcon,
  iconTitle,
  className = "",
  groupProfile = false,
  dropzoneStyle,
  maxImageSize = 10485760, // 10 MB
  disabled = false,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: maximumFiles,
    maxSize: maxFileSize,
    accept: acceptedImages,
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: (acceptedFiles) => {
      // BLOCKING PDF's
      /*  const pdfFiles = acceptedFiles.filter(file => file.type === 'application/pdf');

      if(pdfFiles?.length) {
        showToast({
          message: Translate("common:invalidFileMsg"),
          type: "error",
        });

        return;
      } */

      const concatLength = fileLength + acceptedFiles.length;

      if (concatLength > maximumFiles) {
        if (maximumFiles === 1) {
          showToast({
            message: `${Translate("common:maximumLimitOne")}`,
            type: "error",
          });
        } else {
          showToast({
            message: `${Translate("common:maximum")} ${maximumFiles}${Translate(
              "common:fileAddLimitMsg10"
            )}`,
            type: "error",
          });
        }

        return;
      }

      acceptedFiles.map((file) => {
        const isImage = file?.type.startsWith("image/");

        const maxSize = isImage ? maxImageSize : maxFileSize;

        if (file?.size <= maxSize) {
          convertToB64(file).then((result) => {
            file.b64 = result;
          });

          concatLength <= maximumFiles && onfileSelect(file);
        } else {
          showToast({
            message: isImage
              ? Translate("common:imageSizeLimitMsg")
              : Translate("common:fileAddLimitMsg"),
            type: "error",
          });
        }
      });
    },

    onDropRejected: (acceptedFiles) => {
      const fileSize = acceptedFiles.map((ele) => ele.file.size);
      const fileType = acceptedFiles.map((ele) => ele.file.type);

      if (fileSize > maxFileSize) {
        if (maxFileSize === 52428800 || maxFileSize === 50000000) {
          showToast({
            message: Translate("common:dropZoneFileSizeMsg"),
            type: "error",
          });
        } else {
          if (fileType[0] === "image/jpeg") {
            showToast({
              message: Translate("common:imageSizeLimitMsg"),
              type: "error",
            });
          } else {
            showToast({
              message: Translate("common:dropZoneFileSizeMsg10MB"),
              type: "error",
            });
          }
        }
      }
      // else if (fileSize > maxFileSize && maxFileSize === 10485760) {
      //   console.log("first");
      //   showToast({
      //     message: Translate("common:dropZoneFileSizeMsg10MB"),
      //     type: "error",
      //   });
      // }
      else if (acceptedFiles.length > maximumFiles) {
        showToast({
          message: `${Translate("common:maximum")} ${maximumFiles}${Translate(
            "common:fileAddLimitMsg10"
          )}`,
          type: "error",
        });
      } else if (acceptedFiles.length > 1) {
        showToast({
          message: Translate("common:fileAddLimitMsg"),
          type: "error",
        });
      } else if (fileType !== acceptedImages) {
        showToast({
          message: Translate("common:invalidFileMsg"),
          type: "error",
        });
      }
    },
  });

  const RenderCameraDeleteIcon = () => {
    return (
      <div style={{ paddingLeft: 50, cursor: "pointer" }}>
        {isShowCameraDeleteIcon ? (
          <i
            onClick={deleteAlert}
            className={`note-icon-trash ${className}`}
            style={{
              position: "absolute",
              top: groupProfile ? 100 : 80,
              color: colors.white,
              backgroundColor: colors.primary,
              padding: groupProfile ? "4.5px 9px" : 4.5,
              borderRadius: 20,
              height: 33,
              width: 33,
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              cursor: "pointer",
              textAlign: "center",
            }}
          />
        ) : (
          <i
            onClick={open}
            className={`fa fa-camera ${className}`}
            style={{
              position: "absolute",
              top: groupProfile ? 100 : 80,
              color: colors.white,
              backgroundColor: colors.primary,
              padding: 8.4,
              borderRadius: 20,
              height: 34,
              width: 35,
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              cursor: "pointer",
              textAlign: "center",
            }}
          />
        )}
      </div>
    );
  };
  return (
    <>
      {!isProfile ? (
        <div className="container p-0">
          <div
            {...getRootProps({ className: "dropzone" })}
            style={dropzoneStyle}
            onClick={(e) => e.stopPropagation()}
          >
            <input {...getInputProps()} />
            {translate ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={open}
                disabled={disabled}
              >
                <i className={`mr-2 ${icon}`} />
                {translate}
              </button>
            ) : (
              <i className={`${icon}`} onClick={open} title={iconTitle} />
            )}
          </div>
        </div>
      ) : (
        <RenderCameraDeleteIcon />
      )}
    </>
  );
};

export default Dropzone;
