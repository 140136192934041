import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import Translate from "../../../translation/utils/translateFunction";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { InitialValues } from "./components/constants";
import PageHeader from "../../common/pageHeader";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction";
import { FormikDropdownPicker } from "../../FormikForm/component/FormikDropDownPicker";
import FormikDatePicker from "../../FormikForm/component/FormikDatePicker";
import { useLazyGetCompanyListQuery } from "../../../request/company";
import {
  useGenerateCustomReportMutation,
  useGetAssignedByGmListMutation,
  useGetAssignedByOwnerListMutation,
} from "../../../request/CustomReport";
import { useGetProjectDropdownListMutation } from "../../../request/Project";
import { getDropdownOptions } from "../../../common/Utils/commonFunction";
import Loader from "../../staff/Owner/LoadingComp";
import FormikSearchableDropDown from "../../FormikForm/component/FormikSearchableDropDown";
import FormikMultiSelectDropDown from "../../FormikForm/component/FormikMultiSelectDropDown";
import { CustomUserItem } from "../../common/CustomCellRender";
import { formatDateYYYYMMDD } from "../../../common/Utils/DateFormater";
import { defaultProjectOptions } from "../../AddTask/constants";
import { centerContent } from "../../AddTask/constants";
import { Persona } from "../../Persona";
import { userTypes } from "../../../common/users/userTypes";
import { getCustomReportValidation } from "./components/validators";
import { getMoment } from "../../../common/Utils/commonFunction";
import HighlightCard from "../../common/HighlightCard";
import AlertPermission from "../../AlertPermission";

const CompanyReport = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const isManager = userData?.role?.type === userTypes.Manager.toUpperCase();
  const maxMonth = userData?.masterData?.months || 0;
  const isOwner = userData?.role?.type === userTypes.Owner.toUpperCase();
  const isGM = userData?.role?.type === userTypes.GeneralManager.toUpperCase();

  const [selectedCompany, setSelectedCompany] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [projectOptions, setProjectOptions] = useState([defaultProjectOptions]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [selectedGM, setSelectedGM] = useState("");
  const [gmOptions, setGmOptions] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [managerOptions, setManagerOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [showOwner, setShowOwner] = useState(false);
  const [showGM, setShowGM] = useState(false);
  const [defaultUserOption, setDefaultUserOption] = useState("");
  const customReportSchema = getCustomReportValidation(
    showOwner,
    !showOwner && showGM
  );
  const [pdfData, setPdfData] = useState(null);
  const [showDownloadAlert, setShowDownloadAlert] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const [
    getCompanyList,
    { data: companyResponse, isLoading: isCompanyLoading },
  ] = useLazyGetCompanyListQuery();

  const [
    getProjectList,
    {
      data: projectData,
      isLoading: isProjectLoading,
      isSuccess: isProjectSuccess,
      isError: isProjectError,
      error: projectError,
    },
  ] = useGetProjectDropdownListMutation();

  const [
    getOwnerList,
    {
      data: ownerData,
      isLoading: isOwnerLoading,
      isSuccess: isOwnerSuccess,
      isError: isOwnerError,
      error: ownerError,
    },
  ] = useGetAssignedByOwnerListMutation();

  const [
    getGmAndManagerList,
    {
      data: gmData,
      isLoading: isGmLoading,
      isSuccess: isGmSuccess,
      isError: isGmError,
      error: gmError,
    },
  ] = useGetAssignedByGmListMutation();

  const [
    generateReport,
    {
      data: generatedReport,
      isLoading: isReportLoading,
      isSuccess: isReportSuccess,
      isError: isReportError,
      error: reportError,
    },
  ] = useGenerateCustomReportMutation();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData?.masterData) {
      const { role, primary, addedByRole } = userData;
      const { priority } = userData?.masterData;
      const roleType = role?.type;

      const priorityData = getDropdownOptions(priority, "", "", true);
      setPriorityOptions(priorityData);
      const selfOption = generateSelfOption();
      setDefaultUserOption(selfOption);

      if (
        primary === "YES" ||
        roleType === userTypes.Owner.toUpperCase() ||
        (roleType === userTypes.persoalAssistant.toUpperCase() &&
          addedByRole === userTypes.Owner.toUpperCase())
      ) {
        setShowOwner(true);
        setShowGM(true);
        isOwner && setOwnerOptions([selfOption]);
      } else if (
        roleType === userTypes.GeneralManager.toUpperCase() ||
        (roleType === userTypes.persoalAssistant.toUpperCase() &&
          addedByRole === userTypes.GeneralManager.toUpperCase())
      ) {
        setShowGM(true);
        isGM && setGmOptions([selfOption]);
      } else if (isManager) {
        setManagerOptions([selfOption]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const generateSelfOption = () => {
    const selfOption = {
      id: userData?._id,
      value: userData?.name,
      label: (
        <div style={centerContent}>
          <Persona name={"Self"} size={32} />
          Self
        </div>
      ),
    };

    return selfOption;
  };

  useEffect(() => {
    if (selectedCompany) {
      getProjectList({ companyId: [selectedCompany] });
      getOwnerList({ companyId: selectedCompany, searchText: "" });
      !isManager &&
        getGmAndManagerList({
          companyId: selectedCompany,
          searchTextGM: "",
          searchTextManager: "",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    if (companyResponse) {
      const companyData = getDropdownOptions(companyResponse, "name", "_id");
      setCompanyOptions(companyData);
    }

    return () => {
      setCompanyOptions([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  useEffect(() => {
    if (projectData?.data && isProjectSuccess) {
      const updatedOptions = projectData?.data.map((item) => ({
        label: item?.projectNumber ? (
          <span>
            {isRtl && <span>{` ${item?.title} `}</span>}
            <span>{`#${item?.projectNumber}`}</span>
            {!isRtl && <span>{` ${item?.title} `}</span>}
          </span>
        ) : (
          item?.title
        ),
        value: item?._id,
      }));

      setProjectOptions([defaultProjectOptions, ...updatedOptions]);
    }

    if (isProjectError) {
      showToast({
        message:
          projectError?.message || Translate("common:unknownServerError"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, isProjectSuccess, isProjectError]);

  const removeCurrentUser = (item) => item?._id !== userData?._id;

  useEffect(() => {
    if (ownerData && isOwnerSuccess) {
      const filteredData = isOwner
        ? ownerData?.data.filter(removeCurrentUser)
        : ownerData?.data;
      const owners = generateUserOptions(filteredData);
      const updatedData = isOwner ? [defaultUserOption, ...owners] : owners;

      setOwnerOptions(updatedData);
    }

    if (isOwnerError) {
      showToast({
        message: ownerError?.message || Translate("common:unknownServerError"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerData, isOwnerError, isOwnerSuccess]);

  useEffect(() => {
    if (gmData && isGmSuccess) {
      const { gmList, managersList } = gmData?.data[0];

      const filteredGM =
        showGM && !showOwner && isGM
          ? gmList.filter(removeCurrentUser)
          : gmList;
      const generalManagers = generateUserOptions(filteredGM);

      const filteredManager = isManager
        ? managersList.filter(removeCurrentUser)
        : managersList;
      const managers = generateUserOptions(filteredManager);

      showGM && !showOwner && isGM
        ? setGmOptions([defaultUserOption, ...generalManagers])
        : setGmOptions(generalManagers);
      isManager
        ? setManagerOptions([defaultUserOption, ...managers])
        : setManagerOptions(managers);
    }

    if (isGmError) {
      showToast({
        message: gmError?.message || Translate("common:unknownServerError"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gmData, isGmError, isGmSuccess]);

  const fetchPdf = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      handleDownload(blob);
    } catch (error) {
      showToast({
        message: Translate("common:pdfDownloadError"),
        type: "error",
      });
      setIsPdfLoading(false);
      return false;
    }
  };

  useEffect(() => {
    if (generatedReport?.data && isReportSuccess) {
      if (!generatedReport?.success) {
        showToast({
          message: generatedReport?.message,
          type: "error",
        });
        return;
      }
      setPdfData(generatedReport?.data);
      showToast({
        message: generatedReport?.message,
        type: "success",
      });
      setShowDownloadAlert(true);
    }

    if (isReportError) {
      showToast({
        message: reportError?.message || Translate("common:unknownServerError"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedReport, isReportSuccess, isReportError]);

  const generateUserOptions = (data) => {
    const options = data.map((user) => {
      const { name, _id, profileUrl, login, role } = user;

      return {
        id: _id,
        value: name,
        label: (
          <CustomUserItem
            userName={name}
            title={name}
            role={role || ""}
            profileUrl={profileUrl}
            email={login?.email}
            customClass={"addtaskUserEllipsisTitle"}
          />
        ),
      };
    });

    return options;
  };

  const resetDropdownState = () => {
    setSelectedProject("");
    setProjectOptions([defaultProjectOptions]);
    setSelectedOwner("");
    showOwner && isOwner
      ? setOwnerOptions([defaultUserOption])
      : setOwnerOptions([]);
    setSelectedGM("");
    showGM && !showOwner && isGM
      ? setGmOptions([defaultUserOption])
      : setGmOptions([]);
    setSelectedManager("");
    isManager ? setManagerOptions([defaultUserOption]) : setManagerOptions([]);
  };

  const resetOnCompanyChange = () => {
    formikRef.current.setFieldValue("projectId", "");
    formikRef.current.setFieldValue("ownerId", "");
    formikRef.current.setFieldValue("gmId", "");
    formikRef.current.setFieldValue("managerId", "");
    resetDropdownState();
  };

  const resetFormikForm = () => {
    formikRef.current.resetForm();
    setSelectedCompany("");
    resetDropdownState();
  };

  const onSubmit = (formValues) => {
    const timeSuffix = "T00:00:00.000+00:00";
    const reqObj = {
      fromDate: formatDateYYYYMMDD(formValues.fromDate) + timeSuffix,
      toDate: formatDateYYYYMMDD(formValues.toDate) + timeSuffix,
      companyId: formValues.companyId,
      projectId: formValues.projectId === "null" ? null : formValues.projectId,
      ownerId: formValues.ownerId || null,
      gmId: formValues.gmId || null,
      managerId: formValues.managerId || null,
      priority: formValues.priority,
    };

    generateReport(reqObj);
  };

  const closeDownloadAlert = () => {
    if (isPdfLoading) return;
    setShowDownloadAlert(false);
    setIsPdfLoading(false);
    setPdfData(null);
  };

  const handleDownload = (blobUrl) => {
    if (blobUrl) {
      const blob = new Blob([blobUrl], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = pdfData?.name || "download.pdf";
      link.click();
      // Clean up and remove the link
      link.remove();
    } else {
      console.error(Translate("common:pdfDownloadError"));
    }
    closeDownloadAlert();
  };

  const onBtnClickDownload = () => {
    setIsPdfLoading(true);
    fetchPdf(pdfData?.url);
  };
  const onSelectSelf = (user) => {
    const userObj = {
      id: userData?._id,
      value: userData?.name,
      label: (
        <CustomUserItem
          userName={userData?.name}
          title={userData?.name}
          role={userData?.role?.type || ""}
          profileUrl={userData?.profileUrl}
          email={userData?.login?.email}
          customClass={"addtaskUserEllipsisTitle"}
        />
      ),
    };

    userTypes.Owner === user
      ? setSelectedOwner(userObj)
      : userTypes.GeneralManager === user
      ? setSelectedGM(userObj)
      : setSelectedManager(userObj);
  };
  return (
    <>
      <Formik
        initialValues={InitialValues}
        innerRef={formikRef}
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={customReportSchema}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          return (
            <>
              <div className="row container_spacing">
                <div className="card card_spacing">
                  <PageHeader
                    title={
                      <h5 className="page-title">
                        {Translate("drawer:companyReport")}
                      </h5>
                    }
                    onClick={() => navigate("/")}
                    containerClass="page-title formTitle p-3 row align-items-center"
                  />
                  <div className="card-body">
                    <div
                      onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
                    >
                      <div className="row mt-3">
                        <div className="form-group col-md-12">
                          <HighlightCard
                            content={`${Translate(
                              "customReport:dateNote1"
                            )} ${maxMonth} ${Translate(
                              "customReport:dateNote2"
                            )}`}
                          />
                        </div>
                        <div className="form-group col-md-4 col-sm-12">
                          <FormikDatePicker
                            label={Translate("customReport:fromDate")}
                            containerStyle="datePickerContainer"
                            className="form-control bw-0"
                            format="yyyy/MM/dd"
                            name="fromDate"
                            value={values.fromDate}
                            onChange={(value) => {
                              setFieldValue("fromDate", value);
                              setFieldValue("toDate", "");
                            }}
                            required
                          />
                        </div>
                        <div className="form-group col-md-4 col-sm-12">
                          <FormikDatePicker
                            label={Translate("customReport:toDate")}
                            containerStyle="datePickerContainer"
                            className="form-control bw-0"
                            format="yyyy/MM/dd"
                            name="toDate"
                            value={values.toDate}
                            onChange={(value) => {
                              setFieldValue("toDate", value);
                            }}
                            minDate={values.fromDate}
                            maxDate={
                              values.fromDate && maxMonth
                                ? getMoment(values.fromDate)
                                    .add(maxMonth, "months")
                                    .toDate()
                                : null
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-4 col-sm-12">
                          <FormikDropdownPicker
                            options={companyOptions}
                            value={selectedCompany}
                            name="companyId"
                            label={Translate("vendors:selectCompany")}
                            placeholder={Translate(
                              "vendors:dropdownPlaceholder"
                            )}
                            onSelect={(value) => {
                              setSelectedCompany(value);
                              selectedCompany !== value &&
                                resetOnCompanyChange();
                            }}
                            required
                          />
                        </div>
                        <div className="form-group col-md-4 col-sm-12">
                          <FormikSearchableDropDown
                            selected={selectedProject}
                            onSelect={(item) => {
                              setSelectedProject(item);
                              setFieldValue("projectId", item?.value);
                            }}
                            options={projectOptions}
                            placeholder={Translate(
                              "addTask:projectPlaceholder"
                            )}
                            label={Translate("addTask:project")}
                            name="projectId"
                            menuStyle={{ direction: "ltr" }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-4 col-sm-12">
                          <FormikMultiSelectDropDown
                            options={priorityOptions}
                            name="priority"
                            label={Translate("manageTask:priority")}
                            placeholder={Translate(
                              "manageTask:priorityPlaceHolder"
                            )}
                            onSelect={(value) => {
                              setFieldValue("priority", value);
                            }}
                            required
                          />
                        </div>
                      </div>
                      {showOwner && (
                        <div className="row">
                          <div className="form-group col-md-4 col-sm-12">
                            <FormikSearchableDropDown
                              selected={selectedOwner}
                              onSelect={(value) => {
                                value?.id === userData._id
                                  ? onSelectSelf(userTypes.Owner)
                                  : setSelectedOwner(value);
                                setFieldValue("ownerId", value.id);
                              }}
                              options={ownerOptions}
                              placeholder={Translate(
                                "customReport:assignedByOwner"
                              )}
                              label={Translate("customReport:assignedByOwner")}
                              name="ownerId"
                              disabled={selectedGM || selectedManager}
                              required
                            />
                          </div>
                        </div>
                      )}
                      {showGM && (
                        <div className="row">
                          <div className="form-group col-md-4 col-sm-12">
                            <FormikSearchableDropDown
                              selected={selectedGM}
                              onSelect={(value) => {
                                value?.id === userData._id
                                  ? onSelectSelf(userTypes.GeneralManager)
                                  : setSelectedGM(value);
                                setFieldValue("gmId", value.id);
                              }}
                              options={gmOptions}
                              placeholder={Translate(
                                "customReport:assignedByGm"
                              )}
                              label={Translate("customReport:assignedByGm")}
                              name="gmId"
                              disabled={selectedOwner || selectedManager}
                              required
                            />
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="form-group col-md-4 col-sm-12">
                          <FormikSearchableDropDown
                            selected={selectedManager}
                            onSelect={(value) => {
                              value?.id === userData._id
                                ? onSelectSelf(userTypes.Manager)
                                : setSelectedManager(value);

                              setFieldValue("managerId", value.id);
                            }}
                            options={managerOptions}
                            placeholder={Translate(
                              "customReport:assignedByManager"
                            )}
                            label={Translate("customReport:assignedByManager")}
                            name="managerId"
                            disabled={selectedOwner || selectedGM}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-right card-footer line_spacing">
                      <button
                        type="submit"
                        className="btn btn-primary saveButton"
                        onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
                        onClick={handleSubmit}
                      >
                        {Translate("customReport:generateReport")}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-white addButton"
                        onClick={resetFormikForm}
                      >
                        {Translate("customReport:reset")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
      <Loader
        loading={
          isCompanyLoading ||
          isProjectLoading ||
          isOwnerLoading ||
          isGmLoading ||
          isReportLoading
        }
      />
      <AlertPermission
        show={showDownloadAlert}
        title={Translate("common:download")}
        subTitle={`${Translate("common:downloadAlert")} ${pdfData?.name}?`}
        okTitle={Translate("common:download")}
        onOk={onBtnClickDownload}
        onCanel={closeDownloadAlert}
        isLoading={isPdfLoading}
      />
    </>
  );
};

export default CompanyReport;
