import Picker from "emoji-picker-react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  convertStringForHRManager,
  getNameAndExtFromFile,
} from "../../../common/Utils/commonFunction";
import {
  useAcceptTaskByAssigneeMutation,
  useAddCommentMutation,
  useApproveTaskByReporterMutation,
  useDeleteCommentMutation,
  useDisapproveTaskByReporterMutation,
  useEditCommentMutation,
  useLazyGetAssignToCollectionQuery,
  useLazyGetCommentsQuery,
  useLazyGetTaskDetailQueryQuery,
  useMarkTaskAsCompletedMutation,
  useReassignTaskMutation,
  useRejectTaskByAssigneeMutation,
  useResolveTaskMutation,
  useStartSelfAssignTaskMutation,
  useStartTaskMutation,
  useTaskHistoryMutation,
  useReAllocateTaskMutation,
  useDisApproveReAllocateTaskReqMutation,
  useGetShareTaskMutation,
  useApproveTaskByManagerMutation,
  useDisapproveTaskByManagerMutation,
} from "../../../request/Task";
import Translate from "../../../translation/utils/translateFunction";
import AlertPermission from "../../AlertPermission";
import Dropzone from "../../CommonFileUpload/Dropzone";
import CommonImage from "../../ImageGallery/CommonImage";
import NoDataFound from "../../ManageTask/NoDataFound";
import VoiceNotes from "../../VoiceNotes/Components/VoiceNotes";
import Loader from "../../staff/Owner/LoadingComp";
import {
  taskApprovalEnums,
  taskDetailOptionShare,
  taskDetailOptions,
  taskStatusType,
} from "../constants";
import { InputTextField } from "./AddComment";
import AddListing from "./AddListing";
import TaskDetails from "./TaskDetails";
import TaskHistory from "./TaskHistory";
import TaskInfo from "./TaskInfo";
import { moduleType } from "../../Task/Components/constants";
import { calendarEnums } from "../../Calendar/utils";
import ShareTaskModal from "../../../common/ShareModal";
import { CustomUserItem } from "../../common/CustomCellRender";
import { getProjectTitle } from "../../Task/Components/utils";

const AddCommentListing = ({ replyingTo }) => {
  const { validations, isRtl } = useSelector((state) => state.formanagement);
  const [filesAdded, setfilesAdded] = useState([]);
  const [taskDetailData, setTaskDetailData] = useState(undefined);
  const [images, setImages] = useState([]);
  const [attachmentsArray, setAttachmentsArray] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [voiceNoteObj, setVoiceNoteObj] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [HistorypageNo, setHistoryPageNo] = useState(1);
  const [data, setData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showAttachment, setAttachmentBox] = useState(true);
  const [showVoiceNote, setShowVoiceNote] = useState(true);
  const [showTaskHistory, setShowTaskHistory] = useState(false);
  const [comment, setComment] = useState("");
  const [editCommentId, setEditCommentId] = useState();
  const [cross, setCross] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [removedData, setRemovedData] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [userDetails, setUserDetails] = useState([]);
  const [assigntoOptionList, setAssigntoOptionList] = useState([]);
  const [searchTextReport, setSearchTextReport] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [commentLoading, setCommentLoading] = useState(false);
  const [taskLink, setTaskLink] = useState();
  const [showShareModule, setShowShareModule] = useState(false);
  const { state, search } = useLocation();
  const query = new URLSearchParams(search);

  const acceptedFileType = {
    "image/png": [
      ".png",
      ".jpg",
      ".jpeg",
      ".pdf",
      ".xls",
      ".xlsx",
      ".heif",
      ".heic",
    ],
  };
  const navigate = useNavigate();
  const [
    getTaskDetail,
    {
      data: taskData,
      isSuccess: taskDataSuccess,
      isLoading: isTaskDataLoading,
      isFetching: isTaskDataFetching,
    },
  ] = useLazyGetTaskDetailQueryQuery();

  const [
    getComments,
    {
      data: commentsListing,
      isSuccess: isCommentsSuccess,
      isLoading: isCommentsLoading,
      isFetching: isCommentsFetching,
      error: commentError,
      isError: isCommentError,
    },
  ] = useLazyGetCommentsQuery();

  const [
    addComment,
    { isSuccess: isAddCommentSuccess, isLoading: isCommentAdding },
  ] = useAddCommentMutation();

  const [editComment, { isSuccess: isEditCommentSuccess }] =
    useEditCommentMutation();

  const [
    deleteComment,
    { isSuccess: isDeleteCommentSuccess, isLoading: isCommentDeleting },
  ] = useDeleteCommentMutation();
  const [
    getShareTask,
    {
      data: serverShareResponse,
      isSuccess: isShareSuccess,
      isLoading: isShareLoading,
    },
  ] = useGetShareTaskMutation();
  const [
    getTaskHistory,
    {
      data: taskHistoryData,
      isLoading: taskHistoryLoading,
      isSuccess: taskHistorySuccess,
    },
  ] = useTaskHistoryMutation();

  const [
    acceptTaskByAssigneeApi,
    {
      data: acceptedTaskData,
      isSuccess: isAcceptTaskSuccess,
      isLoading: isAcceptTaskLoading,
      isError: isAcceptTaskError,
      error: errorAcceptTask,
    },
  ] = useAcceptTaskByAssigneeMutation();

  const [
    rejectTaskByAssigneeApi,
    {
      data: rejectedTaskData,
      isSuccess: isRejectTaskSuccess,
      isLoading: isRejectTaskLoading,
      isError: isRejectTaskError,
      error: errorRejectTask,
    },
  ] = useRejectTaskByAssigneeMutation();

  const [
    startTaskApi,
    {
      data: startedTaskData,
      isSuccess: isStartTaskSuccess,
      isLoading: isStartTaskLoading,
      isError: isStartTaskError,
      error: errorStartTask,
    },
  ] = useStartTaskMutation();

  const [
    startSelfAssignTaskApi,
    {
      data: startedSelfAssignTaskData,
      isSuccess: isStartSelfAssignTaskSuccess,
      isLoading: isStartSelfAssignTaskLoading,
      isError: isStartSelfAssignTaskError,
      error: errorStartSelfAssignTask,
    },
  ] = useStartSelfAssignTaskMutation();

  const [
    resolveTaskApi,
    {
      data: resolvedTaskData,
      isSuccess: isResolveTaskSuccess,
      isLoading: isResolveTaskLoading,
      isError: isResolveTaskError,
      error: errorResolveTask,
    },
  ] = useResolveTaskMutation();

  const [
    approveTaskByReporterApi,
    {
      data: approvedTaskData,
      isSuccess: isApprovedTaskSuccess,
      isLoading: isApprovedTaskLoading,
      isError: isApprovedTaskError,
      error: errorApprovedTask,
    },
  ] = useApproveTaskByReporterMutation();

  const [
    disapproveTaskByReporterApi,
    {
      data: disapprovedTaskData,
      isSuccess: isDisapprovedTaskSuccess,
      isLoading: isDisapprovedTaskLoading,
      isError: isDisapprovedTaskError,
      error: errorDisapprovedTask,
    },
  ] = useDisapproveTaskByReporterMutation();

  const [
    markTaskAsCompletedApi,
    {
      data: completedTaskData,
      isSuccess: isCompletedTaskSuccess,
      isLoading: isCompletedTaskkLoading,
      isError: isCompletedTaskError,
      error: errorCompletedTask,
    },
  ] = useMarkTaskAsCompletedMutation();

  const [
    reAllocateTaskApi,
    {
      data: reAllocatedTaskData,
      isSuccess: isReAllocateTaskSuccess,
      isLoading: isReAllocateTaskLoading,
      isError: isReAllocateTaskError,
      error: errorReAllocateTask,
    },
  ] = useReAllocateTaskMutation();

  const [
    disApproveReAllocationReqApi,
    {
      data: disApprovedReAlocTaskData,
      isSuccess: isDisApprovedReAllocReqSuccess,
      isLoading: isDisApprovedReAllocReqLoading,
      isError: isDisApprovedReAllocReqError,
      error: errorDisApprovedReAllocReq,
    },
  ] = useDisApproveReAllocateTaskReqMutation();

  const [
    approveTaskByManagerApi,
    {
      data: approvedTaskByManager,
      isSuccess: isApprovedTaskByManagerSuccess,
      isLoading: isApprovedTaskByManagerLoading,
      isError: isApprovedTaskByManagerError,
      error: errorApprovedTaskByManager,
    },
  ] = useApproveTaskByManagerMutation();

  const [
    disapproveTaskByManagerApi,
    {
      data: disapprovedTaskByManager,
      isSuccess: isDisapprovedTaskByManagerSuccess,
      isLoading: isDisapprovedTaskByManagerLoading,
      isError: isDisapprovedTaskByManagerError,
      error: errorDisapprovedTaskByManager,
    },
  ] = useDisapproveTaskByManagerMutation();

  const [
    getAssignTo,
    { data: assignToData, isSuccess: issuccessAssignToList },
  ] = useLazyGetAssignToCollectionQuery();

  const [reassignTask, { data: serverResponse, isSuccess: isReassignSuccess }] =
    useReassignTaskMutation();

  useEffect(() => {
    if (taskData?.data && taskData?.data?.canReassigned) {
      getAssignTo({
        searchText: searchTextReport,
        companyId: taskData?.data?.company?._id,
        isReassignedFlow: true,
      });
    }
  }, [taskData, searchTextReport]);

  useEffect(() => {
    if (issuccessAssignToList && assignToData) {
      const assignToList = [];
      assignToData.length > 0 &&
        assignToData.map((assigneeItem) => {
          const { name, _id, profileUrl, email, role } = assigneeItem;
          const obj = {
            id: _id,
            value: name,
            label: (
              <CustomUserItem
                userName={name}
                title={role?.type ? `${name} | ${role?.type}` : name}
                profileUrl={profileUrl}
                email={email}
              />
            ),
          };
          assignToList.push(obj);
        });
      assignToList.length && setAssigntoOptionList(assignToList);
    }
  }, [issuccessAssignToList]);

  const commentRequest = useMemo(() => {
    return {
      taskId: taskData?.data?._id,
      pageNo: pageNo,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, taskData?.data?._id]);

  const taskHistoryRquest = useMemo(() => {
    return {
      taskId: taskData?.data?._id,
      pageNo: HistorypageNo,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HistorypageNo, taskData?.data?._id]);

  useEffect(() => {
    if (
      ((state?.taskId || query?.get("taskid")) && !taskData?.data) ||
      (state?.isNotification && state?.taskId !== taskData?.data?._id)
    ) {
      getTaskDetail(query?.get("taskid") || state?.taskId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, search]);

  const handleComments = () => {
    setCommentLoading(true);
    getComments(commentRequest);
  };

  useEffect(() => {
    if (commentRequest?.taskId && (state || query?.get("taskid"))) {
      handleComments();
    }
  }, [commentRequest, state]);

  useEffect(() => {
    if (isAddCommentSuccess || isEditCommentSuccess) {
      handleComments();
    }
  }, [isAddCommentSuccess, isEditCommentSuccess]);

  useEffect(() => {
    if (isDeleteCommentSuccess) {
      pageNo !== 1 ? setPageNo(1) : handleComments();
    }
  }, [isDeleteCommentSuccess]);

  useEffect(() => {
    if (taskData?.data || (state?.isNotification && taskData?.data?._id)) {
      const { _id } = taskData?.data;

      getTaskHistory({
        taskId: _id,
        pageNo: HistorypageNo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, taskData, taskHistoryRquest]);

  useEffect(() => {
    if (isCommentsSuccess && commentsListing) {
      const { record } = commentsListing;
      const tempComments = pageNo === 1 ? record : data.concat(record);
      setData(tempComments);
      setCommentLoading(false);
    }
    if (taskHistoryData && taskHistorySuccess) {
      const { record } = taskHistoryData?.data;
      const { history } = record;
      const tempHistories =
        HistorypageNo === 1 ? history : historyData.concat(history);
      setHistoryData(tempHistories);
    }
    if (taskData?.data && taskDataSuccess) {
      if (taskData?.success == true) {
        const { attachment, voiceNote, reporter, assignee, reassignTo } =
          taskData?.data;

        setTaskDetailData(taskData?.data);
        setUserDetails([]);
        const attachmentArray = [];
        attachment.map((attachmentItem, idx) => {
          const obj = {
            name: attachmentItem?.taskFileName,
            path: attachmentItem?.taskFileName,
            ...attachmentItem,
          };
          attachmentArray.push(obj);
        });
        voiceNote &&
          setVoiceNoteObj({
            byteCode: voiceNote.buffer,
            timeLength: voiceNote.timeLength,
            src: voiceNote.link,
            createdAt: voiceNote.createdAt,
          });
        setAttachmentsArray(attachmentArray);
        setAttachments(attachment?.map((item) => item?.url));
        setUserDetails([reporter, assignee, reassignTo || {}]);
      } else {
        showToast({ message: taskData?.message, type: "error" });
        navigate(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskData, commentsListing, taskHistoryData]);

  useEffect(() => {
    if (commentError && isCommentError) {
      showToast({
        message:
          JSON.stringify(commentError.data) ||
          Translate("common:unknownServerError"),
        type: "error",
      });
      setCommentLoading(false);
    }
  }, [commentError, isCommentError]);

  useEffect(() => {
    const array = [...filesAdded];
    setImages(array.map((ele) => ele.preview || ele?.url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAdded]);
  useEffect(() => {
    if (isShareSuccess && serverShareResponse?.data) {
      setTaskLink(serverShareResponse?.data);
      setShowShareModule(true);
    }
  }, [isShareSuccess]);
  useEffect(() => {
    if (
      (isAcceptTaskError && errorAcceptTask) ||
      (isRejectTaskError && errorRejectTask) ||
      (isStartTaskError && errorStartTask) ||
      (isStartSelfAssignTaskError && errorStartSelfAssignTask) ||
      (isResolveTaskError && errorResolveTask) ||
      (isApprovedTaskError && errorApprovedTask) ||
      (isDisapprovedTaskError && errorDisapprovedTask) ||
      (isCompletedTaskError && errorCompletedTask) ||
      (isReAllocateTaskError && errorReAllocateTask) ||
      (isDisApprovedReAllocReqError && errorDisApprovedReAllocReq) ||
      (isApprovedTaskByManagerError && errorApprovedTaskByManager) ||
      (isDisapprovedTaskByManagerError && errorDisapprovedTaskByManager)
    ) {
      const errorMsg =
        errorAcceptTask?.data?.message ||
        errorRejectTask?.data?.message ||
        errorStartTask?.data?.message ||
        errorStartSelfAssignTask?.data?.message ||
        errorResolveTask?.data?.message ||
        errorApprovedTask?.data?.message ||
        errorDisapprovedTask?.data?.message ||
        errorCompletedTask?.data?.message ||
        errorReAllocateTask?.data?.message ||
        errorDisApprovedReAllocReq?.data?.message ||
        errorApprovedTaskByManager?.data?.message ||
        errorDisapprovedTaskByManager?.data?.message ||
        Translate("common:unknownServerError");

      showToast({
        message: errorMsg,
        type: "error",
      });
    }

    if (
      (isAcceptTaskSuccess && acceptedTaskData) ||
      (isRejectTaskSuccess && rejectedTaskData) ||
      (isStartTaskSuccess && startedTaskData) ||
      (isStartSelfAssignTaskSuccess && startedSelfAssignTaskData) ||
      (isResolveTaskSuccess && resolvedTaskData) ||
      (isApprovedTaskSuccess && approvedTaskData) ||
      (isDisapprovedTaskSuccess && disapprovedTaskData) ||
      (isCompletedTaskSuccess && completedTaskData) ||
      (isReassignSuccess && serverResponse) ||
      (isReAllocateTaskSuccess && reAllocatedTaskData) ||
      (isDisApprovedReAllocReqSuccess && disApprovedReAlocTaskData) ||
      (isApprovedTaskByManagerSuccess && approvedTaskByManager) ||
      (isDisapprovedTaskByManagerSuccess && disapprovedTaskByManager)
    ) {
      getTaskDetail(query?.get("taskid") || state?.taskId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    acceptedTaskData,
    errorAcceptTask,
    rejectedTaskData,
    errorRejectTask,
    startedTaskData,
    errorStartTask,
    startedSelfAssignTaskData,
    errorStartSelfAssignTask,
    resolvedTaskData,
    errorResolveTask,
    approvedTaskData,
    errorApprovedTask,
    disapprovedTaskData,
    errorDisapprovedTask,
    completedTaskData,
    errorCompletedTask,
    serverResponse,
    reAllocatedTaskData,
    errorReAllocateTask,
    isReAllocateTaskError,
    errorDisApprovedReAllocReq,
    disApprovedReAlocTaskData,
    errorApprovedTaskByManager,
    errorDisapprovedTaskByManager,
    approvedTaskByManager,
    disapprovedTaskByManager,
  ]);

  const addFiles = (file) => {
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/gif" ||
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
        ? URL.createObjectURL(file)
        : null;
    if (url) {
      file.preview = url;
    }
    if (filesAdded.length < 1) {
      setfilesAdded([...filesAdded, file]);
    } else {
      showToast({
        message: Translate("common:imagePreviewAlert"),
        type: "error",
      });
    }
  };

  const removeFile = (file) => {
    if (!file?.b64) {
      setRemovedData([...removedData, file?.url]);
    }
    const deletedFiles = filesAdded.filter((ele) => ele.name !== file.name);
    setfilesAdded(deletedFiles);
  };

  const resetCommentData = () => {
    setPageNo(1);
    cancelDelete();
    setComment("");
    setfilesAdded([]);
    setEditCommentId();
    showPicker && setShowPicker((val) => !val);
    setCross(false);
  };

  const clickHandler = () => {
    if (comment?.trim() === "") {
      showToast({ message: Translate("addTask:commentAlert"), type: "info" });
      return;
    }
    const b64Array = filesAdded[0]?.b64;
    let newComment = {
      commentId: editCommentId,
      taskId: taskData?.data?._id,
      comment: comment,
      deletedAttachments: removedData,
    };

    if (b64Array) {
      newComment = {
        ...newComment,
        attachment: filesAdded.map((item) => {
          return {
            url: item?.b64 || item.url,
            type: item?.type,
            commentFileName: item?.name,
            commentFileExt:
              getNameAndExtFromFile(item?.name)?.length &&
              getNameAndExtFromFile(item?.name)[1],
          };
        }),
        isAttachment: true,
      };
    } else {
      newComment = {
        ...newComment,
        isAttachment: filesAdded && filesAdded?.length ? true : false,
        attachment: filesAdded[0],
      };
    }
    editCommentId ? editComment(newComment) : addComment(newComment);
    resetCommentData();
  };

  const handlerChange = (e) => {
    if (e.key === "Enter") {
      comment?.trim() && clickHandler();
    }
  };

  const onEmojiClick = (emojiObject) => {
    setComment((prevInput) => prevInput + emojiObject.emoji);
  };

  const onEdit = (item) => {
    setEditCommentId(item?._id);
    setComment(item?.comment);
    setfilesAdded(item?.attachment);
  };

  const onDelete = () => {
    deleteComment({
      commentId: selectedItem?._id,
      deletedAttachments: filesAdded.map((item) => item?.url),
    });
    cancelDelete();
    selectedItem?._id === editCommentId && resetCommentData();
  };

  const showDelete = (item) => {
    setSelectedItem(item);
    setShowDeleteAlert(true);
  };

  const cancelDelete = () => {
    setSelectedItem();
    setShowDeleteAlert(false);
  };
  const onCancelShareModal = () => setShowShareModule(false);

  const taskApprovalRequest = (reqType, value) => {
    const reqObj = { taskId: query.get("taskid") || state?.taskId };

    switch (reqType) {
      case taskApprovalEnums.ACCEPT_BY_ASSIGNEE: {
        acceptTaskByAssigneeApi(reqObj);
        break;
      }
      case taskApprovalEnums.REJECT_BY_ASSIGNEE: {
        rejectTaskByAssigneeApi({
          taskId: query.get("taskid") || state?.taskId,
          message: value,
        });
        break;
      }
      case taskApprovalEnums.START: {
        startTaskApi(reqObj);
        break;
      }
      case taskApprovalEnums.START_SELF_ASSIGNED: {
        startSelfAssignTaskApi(reqObj);
        break;
      }
      case taskApprovalEnums.RESOLVE: {
        resolveTaskApi(reqObj);
        break;
      }
      case taskApprovalEnums.APPROVE_BY_REPORTER: {
        approveTaskByReporterApi(reqObj);
        break;
      }
      case taskApprovalEnums.DISAPPROVE_BY_REPORTER: {
        disapproveTaskByReporterApi({
          taskId: query.get("taskid") || state?.taskId,
          message: value,
        });
        break;
      }
      case taskApprovalEnums.MARK_AS_COMPLETED: {
        markTaskAsCompletedApi(reqObj);
        break;
      }
      case taskApprovalEnums.REALLOCATION_REQUEST: {
        reAllocateTaskApi(reqObj);
        break;
      }
      case taskApprovalEnums.DISAPPROVE_REALLOCATION_REQ: {
        disApproveReAllocationReqApi({ ...reqObj, message: value });
        break;
      }
      case taskApprovalEnums.APPROVE_BY_MANAGER: {
        approveTaskByManagerApi(reqObj);
        break;
      }
      case taskApprovalEnums.DISAPPROVE_BY_MANAGER: {
        disapproveTaskByManagerApi({
          taskId: query.get("taskid") || state?.taskId,
          message: value,
        });
        break;
      }
      default:
        break;
    }
  };

  const getMenuNavigate = (selectedMenu) => {
    switch (selectedMenu) {
      case 0: {
        navigate("/subtask/list", {
          state: {
            taskId: taskDetailData?._id,
            parentState: state?.parentState ?? state,
            parentNavigation: state?.navigateFrom || "",
            isTaskDetails: true,
          },
        });
        break;
      }
      case 1: {
        navigate("/taskDetails/taskdependancy", {
          state: {
            taskId:
              taskDetailData?.moduleType === moduleType.SUBTASK
                ? taskDetailData?.parentTaskDetails?._id
                : taskDetailData?._id,
          },
        });
        break;
      }
      case 2: {
        getShareTask({ taskId: taskDetailData?._id });
        break;
      }
      default:
        break;
    }
  };

  const onSaveChanges = () => {
    const obj = {
      taskId: taskData?.data?._id,
      assignTo: assignTo?.id,
    };
    reassignTask(obj);
  };

  const handleBackNavigation = () => {
    if (state?.parentState) {
      const { parentState } = state;

      if (parentState?.isStaffReports) {
        return navigate("/reports/staff/view", { state: parentState?.staffId });
      }

      if (parentState?.isWorkloadReports) {
        return navigate("/workloadDetails", {
          state: parentState?.workloadDetails,
        });
      }

      if (state?.navigateFrom === "/relatedTaskListing") {
        return navigate(state?.navigateFrom, {
          state: parentState?.parentState || parentState,
        });
      }
    }

    return navigate(state?.navigateFrom ?? -1);
  };

  return (
    <>
      {isTaskDataFetching ||
      isTaskDataLoading ||
      (!isCommentsSuccess && pageNo === 1) ? (
        <Loader
          loading={
            isTaskDataFetching ||
            isTaskDataLoading ||
            isCommentsLoading ||
            taskHistoryLoading
          }
        />
      ) : (
        <>
          <div
            className={`section-body ${
              isRtl ? "rtlMargin" : "ltrMargin"
            } mt-3 mb-2`}
          >
            <div className="card">
              <div className="card-header">
                <i
                  className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                  /* onClick={() => navigate(-1)} */
                  onClick={handleBackNavigation}
                  title={Translate("common:backToolTip")}
                />
                <h5 className="page-title">{Translate("taskDetails:head")}</h5>
                <div className="card-options taskHeaderMenu">
                  {taskDetailData?.canAddSubtask && (
                    <div
                      className="addSubtaskFormBox viewAddSubtask"
                      onClick={() =>
                        navigate("/subtask/add", {
                          state: {
                            isTaskDetails: true,
                            data: taskDetailData,
                            parentState: state,
                          },
                        })
                      }
                    >
                      <i
                        className="fa fa-plus-square-o subtaskIcon"
                        data-toggle="tooltip"
                        title="fa fa-plus-square-o"
                      />
                      <span>{Translate("taskDetails:addSubtaskBtnLabel")}</span>
                    </div>
                  )}

                  <div className="taskMenuIcon">
                    <a
                      href="/#"
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v manageIconColor" />
                    </a>
                    <div className="dropdown-menu dropdownAlignment">
                      {(state?.module === calendarEnums.event
                        ? taskDetailOptionShare
                        : taskDetailOptions
                      )?.map((menu, index) => (
                        <Fragment key={index}>
                          <div
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              getMenuNavigate(index);
                            }}
                          >
                            <a className="dropdown-item" href="/#">
                              {Translate(`taskDetails:${menu}`)}
                            </a>
                          </div>
                          {index !==
                            (state?.module === calendarEnums.event
                              ? taskDetailOptionShare
                              : taskDetailOptions
                            )?.length -
                              1 && <div className="dropdown-divider" />}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"}`}>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                {taskDetailData && userDetails?.length && (
                  <TaskDetails
                    item={taskDetailData}
                    userDetail={userDetails}
                    taskApprovalRequest={taskApprovalRequest}
                    setSearchTextReport={setSearchTextReport}
                    setAssignTo={setAssignTo}
                    assignTo={assignTo}
                    options={assigntoOptionList}
                    onSaveChanges={onSaveChanges}
                    showApproval
                  />
                )}
                {taskDetailData?.taskStatus === taskStatusType?.REJECTED &&
                  taskDetailData?.rejectionReason?.length > 0 && (
                    <TaskInfo
                      Title={Translate("common:noteTitle")}
                      Heading={Translate("taskDetails:rejectedTaskTitle")}
                      Name={taskDetailData?.rejectionReason}
                      isImportant
                    />
                  )}
                {taskDetailData?.taskStatus === taskStatusType?.REOPENED &&
                  taskDetailData?.reopenReason?.length > 0 && (
                    <TaskInfo
                      Title={Translate("common:noteTitle")}
                      Heading={Translate("taskDetails:reopenTaskTitle")}
                      Name={taskDetailData?.reopenReason}
                      isImportant
                    />
                  )}
                {(taskDetailData?.taskStatus === taskStatusType?.REASSIGNED ||
                  taskDetailData?.taskStatus === taskStatusType?.ASSIGNED) &&
                  taskDetailData?.reallocationDisapprovedReason?.length > 0 && (
                    <TaskInfo
                      Title={Translate("common:noteTitle")}
                      Heading={Translate(
                        "taskDetails:reAllocationTaskErrorTitle"
                      )}
                      Name={taskDetailData?.reallocationDisapprovedReason}
                      isImportant
                    />
                  )}
                {taskDetailData?.EmployeeSelfAssignStartDisapproveMessage
                  ?.length > 0 && (
                  <TaskInfo
                    Title={Translate("common:noteTitle")}
                    Heading={Translate("taskDetails:reasonDisapprove")}
                    Name={
                      taskDetailData?.EmployeeSelfAssignStartDisapproveMessage
                    }
                    isImportant
                  />
                )}
                {taskDetailData && (
                  <TaskInfo
                    Title={Translate("taskDetails:company")}
                    Heading={Translate("taskDetails:companyName")}
                    Name={taskDetailData?.company?.name}
                  />
                )}
                {taskDetailData?.projectDetails && (
                  <TaskInfo
                    Title={Translate("project:projectTitle")}
                    Heading={Translate("project:projectNameHeading")}
                    Name={getProjectTitle(
                      taskDetailData?.projectDetails?._id,
                      taskDetailData?.projectDetails?.title,
                      taskDetailData?.projectDetails?.projectNumber
                    )}
                  />
                )}
                {taskDetailData && (
                  <TaskInfo
                    Title={Translate("taskDetails:task")}
                    Heading={Translate("taskDetails:taskName")}
                    Name={taskDetailData?.title}
                    subtitles={[
                      {
                        title: Translate("taskDetails:description"),
                        name: taskDetailData?.description,
                      },
                    ]}
                  />
                )}
                {taskDetailData && (
                  <TaskInfo
                    Title={Translate("taskDetails:assignee")}
                    Heading={Translate("taskDetails:assigneeName")}
                    Name={taskDetailData?.assignee?.name}
                    subtitles={[
                      {
                        title: Translate("taskDetails:assignemeail"),
                        name: taskDetailData?.assignee?.email,
                      },
                      {
                        title: Translate("taskDetails:designation"),
                        name: convertStringForHRManager(
                          taskDetailData?.assignee?.role?.type
                        ),
                      },
                    ]}
                  />
                )}
                {taskDetailData?.reassignTo?.name && (
                  <TaskInfo
                    Title={Translate("taskDetails:reAssignee")}
                    Heading={Translate("taskDetails:reAssigneeName")}
                    Name={taskDetailData?.reassignTo?.name}
                    subtitles={[
                      {
                        title: Translate("taskDetails:reassignEmail"),
                        name: taskDetailData?.reassignTo?.email,
                      },
                      {
                        title: Translate("taskDetails:reAssigneeDesignation"),
                        name: taskDetailData?.reassignTo?.role?.type,
                      },
                    ]}
                  />
                )}
                {taskDetailData && (
                  <TaskInfo
                    Title={Translate("taskDetails:reporter")}
                    Heading={Translate("taskDetails:reporterName")}
                    Name={taskDetailData?.reporter?.name}
                    subtitles={[
                      {
                        title: Translate("taskDetails:reporterEmail"),
                        name: taskDetailData?.reporter?.email,
                      },
                      {
                        title: Translate("taskDetails:designationReporter"),
                        name: convertStringForHRManager(
                          taskDetailData?.reporter?.role?.type
                        ),
                      },
                    ]}
                  />
                )}
                {taskDetailData?.relatedTaskName && (
                  <TaskInfo
                    Title={Translate("addTask:relatedTask")}
                    Heading={Translate("taskDetails:relatedTask")}
                    Name={taskDetailData?.relatedTaskName?.title}
                  />
                )}
              </div>
              <div className="col-md-8 col-sm-12" style={{ width: "100%" }}>
                <div className="card">
                  <div
                    className={`commentLabel cursor-pointer CommentCard_spacing ${
                      showAttachment && "taskHeadingLineBottom"
                    }`}
                    onClick={() => setAttachmentBox(!showAttachment)}
                  >
                    <span className="taskHeading font-17">
                      {Translate("manageTask:attacheMenttitle")}
                    </span>
                    <div>
                      <i
                        className={
                          showAttachment
                            ? "fa fa-caret-down "
                            : "fa fa-caret-up"
                        }
                      />
                    </div>
                  </div>
                  {showAttachment && (
                    <div className="CommentCard_spacing">
                      {attachmentsArray.length > 0 ? (
                        <div className="AttachmentBoxStyling">
                          <CommonImage
                            images={attachments}
                            files={attachmentsArray}
                            icon={true}
                          />
                        </div>
                      ) : (
                        <NoDataFound
                          message={Translate("manageTask:noAttachmentMsg")}
                          isCenter
                          className="NoDataStyle"
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="card">
                  <div
                    className={`commentLabel cursor-pointer CommentCard_spacing ${
                      showVoiceNote && "taskHeadingLineBottom"
                    }`}
                    onClick={() => setShowVoiceNote(!showVoiceNote)}
                  >
                    <span className="taskHeading font-17">
                      {Translate("manageTask:voiceNoteTitle")}
                    </span>
                    <div>
                      <i
                        className={
                          showVoiceNote ? "fa fa-caret-down " : "fa fa-caret-up"
                        }
                      />
                    </div>
                  </div>
                  {showVoiceNote && (
                    <div className="CommentCard_spacing">
                      {voiceNoteObj?.src || voiceNoteObj?.timeLength ? (
                        <div style={{ width: 450 }}>
                          <VoiceNotes
                            voiceObj={voiceNoteObj}
                            className={"voiceNote_spacing"}
                          />
                        </div>
                      ) : (
                        <NoDataFound
                          message={Translate("manageTask:noVoiceNote")}
                          isCenter
                          className="NoDataStyle"
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="card">
                  <div
                    className={`commentLabel cursor-pointer CommentCard_spacing ${
                      showCommentBox && "taskHeadingLineBottom"
                    }`}
                    onClick={() => setShowCommentBox(!showCommentBox)}
                  >
                    <span className="taskHeading font-17">
                      {Translate("manageTask:commentTitle")}
                    </span>
                    <div>
                      <i
                        className={
                          showCommentBox
                            ? "fa fa-caret-down "
                            : "fa fa-caret-up"
                        }
                      />
                    </div>
                  </div>
                  {showCommentBox && (
                    <div className="CommentCard_spacing">
                      <div className="commentListStyling">
                        {isCommentAdding ||
                        isCommentDeleting ||
                        commentLoading ? (
                          <Loader isListLoader />
                        ) : (
                          <AddListing
                            data={data}
                            onEdit={onEdit}
                            onDelete={showDelete}
                          />
                        )}
                        {showPicker && (
                          <Picker
                            height={350}
                            width="100%"
                            onEmojiClick={onEmojiClick}
                            skinTonesDisabled
                            previewConfig={{
                              showPreview: false,
                            }}
                          />
                        )}

                        {commentsListing?.pageInfo.hasNextPage &&
                          !isCommentAdding &&
                          !isCommentDeleting &&
                          !commentLoading && (
                            <div
                              className="loaderAlignment cursor-pointer"
                              onClick={() => {
                                setPageNo((prevState) => prevState + 1);
                              }}
                            >
                              {isCommentsFetching ||
                              isCommentsLoading ||
                              commentLoading ? (
                                <Loader
                                  message={
                                    Translate("manageTask:loadMoreTitle") +
                                    "..."
                                  }
                                  isListLoader
                                />
                              ) : (
                                <span>
                                  {Translate("manageTask:loadMoreTitle")}
                                </span>
                              )}
                            </div>
                          )}

                        <div className="commentSection">
                          <div className="commentTextField">
                            {cross && (
                              <i
                                className="fe fe-x mr-3"
                                onClick={() => {
                                  setShowPicker(false);
                                  setCross(false);
                                }}
                              ></i>
                            )}

                            <div style={{ width: "99%" }}>
                              <InputTextField
                                className="form-control InputTextAddComment"
                                placeholder={Translate(
                                  "taskDetails:addComment"
                                )}
                                value={comment}
                                onKeyPress={(e) => handlerChange(e)}
                                onChange={(e) => {
                                  setComment(
                                    e.target.value.replace(
                                      replyingTo ? `@${replyingTo}, ` : "",
                                      ""
                                    )
                                  );
                                }}
                                maxLength={validations?.commentLimit.MAX}
                              />
                            </div>
                          </div>
                          <div className="commentSectionIcon ">
                            <div className="commentIconStyling">
                              <div className="display">
                                <Dropzone
                                  icon={
                                    "fe fe-paperclip cursor-pointer fontSize-18"
                                  }
                                  onfileSelect={addFiles}
                                  acceptedImages={acceptedFileType}
                                  maximumFiles={1}
                                  fileLength={filesAdded.length}
                                  maxFileSize={52428800}
                                  style={{
                                    background: "#fff",
                                    color: "black",
                                    padding: 0,
                                    marginBottom: 0.6,
                                  }}
                                />
                              </div>
                            </div>
                            <div className="commentIconStyling">
                              <i
                                className="icon-emoticon-smile cursor-pointer"
                                style={{ fontSize: "18px" }}
                                onClick={() => {
                                  setShowPicker((val) => !val);
                                  setCross((val) => !val);
                                }}
                              ></i>
                            </div>
                            <div
                              className={`commentSendIconStyling ${
                                isRtl && "ml-2"
                              }`}
                            >
                              <i
                                className="fe fe-send cursor-pointer"
                                style={{ fontSize: "18px" }}
                                onClick={() => clickHandler()}
                              ></i>
                            </div>
                          </div>
                        </div>
                        {filesAdded && filesAdded.length > 0 && (
                          <div className="attachmentBackStyling">
                            <div className="imageViewerStyling">
                              <CommonImage
                                images={images}
                                files={filesAdded}
                                removeFile={removeFile}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="card">
                  <div
                    className={`commentLabel cursor-pointer CommentCard_spacing ${
                      showTaskHistory && "taskHeadingLineBottom"
                    }`}
                    onClick={() => setShowTaskHistory(!showTaskHistory)}
                  >
                    <span className="taskHeading font-17">
                      {Translate("manageTask:historyTitle")}
                    </span>
                    <div>
                      <i
                        className={
                          showTaskHistory
                            ? "fa fa-caret-down "
                            : "fa fa-caret-up"
                        }
                      />
                    </div>
                  </div>
                  {showTaskHistory && (
                    <div className="CommentCard_spacing">
                      <div className="commentListStyling">
                        {historyData?.length > 0 && (
                          <TaskHistory data={historyData} />
                        )}

                        {taskHistoryData?.data?.pageInfo.hasNextPage && (
                          <div
                            className="loaderAlignment"
                            onClick={() => {
                              setHistoryPageNo((prevState) => prevState + 1);
                            }}
                          >
                            {taskHistoryLoading ? (
                              <Loader
                                message={
                                  Translate("manageTask:loadMoreTitle") + "..."
                                }
                                isListLoader
                              />
                            ) : (
                              <span>
                                {Translate("manageTask:loadMoreTitle")}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <AlertPermission
                    show={showDeleteAlert}
                    subTitle={Translate("addTask:deleteAlertTitle")}
                    okTitle={Translate("common:delete")}
                    onOk={onDelete}
                    onCanel={cancelDelete}
                  />
                  <ShareTaskModal
                    show={showShareModule}
                    onCancel={onCancelShareModal}
                    data={taskLink}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {
        <Loader
          loading={
            isAcceptTaskLoading ||
            isRejectTaskLoading ||
            isStartTaskLoading ||
            isStartSelfAssignTaskLoading ||
            isResolveTaskLoading ||
            isApprovedTaskLoading ||
            isDisapprovedTaskLoading ||
            isCompletedTaskkLoading ||
            isReAllocateTaskLoading ||
            isDisApprovedReAllocReqLoading ||
            isShareLoading ||
            isApprovedTaskByManagerLoading ||
            isDisapprovedTaskByManagerLoading
          }
        />
      }
    </>
  );
};

export default AddCommentListing;
