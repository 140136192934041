import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useViewPODataMutation } from "../../../../request/Staff/ProcurementOfficer";
import { InitialValues } from "../Components/constants";
import ProcurementForm from "../Components/ProcurementForm";
import { AddProcurementOfficerSchema } from "../Components/validation";

const ViewProcurementOfficer = () => {
    const { state } = useLocation();
    const formikRef = useRef(null);
    const [filesAdded, setFilesAdded] = useState([]);
    const [images, setImages] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState('PO');

    const [
        getProcurementOfficerApi,
        {
            data: procurementOfficerData,
            isLoading: isViewPOLoading,

        },
    ] = useViewPODataMutation();

    useEffect(() => {
        if (state) { getProcurementOfficerApi(state); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const onSubmit = (formValues) => {

    };

    return (
        <Formik
            initialValues={InitialValues}
            innerRef={formikRef}
            validateOnMount
            onSubmit={onSubmit}
            validationSchema={AddProcurementOfficerSchema}>
            {({ values, setFieldValue, handleSubmit }) => {
                return (
                    <ProcurementForm
                        formikRef={formikRef}
                        isView
                        isLoading={isViewPOLoading}
                        values={values}
                        setFieldValue={setFieldValue}
                        poData={procurementOfficerData?.data?.length && procurementOfficerData?.data[0]}
                        handleSubmit={handleSubmit}
                        filesAdded={filesAdded}
                        setFilesAdded={setFilesAdded}
                        images={images}
                        setImages={setImages}
                        selectedStaff={selectedStaff}
                        setSelectedStaff={setSelectedStaff}
                    />
                );
            }}
        </Formik>
    );
};

export default ViewProcurementOfficer;
