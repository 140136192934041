import { colors } from "../../../common/Theme/Colors";
import { taskStatusType } from "../../AddTask/constants";

export const getProgresbarColor = (taskStatus, isView = false) => {
  switch (taskStatus) {
    case taskStatusType.ASSIGNED:
      return isView ? colors.black : colors.grey_013;
    case taskStatusType.ACCEPTED:
      return colors.blue_004;
    case taskStatusType.REJECTED:
      return colors.red_001;
    case taskStatusType.INPROGRESS:
      return colors.blue_002;
    case taskStatusType.AWAITING_APPROVAL:
      return colors.primary_002;
    case taskStatusType.REOPENED:
      return colors.primary;
    case taskStatusType.RESOLVED:
      return colors.primary_002;
    case taskStatusType.COMPLETED:
      return colors.green;
    case taskStatusType.OVERDUE:
      return colors.orange;
    case taskStatusType.AWAITING_FOR_APPROVAL:
      return colors.primary_002;
    default:
      return colors.black;
  }
};

export const getStatusLabel = (taskStatus) => {
  switch (taskStatus) {
    case taskStatusType.INPROGRESS:
      return taskStatusType.INPROGRESS_LABEL;
    case taskStatusType.AWAITING_APPROVAL:
      return taskStatusType.AWAITING_APPROVAL_LABEL;
    case taskStatusType.AWAITING_FOR_APPROVAL:
      return taskStatusType.AWAITING_APPROVAL_LABEL;
    default:
      return taskStatus;
  }
};

export const getProjectTitle = (projectId, projectTitle, projectNumber) => {
  if (!projectId || !projectTitle) return "Independent task";

  if (!projectNumber) return projectTitle;

  return `#${projectNumber} ${projectTitle}`;
};

export const extractProjectTitle = (projectId, projectTitle, projectNumber) => {
  return `#${projectNumber} ${projectTitle}`;
};
