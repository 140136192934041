export default {
  addHrOfficerTitle: "Add HR Officer",
  editHrOfficerTitle: "Edit HR officer",
  viewHrOfficerTitle: "View HR officer",
  hrOfficerName: "HR officer name",
  staff: "Staff",
  staffplaceholder: "Select staff",
  staffError: "Staff is required",
  nameError: "HR officer name is required",
};
