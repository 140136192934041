import { LinearProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyHeader from "../../common/CompanyHeader";
import { DateTimeFormats } from "../../common/Utils/DateFormater";
import { showToast } from "../../common/Utils/ToastMessage";
import { getFilterValue, getMoment } from "../../common/Utils/commonFunction";
import { moduleTypes } from "../../common/users/userTypes";
import {
  useGetDayCalendarMutation,
  useGetMarkedEventListMutation,
} from "../../request/Calendar";
import Translate from "../../translation/utils/translateFunction";
import CompanyListing from "../ContactRepository/component";
import { Persona } from "../Persona";
import ShareWithUser from "../ShareWithUser";
import { shareModuleType } from "../ShareWithUser/components/constants";
import FullCalendarScreen from "../common/fullcalendar";
import Loader from "../staff/Owner/LoadingComp";
import EventList from "./components/EventList";
import ShowMorePopup from "./components/ShowMoreModal";
import {
  CalendarEnums,
  calendarEnums,
  calendarViewEnums,
  calendarViewTypEnums,
} from "./utils";

const Calendar = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const YMDFormat = "YYYY-MM-DD";
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state?.formanagement
  );
  const [initialDate, setInitialDate] = useState(
    getMoment().clone().format(YMDFormat)
  );

  const [eventsData, setEventsData] = useState([]);
  const [showMoreEventsData, setShowMoreEventsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEventsPopup, setShowEventsPopup] = useState(false);
  const [moreEvents, setMoreEvents] = useState([]);

  const [initialDateForCalendar, setInitialDateForCalendar] = useState(
    getMoment().clone().format(YMDFormat)
  );
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [markedEvents, setMarkedEvents] = useState([]);
  const [isCalendarEventLoading, setIsCalendarEventLoading] = useState(false);
  const [calendarDate, setCalendarDate] = useState(getMoment().toDate());
  const [roleOptions, setRoleOptions] = useState([]);
  const [calendarViewType, setCalendarViewType] = useState(
    calendarViewTypEnums.Month
  );
  const [calendarOptions, setCalendarOptions] = useState([]);
  const ref = useRef(null);

  const [
    trigger,
    {
      data: myCalendarData,
      isLoading: isCalendarLoading,
      isSuccess: isCalendarSuccess,
    },
  ] = useGetDayCalendarMutation();

  const getEventsBodyObj = useMemo(() => {
    return {
      companyId: selectedCompanyList?.length
        ? selectedCompanyList?.map((item) => item?.value)
        : [],
      selectedDate: `${moment(initialDate, YMDFormat).format(
        DateTimeFormats.calendar_format
      )}Z`,
      userId: selectedMember ? selectedMember?._id : "",
    };
  }, [selectedCompanyList, initialDate, selectedMember]);

  useEffect(() => {
    state?.user && setSelectedMember(state?.user);
  }, [state]);
  useEffect(() => {
    trigger(getEventsBodyObj);
    setEventsData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEventsBodyObj]);

  useEffect(() => {
    if (isCalendarSuccess) {
      setEventsData(myCalendarData?.data.displayArray);
      setShowMoreEventsData(myCalendarData?.data.showMoreArray);
    }
  }, [isCalendarSuccess, myCalendarData]);
  useEffect(() => {
    if (userData) {
      const data = userData?.moduleAccess?.find(
        (item) => Object.keys(item)[0] === moduleTypes.calendar
      );
      setCalendarOptions(Object.values(data)[0]);
    }
  }, [userData]);
  const onShowMorePress = (value) => {
    setShowEventsPopup(!showEventsPopup);
    setMoreEvents(value);
  };

  const [
    getMarkedEventsApi,
    {
      data: markedEventData,
      isLoading: isMarkedEventLoading,
      isSuccess: isMarkedEventSuccess,
      isError: isMarkedEventError,
      error: markedEventError,
    },
  ] = useGetMarkedEventListMutation();

  const reqMarkedEventBody = useMemo(() => {
    return {
      companyId: selectedCompanyList?.length
        ? selectedCompanyList?.map((item) => item?.value)
        : [],
      selectedDate: `${moment(initialDateForCalendar, YMDFormat).format(
        DateTimeFormats.calendar_format
      )}Z`,
      viewType: calendarViewType,
      userId: selectedMember ? selectedMember?._id : "",
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedMember,
    selectedCompanyList,
    initialDateForCalendar,
    calendarViewType,
  ]);

  useEffect(() => {
    setIsCalendarEventLoading(true);
    getMarkedEventsApi(reqMarkedEventBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqMarkedEventBody]);

  useEffect(() => {
    if (markedEventData?.data && isMarkedEventSuccess) {
      const { data } = markedEventData;

      const updatedEvents = data?.flatMap(({ dots }) =>
        dots.map((event) => ({
          title: event?.subject || "",
          start: event?.startDateTime
            ? new Date(
              event?.type === calendarEnums.task ||
                event?.type === calendarEnums.subTask
                ? event.endDateTime
                : event?.startDateTime
            ).toISOString()
            : "",
          end: event?.endDateTime
            ? new Date(event.endDateTime).toISOString()
            : "",
          ...event,
        }))
      );
      const uniqueEvents = [];
      const uniqueIds = [];

      for (const event of updatedEvents) {
        if (!uniqueIds?.includes(event?.nodeId)) {
          uniqueIds.push(event?.nodeId);
          uniqueEvents.push(event);
        }
      }
      setMarkedEvents(uniqueEvents);
      setIsCalendarEventLoading(false);
    }

    if (isMarkedEventError && markedEventError) {
      showToast({
        message:
          markedEventError?.data?.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
      setIsCalendarEventLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    markedEventData,
    isMarkedEventSuccess,
    isMarkedEventError,
    markedEventError,
  ]);

  const getRoleOptions = useMemo(() => {
    if (userData) {
      let filter = [];
      const filterData = userData?.moduleAccess?.find(
        (item) => Object.keys(item)[0] === moduleTypes.viewCalendar
      );
      Object?.values(filterData)[0]?.map((item) => {
        item?.isEnable &&
          filter.push({
            label: Translate(`taskReport:${item?.label}`),
            value: getFilterValue(item.label),
          });
      });
      return filter;
    }
    return [];
  }, [userData]);

  useEffect(() => {
    if (getRoleOptions?.length) {
      setRoleOptions(getRoleOptions);
    }
  }, [getRoleOptions]);

  const handleSelectMember = (members) => {
    navigate("view", { state: { user: members[0] } });
    setSelectedMember(members[0]);
    setInitialDateForCalendar(getMoment().clone().format(YMDFormat));
    setCalendarDate(getMoment().toDate());
  };

  const onCloseShareModal = () => setShowShareModal(false);

  const handleDateClick = (info) => {
    setInitialDate(info?.dateStr);
  };

  const handleCalendarView = (date) => {
    setInitialDateForCalendar(date);
  };
  const getActions = (label, action) => {
    switch (label) {
      case CalendarEnums.ViewCalendar:
        setShowShareModal(true);
        break;
      case CalendarEnums.CreateEvent:
        navigate(action);
        break;
      case CalendarEnums.Reminder:
        navigate(action, {
          state: { fromCalendar: true },
        })
        break;
    }
  };
  const CalendarHeader = () => {
    return (
      <div className="card-options">
        {/* <a
          href="/#"
          className="card-options-fullscreen"
          data-toggle="card-fullscreen"
        >
          <i className="fe fe-maximize" />
        </a> */}
        <>
          <a
            href="/#"
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false">
            <i className="fa fa-ellipsis-v manageIconColor" />
          </a>
          <div className="dropdown-menu dropdownAlignment">
            {calendarOptions?.map((item) => {
              return (
                item?.isEnable && (<div
                  className="manageIconColor cursor-pointer dropdown-item"
                  onClick={() => getActions(item?.label, item?.navigation)}>
                  {Translate(`calendarPage:${item?.label}`)}
                </div>))
            })}
          </div>
        </>
      </div>
    );
  };
  const navigateTo = (event) => {
    if (event?.type === calendarEnums.freeSlot) {
      if (event?.endTime >= getMoment().format(DateTimeFormats.Time)) {
        navigate("/calendar/createEvent", { state: { eventSlot: event } });
      } else {
        showToast({
          message: Translate("createEvent:timeSlotMsg"),
          type: "info",
        });
      }
    } else if (event?.type === calendarEnums.subTask) {
      navigate("/subtask/view", {
        state: {
          taskId: event?._id,
          module: calendarEnums.event,
        },
      });
    } else if (event?.type === calendarEnums.task) {
      navigate("/taskDetails", {
        state: {
          taskId: event?._id,
          module: calendarEnums.event,
          navigateFrom: "/calendar",
        },
      });
    } else if (event?.type === calendarEnums.event) {
      navigate("/calendar/viewEvent", {
        state: { eventId: event?._id },
      });
    }
  };
  const onDateViewClick = (calendarView) => {
    switch (calendarView?.view?.type) {
      case calendarViewEnums.Month:
        setCalendarViewType(calendarViewTypEnums.Month);
        break;
      case calendarViewEnums.Day:
        setCalendarViewType(calendarViewTypEnums.Day);
        break;
      case calendarViewEnums.Week:
        setCalendarViewType(calendarViewTypEnums.Week);
        break;
      default:
        setCalendarViewType(calendarViewTypEnums.Month);
        break;
    }
  };
  return (
    <>
      <div ref={ref}>
        <div
          className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${fixNavbar ? "marginTop" : ""
            } mt-3`}>
          <div className="container-fluid">
            <div className="row clearfix row-deck">
              <div className="col-lg-4 col-md-12">
                <div className="card">
                  <div className="card-header bline">
                    <CompanyHeader
                      setShowModal={setShowModal}
                      className={"card-title mb-2"}
                    />
                  </div>
                  <div className="card-body eventListscroll">
                    {isCalendarLoading ? (
                      <LinearProgress style={{ height: 2 }} />
                    ) : (
                      <EventList
                        dayEventData={eventsData}
                        showMoreData={showMoreEventsData}
                        screenWidth={ref?.current?.offsetWidth}
                        eventCount={myCalendarData?.data?.eventsCount}
                        setShowEventsPopup={onShowMorePress}
                        onCardClick={(event) => {
                          selectedMember
                            ? !event?.isPrivate && navigateTo(event)
                            : navigateTo(event);
                        }}
                      />
                    )}
                    {showEventsPopup && (
                      <ShowMorePopup
                        eventData={moreEvents}
                        isVisible={showEventsPopup}
                        setEventsPopup={setShowEventsPopup}
                        navigate={navigate}
                        onCancel={() => setShowEventsPopup(false)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="card">
                  <div className="card-header bline">
                    <div className="calendarHeaderBox">
                      <Persona
                        name={selectedMember?.name || userData?.name}
                        image={selectedMember?.profile || userData?.profileUrl}
                      />
                      <div className="calendarTitleBox">
                        <h3 className="card-title calendarUserName">
                          {selectedMember?.name || userData?.name}
                        </h3>
                        <h3 className="card-subtitle calendarUserRole">
                          {selectedMember?.role || userData?.role?.type}
                        </h3>
                      </div>
                    </div>

                    {CalendarHeader()}
                  </div>
                  <div className="card-body">
                    <FullCalendarScreen
                      events={markedEvents}
                      handleCalendarView={handleCalendarView}
                      handleDateClick={handleDateClick}
                      calendarDate={calendarDate}
                      onDateSetClick={onDateViewClick}
                      onEventClick={(e) => {
                        const event = e.event?._def?.extendedProps;
                        selectedMember
                          ? !event?.isPrivate && navigateTo(event)
                          : navigateTo(event);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CompanyListing
        handleModal={setShowModal}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
      {showShareModal && (
        <ShareWithUser
          currentModule={shareModuleType?.CALENDAR}
          showModal={showShareModal}
          closeModal={onCloseShareModal}
          onSubmit={handleSelectMember}
          roleOptions={roleOptions}
          isSingleSelect
          showRoleFilter
        />
      )}
      <Loader
        loading={
          isMarkedEventLoading || isCalendarLoading || isCalendarEventLoading
        }
      />
    </>
  );
};
export default Calendar;
