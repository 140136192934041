import React from "react";
import { colors } from "../../../../common/Theme/Colors";

const TaskInfo = ({ Title, Heading, Name, isImportant, subtitles = [] }) => {
  return (
    <div>
      <div>
        <div className="card">
          {Title && (
            <div className="card-header taskHeadingLineBottom">
              <h3
                className="TaskInfoTitle taskHeading font-17"
                style={{ color: isImportant && colors?.red }}
              >
                {Title}
              </h3>
            </div>
          )}

          <div className="TaskInfo">
            <ul className="list-group">
              <small className="text-muted">{Heading}</small>
              <p className="mb-0 taskCardContentStyling">{Name}</p>
            </ul>
            {subtitles?.map(
              (item, idx) =>
                item?.name && (
                  <ul className="list-group" key={idx}>
                    <div className="divider"></div>
                    <small className="text-muted">{item?.title}</small>
                    <p className="mb-0 taskCardContentStyling">{item?.name}</p>
                  </ul>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskInfo;
