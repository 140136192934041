import { combineReducers, configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistReducer } from "redux-persist";
import { CreateApiV105 } from "../request/CreateApiv105";
import storage from "redux-persist/lib/storage";
import { CreateApi } from "../request/CreateApi";
import formanagement from "../store/Reducer";
const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["CreateApi", "CreateApiV105"],
};
export const rootReducers = combineReducers({
  formanagement,
  // Add the generated reducer as a specific top-level slice
  [CreateApi.reducerPath]: CreateApi.reducer,
  [CreateApiV105.reducerPath]: CreateApiV105.reducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducers);
export const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(CreateApi.middleware)
      .concat(CreateApiV105.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
