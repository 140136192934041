import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useApproveDisapproveleaveMutation,
  useDisapproveleaveMutation,
  useLazyGetMyLeaveDetailQuery,
} from "../../../request/LeaveRequest";
import Translate from "../../../translation/utils/translateFunction";
import CommonImage from "../../ImageGallery/CommonImage";
import NoDataFound from "../../ManageTask/NoDataFound";
import Loader from "../../staff/Owner/LoadingComp";
import { LeaveDetailCard } from "./Components/LeaveDetailCard";
import { StatusCheck } from "./Components/StatusCheck";
import { leaveStatusEnum } from "../../Profile/LeaveRequest/constants";
import AlertPermission from "../../AlertPermission";
import {
  DisapprovedReasonSchema,
  InitialValuesForRejected,
} from "../LeaveRequest/validation";
import ModalFormikForm from "../../ModalForm/Formik";
import { showToast } from "../../../common/Utils/ToastMessage";
const LeaveRequestDetail = () => {
  const { validations, isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showAttachment, setShowAttachment] = useState(true);
  const [attachmentsArray, setAttachmentsArray] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [leaveData, setLeaveData] = useState();
  const [showDisapproveReasonbox, setShowDisapproveReasonbox] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isApprove, setIsApprove] = useState(true);
  const [
    getLeaveDetail,
    {
      data: serverResponse,
      isSuccess: isDeatilSuccess,
      isLoading: isDetailLoading,
    },
  ] = useLazyGetMyLeaveDetailQuery();
  useEffect(() => {
    if (isDeatilSuccess && serverResponse?.data) {
      const { attachment } = serverResponse?.data;
      setLeaveData(serverResponse?.data);
      const attachmentArray = [];
      attachment.map((attachmentItem, idx) => {
        const obj = {
          name: attachmentItem?.fileName,
          path: attachmentItem?.fileName,
          ...attachmentItem,
        };
        attachmentArray.push(obj);
      });
      setAttachmentsArray(attachmentArray);
      setAttachments(attachment?.map((item) => item?.url));
    }
  }, [isDeatilSuccess]);
  useEffect(() => {
    getLeaveDetail(state?.leaveId);
  }, []);
  const [
    approveDisapprove,
    {
      data: serverResponseApproveDisapprove,
      isSuccess: isApproveDisapproveSuccess,
      isLoading: isApproveDisapproveLoading,
    },
  ] = useApproveDisapproveleaveMutation();
  const [
    disapprove,
    {
      data: serverResponseDisapprove,
      isSuccess: isDisapproveSuccess,
      isLoading: isDisapproveLoading,
      isError: isErrorDisapproved,
      error: errorDisapproved,
    },
  ] = useDisapproveleaveMutation();
  useEffect(() => {
    if (isApproveDisapproveSuccess && serverResponseApproveDisapprove) {
      showToast({
        message: serverResponseApproveDisapprove?.message,
        type: "success",
      });
      navigate(-1);
    }
  }, [isApproveDisapproveSuccess]);
  useEffect(() => {
    if (isDisapproveSuccess && serverResponseDisapprove) {
      showToast({
        message: serverResponseDisapprove?.message,
        type: "success",
      });
      navigate(-1);
    }
    if (isErrorDisapproved && errorDisapproved) {
      console.log("errorDisapproved", errorDisapproved?.data?.error);
      showToast({
        message: errorDisapproved?.data?.error?.[0]?.msg,
        type: "error",
      });
    }
  }, [isDisapproveSuccess, isErrorDisapproved, errorDisapproved]);

  const onAcceptAlert = () => {
    isApprove ? handleApproveDisapprove() : setShowDisapproveReasonbox(true);
    setShowAlert(false);
  };
  const onCancelAlert = () => {
    setShowAlert(false);
  };
  const onCancelDisapproveReasonAlert = () => {
    setShowDisapproveReasonbox(false);
  };
  const onDisapproveReasonAlert = (value) => {
    handleApproveDisapprove(false, value.disapproveReason);
    setShowDisapproveReasonbox(false);
  };
  const handleApproveDisapprove = (isApprove = true, reason = "") => {
    const requestObj = {
      leaveId: [state?.leaveId],
      leaveStatus: isApprove
        ? leaveStatusEnum.approved
        : leaveStatusEnum.disapproved,
      ...(isApprove ? {} : { reason }),
    };
    isApprove ? approveDisapprove(requestObj) : disapprove(requestObj);
  };

  return (
    <>
      <div
        className={`section-body ${
          isRtl ? "rtlMargin" : "ltrMargin"
        } mt-3 mb-2`}
      >
        <div className="card">
          <div className="card-header approveLeaveContainer">
            <div className="approveLeaveHeader">
              <i
                className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                onClick={() => navigate(-1)}
                title={Translate("common:backToolTip")}
              />
              <h5 className="page-title">{Translate("drawer:leaveReq")}</h5>
            </div>
            {state?.isFromManageRequest && (
              <div className="approveLeaveButtons">
                <button
                  type="button"
                  className="btn taskAcceptBtn saveButton"
                  onClick={() => {
                    setIsApprove(true);
                    setShowAlert(true);
                  }}
                >
                  {Translate("taskDetails:approveButtonLabel")}
                </button>
                <button
                  type="button"
                  className="btn taskRejectBtn saveButton"
                  onClick={() => {
                    setIsApprove(false);
                    setShowAlert(true);
                  }}
                >
                  {Translate("taskDetails:disapproveButtonLabel")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"}`}>
        {isDetailLoading ||
        isApproveDisapproveLoading ||
        isDisapproveLoading ? (
          <Loader
            loading={
              isDetailLoading ||
              isApproveDisapproveLoading ||
              isDisapproveLoading
            }
          />
        ) : (
          <>
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <LeaveDetailCard
                  leaveData={leaveData}
                  validations={validations}
                  isFromManageRequest={state?.isFromManageRequest}
                />
              </div>

              <div className="col-md-7 col-sm-12" style={{ width: "100%" }}>
                <div className="card">
                  <div
                    className={`commentLabel cursor-pointer CommentCard_spacing ${
                      showAttachment && "taskHeadingLineBottom"
                    }`}
                    onClick={() => setShowAttachment(!showAttachment)}
                  >
                    <span className="leaveRequestAttachment font-17">
                      {Translate("manageTask:attacheMenttitle")}
                    </span>
                    <div>
                      <i
                        className={
                          showAttachment
                            ? "fa fa-caret-down "
                            : "fa fa-caret-up"
                        }
                      />
                    </div>
                  </div>
                  {showAttachment && (
                    <div className="CommentCard_spacing">
                      {attachmentsArray.length > 0 ? (
                        <div className="AttachmentBoxStyling">
                          <CommonImage
                            images={attachments}
                            files={attachmentsArray}
                            icon={true}
                          />
                        </div>
                      ) : (
                        <NoDataFound
                          message={Translate("manageTask:noAttachmentMsg")}
                          isCenter
                          className="NoDataStyle"
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <StatusCheck historyData={leaveData?.history} />
              </div>
            </div>
          </>
        )}
      </div>
      <AlertPermission
        show={showAlert}
        subTitle={
          isApprove
            ? Translate("leaveRequest:approveTaskAlert")
            : Translate("leaveRequest:disapproveTaskAlert")
        }
        okTitle={Translate("common:ok")}
        onOk={onAcceptAlert}
        onCanel={onCancelAlert}
      />
      {(isApproveDisapproveLoading || isDisapproveLoading) && (
        <Loader loading={isApproveDisapproveLoading} />
      )}
      <ModalFormikForm
        modalTitle={Translate("leaveRequest:addReason")}
        show={showDisapproveReasonbox}
        title={Translate("leaveRequest:reasonTitle")}
        fieldName={"disapproveReason"}
        onOk={onDisapproveReasonAlert}
        onCancel={onCancelDisapproveReasonAlert}
        btnLabel={Translate("taskDetails:saveButtonLabel")}
        placeholder={Translate("leaveRequest:reasonplaceholder")}
        schema={DisapprovedReasonSchema}
        InitialValues={InitialValuesForRejected}
      />
    </>
  );
};
export default LeaveRequestDetail;
