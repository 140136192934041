import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useAddContactMutation } from "../../../../request/ContactRepo";
import ContactForm from "../../component/ContactForm";
import { InitialValues, PrivateListType } from "../../component/constant";
import { AddContactSchema } from "../../component/utils";
import { useSelector } from "react-redux";

const AddNewContact = () => {
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const { state } = useLocation();
  const formikRef = useRef(null);
  const navigate = useNavigate();

  const [
    addContactApi,
    {
      data: addContact,
      isSuccess: isSuccessContact,
      isLoading: isLoadingContact,
      isError: isErrorContact,
      error: errorContact,
    },
  ] = useAddContactMutation();

  useEffect(() => {
    if (isErrorContact && errorContact) {
      errorContact?.data?.error && errorContact.data.error.length > 0
        ? errorContact.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({ message: errorContact?.data?.message, type: "error" });
    }

    if (isSuccessContact && addContact) {
      showToast({ message: addContact.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      if (addContact.data.contactType === PrivateListType) {
        navigate("/contactRepository/privateListing");
      } else {
        navigate("/contactRepository/publicListing");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addContact, errorContact]);

  useEffect(() => {
    if (filesAdded?.length > 0) {
      const newImages = filesAdded.map((imageElement) => imageElement.preview);
      setImages(newImages);
    }
  }, [filesAdded]);

  const onSubmit = (values) => {
    let requestObject = {
      companyName: values?.companyName,
      contactName: values?.contactName,

      contactDesignation: values?.contactDesignation,
      contactIndustry: values?.contactIndustry,
      contactDepartment: values?.contactDepartment,
      contactField: values?.contactField,
      contactSector: values?.contactSector,
      workEmail: values?.workEmail,
      contactPhoneCountryCode: values?.contactPhoneCountryCode
        ? values?.contactPhoneCountryCode
        : "962",

      contactPhone: values?.contactPhone?.split("-")[1],

      alternateContact: values?.alternateContact
        ? values?.alternateContact?.split("-")[1]
        : "",

      contactAddress: values?.contactAddress,
      contactType: values?.contactType ? values?.contactType : state.listType,

      contactPhoneShortCode: values?.contactPhoneShortCode
        ? values?.contactPhoneShortCode
        : "JO",
    };

    let b64Object = {};

    if (filesAdded?.length > 0) {
      b64Object = {
        ...filesAdded.map((ele) => ({
          url: ele?.b64,
          type: ele?.type,
          contactFileName: ele?.name,
          contactFileExt: ele?.name.split(".").pop(),
        }))[0],
      };
      requestObject = {
        ...requestObject,
        webAttachment: b64Object,
      };
    }
    if (values?.alternateContact?.length > 0) {
      requestObject = {
        ...requestObject,
        alternateContactCountryCode: values?.alternateContactCountryCode,
        alternateContactShortCode: values?.alternateContactShortCode,
      };
    }

    addContactApi(requestObject);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddContactSchema}
    >
      {({ values, handleSubmit }) => {
        return (
          <ContactForm
            values={values}
            handleSubmit={handleSubmit}
            filesAdded={filesAdded}
            images={images}
            setfilesAdded={setfilesAdded}
            setImages={setImages}
            isLoading={isLoadingContact}
            listType={state?.listType}
          />
        );
      }}
    </Formik>
  );
};
export default AddNewContact;
