import dayjs from "dayjs";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DateTimeFormats,
  formatDateYYYYMMDD,
} from "../../../../common/Utils/DateFormater";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  getMoment,
  getNameAndExtFromFile,
} from "../../../../common/Utils/commonFunction";
import {
  useAssignTaskFromRejectedMutation,
  useEditSubtaskMutation,
  useReAllocateTaskToAssigneeMutation,
} from "../../../../request/Task";
import Translate from "../../../../translation/utils/translateFunction";
import { moduleType } from "../../Components/constants";
import SubtaskForm from "../Components/SubtaskForm";
import { InitialValues } from "../Components/constants";
import { AddSubtaskSchema } from "../Components/validations";

const EditSubtask = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { state } = useLocation();

  const [selectedCompany, setSelectedCompany] = useState("");
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [startDateTZ, setStartDateTZ] = useState("");
  const [dueDateTZ, setDueDateTZ] = useState("");

  const [
    editSubtaskApi,
    {
      data: editSubtaskData,
      isLoading: isSubtaskLoading,
      isSuccess: editSubtaskSuccess,
      isError: isSubtaskError,
      error: editSubtaskError,
    },
  ] = useEditSubtaskMutation();

  const [
    reAllocateTaskToAssignee,
    {
      data: reAllocatedTaskData,
      isLoading: isReAllocateLoading,
      isSuccess: isReAllocateSuccess,
      isError: isReAllocateError,
      error: reAllocateError,
    },
  ] = useReAllocateTaskToAssigneeMutation();

  const [
    reAssignTask,
    {
      data: reAssignedTaskData,
      isLoading: isReAssignTaskLoading,
      isSuccess: isReAssignTaskSuccess,
      isError: isReAssignTaskError,
      error: reAssignTaskError,
    },
  ] = useAssignTaskFromRejectedMutation();

  useEffect(() => {
    if (state?.isEdit) {
      const {
        company,
        startDate,
        startTime,
        dueDate,
        dueTime,
        priority,
        isCritical,
        title,
        type,
        description,
        attachment,
        taskId,
        dueDateObject,
        startDateObject,
        parentTaskDetails,
        projectId,
        projectTitle,
        projectNumber,
        projectDetails,
      } = state?.data;

      const startTimeArr = getMoment().toDate().toString().split(" ");
      startTimeArr.splice(4, 1, startTime);
      const dueTimeArr = getMoment().toDate().toString().split(" ");
      dueTimeArr.splice(4, 1, dueTime);
      const attachmentArray = [];

      attachment.map((attachmentItem) => {
        const obj = {
          name: attachmentItem?.taskFileName,
          path: attachmentItem?.taskFileName,
          ...attachmentItem,
        };

        attachmentArray.push(obj);
      });

      setFilesAdded(attachmentArray);
      setImages(attachment?.map((item) => item?.url));
      formikRef.current.setFieldValue("startDate", new Date(startDate));
      formikRef.current.setFieldValue(
        "startTime",
        dayjs(startTimeArr.join(" ").trim())
      );
      setStartDateTZ(startDateObject);
      setDueDateTZ(dueDateObject);
      formikRef.current.setFieldValue("dueDate", new Date(dueDate));
      formikRef.current.setFieldValue(
        "dueTime",
        dayjs(dueTimeArr.join(" ").trim())
      );
      formikRef.current.setFieldValue("company", company?._id);
      formikRef.current.setFieldValue("markAsCritical", isCritical);
      formikRef.current.setFieldValue("priority", priority);
      formikRef.current.setFieldValue("title", title);
      formikRef.current.setFieldValue("projectId", projectId);
      formikRef.current.setFieldValue(
        "projectTitle",
        projectDetails?.projectNumber
          ? `#${projectDetails?.projectNumber} ${projectDetails?.title}`
          : "Independent task"
      );
      formikRef.current.setFieldValue("description", description);
      formikRef.current.setFieldValue("relatedTaskName", taskId);
      formikRef.current.setFieldValue(
        "parentTitle",
        parentTaskDetails?.parentTaskTitle
      );

      setSelectedCompany(company?._id);
    }
  }, [state]);

  const resetFormikForm = () => {
    formikRef.current.resetForm();
    formikRef.current.setFieldValue("title", "");
    setFilesAdded([]);
    setImages([]);
    resetOnCompanyChange();
    formikRef.current.setFieldValue("markAsCritical", false);
    return navigate(handleEditNavigation());
  };

  const handleEditNavigation = () => {
    if (state?.parentState?.navigateFrom) {
      return state?.parentState?.navigateFrom;
    } else {
      return "/manageTask";
    }
  };

  /********************************************************************** Add-SubTask UseEffect ****************************************************/
  useEffect(() => {
    if (isSubtaskError && editSubtaskError) {
      const taskError = editSubtaskError;

      taskError.data.error &&
        taskError.data.error.length > 0 &&
        taskError.data.error.map((errorItem) =>
          errorItem?.param === "startDateObject" ||
          errorItem?.param === "dueDateObject"
            ? showToast({ message: errorItem?.msg, type: "error" })
            : formikRef.current.setFieldError(errorItem.param, errorItem.msg)
        );
    }

    if (editSubtaskSuccess && editSubtaskData) {
      showToast({
        type: "success",
        message: editSubtaskData.message,
      });

      resetFormikForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSubtaskError, editSubtaskData]);

  /**********************************************************************ReAllocate Task UseEffect ****************************************************/
  useEffect(() => {
    if (reAllocateError && isReAllocateError) {
      showToast({
        type: "error",
        message:
          JSON.stringify(reAllocateError?.data?.message) ||
          Translate("common:unknownServerError"),
      });
    }

    if (reAllocatedTaskData && isReAllocateSuccess) {
      showToast({
        type: "success",
        message: reAllocatedTaskData?.message,
      });

      resetFormikForm();
    }
  }, [
    reAllocatedTaskData,
    reAllocateError,
    isReAllocateSuccess,
    isReAllocateError,
  ]);

  /**********************************************************************ReAssign Task UseEffect ****************************************************/
  useEffect(() => {
    if (reAssignTaskError && isReAssignTaskError) {
      showToast({
        type: "error",
        message:
          JSON.stringify(reAssignTaskError?.data?.message) ||
          Translate("common:unknownServerError"),
      });
    }

    if (reAssignedTaskData && isReAssignTaskSuccess) {
      showToast({
        type: "success",
        message: reAssignedTaskData?.message,
      });
      resetFormikForm();
    }
  }, [
    reAssignedTaskData,
    reAssignTaskError,
    isReAssignTaskSuccess,
    isReAssignTaskError,
  ]);

  useEffect(() => {
    if (filesAdded?.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );

      setImages(newImages);
    }
  }, [filesAdded]);

  /********************************************************************** OnSubmit Function ****************************************************/
  const onSubmit = (taskFormValues) => {
    let b64Array = [];
    let oldAttachment = [];

    if (state?.isReAllocate) {
      if (state?.data?.assignee?._id === taskFormValues?.assignTo) {
        return showToast({
          message: Translate("taskDetails:reAssignUserSubtaskError"),
          type: "error",
        });
      } else {
        const reqBody = {
          taskId: state?.data?._id,
          assignTo: taskFormValues?.assignTo,
        };

        return reAllocateTaskToAssignee(reqBody);
      }
    }

    // ReAssignee flow for rejected status
    if (state?.isRejected) {
      const reqBody = {
        taskId: state?.data?._id,
        assignTo: taskFormValues?.assignTo,
      };

      return reAssignTask(reqBody);
    }

    if (filesAdded.length > 0) {
      filesAdded.map((fileItem) => {
        if (fileItem.b64) {
          b64Array.push({
            url: fileItem?.b64 ? fileItem?.b64 : fileItem?.url,
            type: fileItem?.type,
            taskFileName: fileItem?.name || fileItem?.path,
            taskFileExt: fileItem?.taskFileExt
              ? fileItem?.taskFileExt
              : getNameAndExtFromFile(fileItem?.name)?.length &&
                getNameAndExtFromFile(fileItem?.name)[1],
          });
        } else {
          const fileObj = {
            url: fileItem?.url,
            type: fileItem?.type,
            taskFileName: fileItem?.name || fileItem?.path,
            taskFileExt: fileItem?.taskFileExt
              ? fileItem?.taskFileExt
              : getNameAndExtFromFile(fileItem?.name)?.length &&
                getNameAndExtFromFile(fileItem?.name)[1],
          };
          oldAttachment.push(fileObj);
          b64Array.push(fileObj);
        }
      });
    }
    let requestObj = {
      taskId: state?.data?._id,
      taskNumber: state?.data?.taskNumber,
      company: taskFormValues.company,
      parentTask: {
        id: state?.data?.parentTaskDetails?._id,
        taskNumber: state?.data?.parentTaskDetails?.parentTaskNumber,
      },
      title: taskFormValues.title,
      projectId: taskFormValues.projectId,
      description: taskFormValues.description,
      assignTo: taskFormValues.assignTo,
      startDate: moment(taskFormValues.startDate).format(
        DateTimeFormats.YearMonthDay
      ),
      dueDate: moment(taskFormValues.dueDate).format(
        DateTimeFormats.YearMonthDay
      ),
      startTime: dayjs(taskFormValues.startTime).format(DateTimeFormats.Time),
      dueTime: dayjs(taskFormValues.dueTime).format(DateTimeFormats.Time),
      priority: taskFormValues.priority,
      reportTo: taskFormValues.reportTo,
      relatedTaskName: taskFormValues.relatedTaskName,
      isCritical: taskFormValues.markAsCritical,
      attachment: oldAttachment,
      webAttachment: b64Array,
      startDateObject: state?.data?.startDateObject,
      dueDateObject: dueDateTZ,
      moduleType: moduleType?.SUBTASK,
    };

    let isDueDateError = false;

    const currentDate = formatDateYYYYMMDD();
    const dueDate = formatDateYYYYMMDD(taskFormValues?.dueDate);

    if (dueDate <= currentDate) {
      showToast({
        message: Translate("addTask:beforeDateError"),
        type: "error",
      });
      isDueDateError = true;
    } else {
      formikRef.current.setFieldValue(
        "dueDate",
        new Date(state?.data?.dueDate)
      );
    }

    if (!isDueDateError) {
      let reg2 = /[a-zA-Z]/;
      if (reg2.test(taskFormValues.description)) {
        editSubtaskApi(requestObj);
      } else {
        showToast({
          message: Translate("addTask:descriptionInputError"),
          type: "error",
        });
      }
    }
  };

  /********************************************************************** Return Start ****************************************************/
  const resetOnCompanyChange = () => {
    formikRef.current.setFieldValue("assignTo", "");
    formikRef.current.setFieldValue("relatedTaskName", "");
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddSubtaskSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors }) => {
        return (
          <SubtaskForm
            isEdit
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            subtaskData={state}
            handleSubmit={handleSubmit}
            selectedCompany={selectedCompany}
            filesAdded={filesAdded}
            images={images}
            setFilesAdded={setFilesAdded}
            startDateTZ={startDateTZ}
            setStartDateTZ={setStartDateTZ}
            dueDateTZ={dueDateTZ}
            setDueDateTZ={setDueDateTZ}
            isLoading={
              isSubtaskLoading || isReAllocateLoading || isReAssignTaskLoading
            }
          />
        );
      }}
    </Formik>
  );
};

export default EditSubtask;
