import dayjs from "dayjs";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTimeFormats } from "../../../common/Utils/DateFormater";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  convertStringForHRManager,
  getDayJs,
  getMoment,
} from "../../../common/Utils/commonFunction/index.js";
import { useGetRemindToListMutation } from "../../../request/Calendar/index.js";
import { useSetTaskReminderMutation } from "../../../request/Task";
import { useGetVoiceNoteReminderMutation } from "../../../request/VoiceNotes";
import Translate from "../../../translation/utils/translateFunction";
import { calendarEnums } from "../../Calendar/utils";
import FormikDatePicker from "../../FormikForm/component/FormikDatePicker";
import { FormikDropdownPicker } from "../../FormikForm/component/FormikDropDownPicker";
import FormikSearchableDropDown from "../../FormikForm/component/FormikSearchableDropDown";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import PageHeader from "../../common/pageHeader";
import CustomReminder from "../Components/CustomReminder";
import {
  InitialValues,
  InitialValuesVoiceNotes,
  customNotificationType,
  notificationType,
  reminderOptions,
  reminderType,
} from "../Components/constants";
import { AddCustomReminderSchema } from "../Components/utils";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction/index.js";
import Loader from "../../staff/Owner/LoadingComp.js";
import { CustomUserItem } from "../../common/CustomCellRender.js";

const SetRemider = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userData, selectedCompanyList, validations } = useSelector(
    (state) => state.formanagement
  );
  const formikRef = useRef(null);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [startDateObj, setStartDateObj] = useState();
  const [startTimeObj, setStartTimeObj] = useState();
  const [startTime, setStartTime] = useState(
    getDayJs().format(DateTimeFormats.Time)
  );
  const [isLoading, setIsLoading] = useState(true);
  const [assignees, setAssignees] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [remindTo, setRemindTo] = useState("");

  const [
    getVoiceNoteReminder,
    {
      data: voiceNotesReminderData,
      isSuccess: successfullVoiceNoteReminder,
      isError: isErrorVoiceNoteReminder,
      error: errorVoiceNoteReminder,
      isLoading: isVoiceNoteLoading,
    },
  ] = useGetVoiceNoteReminderMutation();

  const [
    getRemindToList,
    { data: remindToData, isSuccess: isReminderToSuccess },
  ] = useGetRemindToListMutation();

  const [
    addTaskReminderApi,
    {
      data: taskReminderData,
      isSuccess: isTaskReminderSuccess,
      isLoading: isTaskReminderLoading,
      isError: isTaskReminderError,
      error: errorTaskReminder,
    },
  ] = useSetTaskReminderMutation();

  useEffect(() => {
    if (state?.isVoiceNote) {
      //formikRef.current.setFieldValue("startTime", getDayJs());
      formikRef.current.setFieldValue("reminderFor", state?.voiceNoteName);
      setRemindTo([{ label: "Self", value: "Self" }]);
    }
  }, [state]);

  useEffect(() => {
    if (isReminderToSuccess && remindToData?.data?.nodes?.length) {
      const remindToList = [];
      remindToList.push({
        id: userData?._id,
        value: userData?.name,
        label: (
          <CustomUserItem
            userName={userData?.name}
            profileUrl={userData?.profileUrl}
            title={Translate("common:self")}
          />
        ),
      });
      remindToData.data.nodes.map((assigneeItem) => {
        const { name, _id, role, email } = assigneeItem;
        const obj = {
          id: _id,
          value: name,
          label: (
            <CustomUserItem
              userName={name}
              title={role?.type ? `${name} | ${role?.type}` : name}
              profileUrl={assigneeItem?.profileUrl}
              email={email}
            />
          ),
        };
        remindToList.push(obj);
      });
      setAssignees(remindToList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReminderToSuccess]);

  useEffect(() => {
    if (state) {
      // update current time for the initial time
      formikRef.current.setFieldValue("startTime", getDayJs());
      if (state?.taskDetail) {
        const { _id, name, role, email, profileUrl } =
          state?.taskDetail?.assignee;

        const obj = {
          id: _id,
          value: name,
          label: (
            <CustomUserItem
              userName={name}
              title={
                role?.type
                  ? `${name} | ${convertStringForHRManager(role?.type)}`
                  : name
              }
              profileUrl={profileUrl}
              email={email}
            />
          ),
        };
        setRemindTo(obj);
        InitialValues.remindTo = state?.taskDetail?.assignee?._id;
        formikRef.current.setFieldValue(
          "remindTo",
          state?.taskDetail?.assignee?._id
        );
      }

      if (state?.fromCalendar) {
        const requestObj = {
          companyId: selectedCompanyList?.length
            ? selectedCompanyList?.map((item) => item?.value)
            : [],
          searchText: searchText,
          pageNo: pageNo,
        };
        getRemindToList(requestObj);
      }
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, searchText, pageNo]);

  useEffect(() => {
    if (isErrorVoiceNoteReminder && errorVoiceNoteReminder) {
      errorVoiceNoteReminder.data.error
        ? errorVoiceNoteReminder.data.error.length > 0 &&
          errorVoiceNoteReminder.data.error.map((errorItem) =>
            showToast({ message: errorItem.msg, type: "error" })
          )
        : showToast({
            message: errorVoiceNoteReminder?.data?.message,
            type: "error",
          });
    }

    if (successfullVoiceNoteReminder && voiceNotesReminderData) {
      showToast({ message: voiceNotesReminderData.message, type: "success" });
      formikRef.current.resetForm();

      navigate("/voiceNotes/myVoiceNotes");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voiceNotesReminderData, errorVoiceNoteReminder]);

  useEffect(() => {
    if (isTaskReminderError && errorTaskReminder) {
      errorTaskReminder.data.error
        ? errorTaskReminder.data.error.length > 0 &&
          errorTaskReminder.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({
            message: errorTaskReminder?.data?.message,
            type: "error",
          });
    }

    if (isTaskReminderSuccess && taskReminderData) {
      showToast({ message: taskReminderData.message, type: "success" });
      formikRef.current.resetForm();
      state?.fromCalendar ? navigate("/calendar") : navigate("/manageTask");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskReminderData, errorTaskReminder]);

  // Code for the past time checking
  // const checkValidTime = (
  //   reminderTypeTime,
  //   startTime,
  //   timeInterval,
  //   reminderTypes
  // ) => {
  //   const val = startTime;

  //   if (
  //     moment().format(DateTimeFormats.YearMonthDay) ===
  //     moment(val).format(DateTimeFormats.YearMonthDay)
  //   ) {
  //     let minHr;
  //     if (
  //       timeInterval === reminderType?.REM_CUSTOM &&
  //       reminderTypeTime >= 1 &&
  //       reminderTypes === customReminderType.MINUTES_BEFORE
  //     ) {
  //       minHr = "minutes";
  //     } else if (
  //       timeInterval === reminderType?.REM_CUSTOM &&
  //       reminderTypeTime >= 1 &&
  //       reminderTypes === customReminderType.HOURS_BEFORE
  //     ) {
  //       minHr = "hours";
  //     } else {
  //       minHr = reminderTypeTime > 1 ? "minutes" : "hours";
  //     }

  //     const selectedTimeValid = moment(startTime)
  //       .subtract(reminderTypeTime, minHr)
  //       .format(DateTimeFormats.Time);

  //     if (selectedTimeValid < moment().format(DateTimeFormats.Time)) {
  //       showToast({
  //         message: Translate("VoiceNotes:timeError"),
  //         type: "error",
  //       });

  //       return false;
  //     }
  //   }
  //   return true;
  // };

  const onSubmit = (FormValues) => {
    const { reminder, time, timeInterval, notification } = FormValues;
    // Code for the past time checking
    // let val =
    //   notification && timeInterval
    //     ? +time
    //     : +FormValues?.reminder.split("_")[0];

    // let booleanCheck = checkValidTime(
    //   val,
    //   convertDueDateUTC(
    //     FormValues?.startDate.toISOString(),
    //     FormValues?.startTime.toISOString()
    //   ),
    //   FormValues?.reminder,
    //   timeInterval
    // );

    let finalObj = {
      time: parseInt(time),
      timeInterval,
      notification,
      convertedTimeInterval: "",
      convertedNotification: "",
    };
    if (reminder === reminderType?.REM_CUSTOM) {
      if (timeInterval) {
        finalObj["convertedTimeInterval"] = timeInterval
          .split(" ")[0]
          ?.toUpperCase();
      }

      if (notification) {
        if (notification === customNotificationType?.PUSH_NOTIFICATION) {
          finalObj["convertedNotification"] = notificationType.PUSH;
        } else if (notification === customNotificationType?.MAIL_NOTIFICATION) {
          finalObj["convertedNotification"] = notificationType?.MAIL;
        }
      }
    }

    let requestObject = {
      userId: userData?._id,
      companyId: userData?.companyId,
      startDate: moment(FormValues?.startDate).format("YYYY-MM-DD"),
      startTime: startTime,
      startDateUTCObject: startDateObj,
      reminderType: finalObj.convertedNotification
        ? reminderType?.REM_CUSTOM
        : FormValues?.reminder,
      reminderObject: {
        reminderFor: FormValues?.reminderFor,
        remindTo: state?.isVoiceNote ? [userData._id] : [remindTo?.id],
        type: state?.isVoiceNote
          ? "VOICENOTE"
          : state?.fromCalendar
          ? calendarEnums.event
          : calendarEnums.task,
        notificationType: finalObj.convertedNotification
          ? finalObj?.convertedNotification
          : notificationType.PUSH,
        reminderType: finalObj.convertedNotification
          ? reminderType?.REM_CUSTOM
          : FormValues?.reminder,
        customReminderType: finalObj.convertedTimeInterval
          ? finalObj?.convertedTimeInterval
          : "NULL",
        reminderValue: notification && timeInterval ? time : 0,
        documentId: state?.isVoiceNote
          ? state?.voiceNoteId
          : state?.taskDetail?._id,
        documentCollection: state?.isVoiceNote
          ? "voicenotes"
          : state?.fromCalendar
          ? "events"
          : "tasks",
      },
      type: "REMINDER",
    };

    if (state?.isVoiceNote) {
      getVoiceNoteReminder(requestObject);
    } else {
      addTaskReminderApi(requestObject);
    }
  };

  const onCancel = () => {
    setShowCustomModal(false);
  };

  const convertDueDateUTC = (startDate, dueDate) => {
    let startDatePostFix = startDate.toString().split("T")[0];
    let dueDatePostFix = dueDate.toString().split("T")[1];
    let finalDueDate = startDatePostFix + "T" + dueDatePostFix;
    return finalDueDate;
  };

  const handleNavigation = () => {
    if (state?.isVoiceNote) {
      return navigate("/voiceNotes/myVoiceNotes");
    }

    if (state?.fromCalendar) {
      return navigate("/calendar");
    }

    if (state?.taskDetail) {
      return navigate(-1);
    }

    return navigate("/manageTask");
  };

  return (
    <>
      <Formik
        initialValues={
          state?.isVoiceNote ? InitialValuesVoiceNotes : InitialValues
        }
        innerRef={formikRef}
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={() => AddCustomReminderSchema(!state?.isVoiceNote)}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <>
              <div className="row container_spacing ">
                <div className="card card_spacing">
                  <PageHeader
                    title={
                      state?.isVoiceNote
                        ? Translate("VoiceNotes:reminderpageHeading")
                        : Translate("taskDetails:taskReminderHeading")
                    }
                    onClick={handleNavigation}
                  />
                  <div onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-md-6 col-sm-12">
                          <FormikTextField
                            name="reminderFor"
                            label={Translate("VoiceNotes:reminderFor")}
                            placeholder={Translate("VoiceNotes:reminderFor")}
                            autoComplete={"off"}
                            maxLength={validations?.nameLength.MAX}
                            required
                            autoFocus={!state?.isVoiceNote}
                          />
                        </div>

                        <div
                          className="form-group col-md-6 col-sm-12"
                          style={{ marginTop: "4px" }}
                        >
                          {state?.isVoiceNote ? (
                            <FormikDropdownPicker
                              options={remindTo}
                              value={values.remindTo}
                              name="remindTo"
                              label={Translate("VoiceNotes:remindTo")}
                              placeholder={Translate("VoiceNotes:remindTo")}
                              onSelect={(value) => {
                                setFieldValue("companyId", value);
                              }}
                              disabled={true}
                            />
                          ) : (
                            <FormikSearchableDropDown
                              selected={remindTo}
                              onSelect={(value) => {
                                setRemindTo(value);
                                setFieldValue("remindTo", value.id);
                              }}
                              onChange={(text) => {
                                setPageNo(1);
                                setSearchText(text);
                              }}
                              options={assignees}
                              placeholder={Translate("taskDetails:remindTo")}
                              label={Translate("taskDetails:remindTo")}
                              name="remindTo"
                              disabled={!state?.fromCalendar}
                              required
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="form-group col-md-6 col-sm-12"
                          style={{ marginTop: "1px" }}
                        >
                          <FormikDatePicker
                            label={Translate("VoiceNotes:date")}
                            containerStyle="datePickerContainer"
                            className="form-control bw-0"
                            format="yyyy/MM/dd"
                            name="startDate"
                            value={values.startDate}
                            onChange={(value) => {
                              setStartDateObj(
                                moment(value).format(
                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                )
                              );
                              setFieldValue("startDate", value);
                              setStartTimeObj(
                                getMoment()
                                  .utc()
                                  .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                              );
                            }}
                            minDate={getMoment().toDate()}
                            required
                          />
                        </div>

                        <div className="form-group col-md-6 col-sm-12">
                          <FormikDatePicker
                            label={Translate("VoiceNotes:time")}
                            format="HH:mm"
                            name="startTime"
                            value={values.startTime}
                            onChange={(event) => {
                              if (dayjs(event).format() !== "Invalid Date") {
                                let newTime = dayjs(event).format(
                                  DateTimeFormats.calendar_format
                                );
                                setStartTime(
                                  dayjs(event).format(DateTimeFormats.Time)
                                );
                                setFieldValue("startTime", dayjs(event));
                                setStartTimeObj(dayjs(event).format());
                                setStartDateObj(
                                  convertDueDateUTC(
                                    (values?.startDate).toISOString(),
                                    dayjs(newTime).toISOString()
                                  )
                                );
                              }
                            }}
                            isTimePicker
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6 col-sm-12">
                          <FormikDropdownPicker
                            options={reminderOptions}
                            required
                            value={formikRef?.current?.values?.type}
                            name="reminder"
                            label={Translate("VoiceNotes:reminder")}
                            placeholder={Translate("VoiceNotes:addReminder")}
                            onSelect={(value) => {
                              if (value === reminderType?.REM_CUSTOM) {
                                setShowCustomModal(true);
                                values.time
                                  ? setFieldValue("time", values.time)
                                  : setFieldValue("time", null);
                                values?.notification
                                  ? setFieldValue(
                                      "notification",
                                      values.notification
                                    )
                                  : setFieldValue(
                                      "notification",

                                      "As notification"
                                    );
                                values?.timeInterval
                                  ? setFieldValue(
                                      "timeInterval",
                                      values.timeInterval
                                    )
                                  : setFieldValue(
                                      "timeInterval",
                                      "Minutes before"
                                    );
                              } else {
                                setFieldValue("reminder", value);
                                setFieldValue("time", null);
                                setFieldValue("notification", "");
                                setFieldValue("timeInterval", "");
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-right line_spacing">
                      <button
                        type="submit"
                        className="btn btn-primary saveButton"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {Translate("common:save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <CustomReminder
                showModal={showCustomModal}
                onSave={setShowCustomModal}
                onCancel={onCancel}
              />
            </>
          );
        }}
      </Formik>
      <Loader loading={isTaskReminderLoading || isVoiceNoteLoading} />
    </>
  );
};
export default SetRemider;
