import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showToast } from "../../../../../common/Utils/ToastMessage";
import Translate from "../../../../../translation/utils/translateFunction";
import AlertPermission from "../../../../AlertPermission";
import {
  getProgresbarColor,
  getStatusLabel,
} from "../../../../Task/Components/utils";
import { styles } from "../../../../TextField/TextField/index.Style";
import TextWrapWithTooltip from "../../../../TextWrapWithTooltip";
import Loader from "../../../../staff/Owner/LoadingComp";
import ModalFormikForm from "../../../../ModalForm/Formik";
import { RejectedReasonSchema } from "../../../../AddTask/Components/TaskDetails/components/validators";
import { InitialValuesForRejected } from "../../../../AddTask/Components/TaskDetails/components/constants";
import { useApproveDisapproveRequestMutation } from "../../../../../request/ProcurementRequest";
import { UserTypes } from "../../../../ContactRepository/component/constant";
import { useNavigate } from "react-router-dom";

const ProcurementDetails = ({ viewData, currentStateId, onSuccess }) => {
  const { isRtl, userData } = useSelector((state) => state?.formanagement);
  const taskStatusBtnColor = getProgresbarColor(viewData?.status, true);
  const [showApproveDisapprove, setShowApproveDisapprove] = useState(false);
  const [showRejectReasonAlert, setShowRejectReasonAlert] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [hideApproveDisapprove, setHideApproveDisapprove] = useState(true);
  const navigate = useNavigate();
  const [
    approveDisapproveApi,
    {
      data: approveDisapproveData,
      isSuccess: successfulApproveDisapprove,
      isError: isErrorApproveDisapprove,
      error: errorApproveDisapprove,
      isLoading: isLoadingApproveDisapprove,
    },
  ] = useApproveDisapproveRequestMutation();

  useEffect(() => {
    if (errorApproveDisapprove && isErrorApproveDisapprove) {
      errorApproveDisapprove.data &&
        showToast({
          message: errorApproveDisapprove?.data?.message,
          type: "error",
        });
    }

    if (successfulApproveDisapprove && approveDisapproveData) {
      showToast({ message: approveDisapproveData.message, type: "success" });
      onSuccess(true);
      setHideApproveDisapprove(false);
      navigate("/manageRequest/procurementList/pendingApprovals");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    approveDisapproveData,
    errorApproveDisapprove,
    isErrorApproveDisapprove,
    successfulApproveDisapprove,
  ]);

  const onCancel = () => {
    setShowApproveDisapprove(false);
  };
  const approveOrDisapproveRequest = () => {
    if (!isApproved) {
      setShowRejectReasonAlert(true);
    }
    if (isApproved) {
      approveDisapproveApi({
        body: { approvalStatus: "Approved" },
        _id: currentStateId,
      });
    }

    setShowApproveDisapprove(false);
    setIsApproved(false);
  };
  const onRejectReasonAlert = (value) => {
    setShowRejectReasonAlert(false);
    approveDisapproveApi({
      body: { approvalStatus: "Disapproved", reason: value?.rejectedReason },
      _id: currentStateId,
    });
  };
  const onCancelRejectReasonAlert = () => setShowRejectReasonAlert(false);
  console.log("viewData?.isActionEnabled", viewData?.isActionEnabled);
  return (
    <div>
      <div className="card">
        <div>
          <div className="taskHeader">
            <div className="taskInfoBox">
              <TextWrapWithTooltip
                textVariant="span"
                customClassName="taskProjectTitle TaskInfoTitle"
                label={`#${viewData?.requestId}`}
                customStyle={isRtl && { direction: "ltr" }}
              />
            </div>
          </div>

          <div className="card_spacing">
            <div className="mb-2">
              <div className="col-7 py-1">
                <span className="taskHeading">
                  {Translate("taskDetails:startDateLabel")}
                </span>
              </div>
              <div className="TaskStyling">
                <div className="taskCardContentStyling">
                  <i
                    className={`icon-color ${
                      isRtl ? "ml-2" : "mr-2"
                    } ${"fa fa-calendar"}`}
                    style={{ display: "inline" }}
                  ></i>
                  {viewData?.deliveryInformation?.startDate}
                </div>
                <div className="taskCardContentStyling">
                  <i
                    className={`icon-color ${
                      isRtl ? "ml-2" : "mr-2"
                    } ${"fa fa-clock-o"}`}
                    style={{ display: "inline" }}
                  ></i>
                  {viewData?.deliveryInformation?.startTime}
                </div>
              </div>
            </div>

            <div className="mb-2">
              <div className="col-7 py-1">
                <span className="taskHeading">
                  {Translate("taskDetails:dueDateLabel")}
                </span>
              </div>
              <div className="TaskStyling">
                <div className="taskCardContentStyling">
                  <i
                    className={`icon-color ${
                      isRtl ? "ml-2" : "mr-2"
                    } ${"fa fa-calendar"}`}
                    style={{ display: "inline" }}
                  ></i>
                  {viewData?.deliveryInformation?.dueDate}
                </div>
                <div className="taskCardContentStyling">
                  <i
                    className={`icon-color ${
                      isRtl ? "ml-2" : "mr-2"
                    } ${"fa fa-clock-o"}`}
                    style={{ display: "inline" }}
                  ></i>
                  {viewData?.deliveryInformation?.dueTime}
                </div>
              </div>
            </div>

            <div className="TaskStyling mb-10">
              <div
                style={{
                  borderColor: taskStatusBtnColor,
                  color: taskStatusBtnColor,
                }}
                className="taskDetailsStatus saveButton"
              >
                {getStatusLabel(viewData?.status)}
              </div>
              <div style={styles.taskDateStyle}>
                <span className={viewData?.priority.toLowerCase()}>
                  {viewData?.priority}
                </span>
              </div>
            </div>
            {hideApproveDisapprove && viewData?.isActionEnabled && (
              <>
                <div className="mb-2">
                  <div className="col-7 py-3 taskApprovalButton">
                    <button
                      className="btn taskAcceptBtn saveButton"
                      onClick={() => {
                        setIsApproved(true);
                        setShowApproveDisapprove(true);
                      }}
                    >
                      {Translate("taskDetails:approveButtonLabel")}
                    </button>
                    <button
                      className="btn taskRejectBtn saveButton btnNoWrap"
                      onClick={() => {
                        setIsApproved(false);
                        setShowApproveDisapprove(true);
                      }}
                    >
                      {Translate("taskDetails:disapproveButtonLabel")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <AlertPermission
        show={showApproveDisapprove}
        subTitle={
          isApproved
            ? Translate("procurementRequest:approveAlert")
            : Translate("procurementRequest:disapproveAlert")
        }
        onOk={approveOrDisapproveRequest}
        onCanel={onCancel}
        okTitle={
          isApproved
            ? Translate("procurementRequest:approveTitle")
            : Translate("procurementRequest:disapproveTitle")
        }
      />
      <ModalFormikForm
        show={showRejectReasonAlert}
        title={Translate("procurementRequest:disapproveReason")}
        onOk={onRejectReasonAlert}
        onCancel={onCancelRejectReasonAlert}
        btnLabel={Translate("taskDetails:saveButtonLabel")}
        placeholder={Translate("taskDetails:reasonInputPlaceholder")}
        schema={RejectedReasonSchema}
        InitialValues={InitialValuesForRejected}
      />
      <Loader loading={isLoadingApproveDisapprove}></Loader>
    </div>
  );
};

export default memo(ProcurementDetails);
