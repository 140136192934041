import { Stack } from "@mui/material";
import React from "react";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { TextField } from "../../../TextField/TextField";
import { leaveStatusEnum } from "../../../Profile/LeaveRequest/constants";
import Translate from "../../../../translation/utils/translateFunction";
import { colors } from "../../../../common/Theme/Colors";
import { convertToSentenceCase } from "../../../../common/Utils/commonFunction";

export const LeaveDetailCard = ({
  leaveData,
  validations,
  isFromManageRequest,
}) => {
  const { type } = { ...leaveData?.userDetails?.role };

  return (
    <Stack className="card p-4">
      {leaveData?.leaveStatus === leaveStatusEnum.disapproved && (
        <div className="row leaveDetailsCard">
          <StyledTypography className="px-2 mb-1" color={colors.red_001}>
            {Translate("leaveRequest:disapproveReason")}
          </StyledTypography>
          <StyledTypography className="px-2 mb-4">
            {leaveData?.disapprovedReason}
          </StyledTypography>
        </div>
      )}

      {isFromManageRequest && leaveData?.userDetails && (
        <>
          <div className="row">
            <div className="form-group col-md-6 col-sm-6 mb-1">
              <span className="taskHeading">
                {leaveData?.userDetails?.name}
              </span>
              <StyledTypography className="mt-1 mb-3">
                {convertToSentenceCase(type)}
              </StyledTypography>
            </div>
            <div
              className="form-group col-md-6 col-sm-6 mb-1"
              style={{ textAlign: "end" }}
            >
              <StyledTypography className="mt-1 mb-3">
                {leaveData.history[0].approvalDate}
              </StyledTypography>
            </div>
          </div>
        </>
      )}
      <div className="row ">
        <div className="form-group col-md-12 col-sm-12">
          <span> {`${Translate("taskDetail:status")}:`}</span>
          <span className="px-2 mb-3 leaveRequestAttachment">
            {leaveData?.leaveStatus}
          </span>
        </div>
      </div>

      <div className="row">
        <StyledTypography className="px-2 mb-3">
          {leaveData?.duration === "1"
            ? `${Translate("leaveRequest:leaveDur")}: ${
                leaveData?.duration
              } day`
            : `${Translate("leaveRequest:leaveDur")}: ${
                leaveData?.duration
              } days`}
        </StyledTypography>
      </div>
      <div className="row">
        <div className="form-group col-md-6 col-sm-12">
          <TextField
            value={leaveData?.formattedFromDate}
            label={Translate("leaveRequest:from")}
            placeholder={Translate("leaveRequest:from")}
            autoComplete={"off"}
            maxLength={validations?.designationLength.MAX}
            disabled={true}
            className={"form-control textFieldHeight"}
          />
        </div>
        <div className="form-group col-md-6 col-sm-12">
          <TextField
            value={leaveData?.formattedEndDate}
            label={Translate("leaveRequest:to")}
            placeholder={Translate("leaveRequest:to")}
            autoComplete={"off"}
            maxLength={validations?.designationLength.MAX}
            disabled={true}
            className={"form-control textFieldHeight"}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12 col-sm-12">
          <TextField
            value={leaveData?.leaveReason}
            label={Translate("leaveRequest:leaveReason")}
            placeholder={Translate("addOwner:leaveReason")}
            autoComplete={"off"}
            maxLength={validations?.designationLength.MAX}
            disabled={true}
            className={"form-control textFieldHeight"}
          />
        </div>
      </div>
    </Stack>
  );
};
