import { Stack, TextField } from "@mui/material";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  ErroLabelStyle,
  FontFamily,
  StyledTypography,
} from "../../../../common/Utils/StyledComponents";
import { isRtl } from "../../../../common/Utils/commonFunction";
import { disableColor } from "../../../FormikForm/component/FormikNumberField";
import CountrySelect from "./country";

const PhoneInputField = ({
  className,
  value,
  onChange,
  label,
  onCountryChange,
  touched,
  RenderRightContainer,
  placeholder,
  error,
  style,
  maxLength,
  shortCodeChange,
  currentCountryCode,
  required,
  disabled,
  props,
  isLogin,
  autoFocus,
  onkeyDown,
  ...field
}) => {
  const handleInputChange = (event) => {
    if (event.target.value.length > maxLength) {
      event.target.value = event.target.value.slice(0, maxLength);
    }
  };
  const loginInputStyle = {
    fontFamily: FontFamily,
    width: "100%",
    height: "2.6rem",
    borderColor: touched && error ? "#dc3545" : "",
  };

  const loginInputStyleForRtl = {
    paddingRight: "48px",
    direction: "ltr",
    textAlign: "right",
  };

  const customLoginInputStyle = isRtl()
    ? { ...loginInputStyle, ...loginInputStyleForRtl }
    : { ...loginInputStyle };

  return (
    <Stack>
      <StyledTypography className="labelStyling" component="span">
        {label}
        {required && (
          <StyledTypography sx={{ color: "red" }} component="span">
            {" *"}
          </StyledTypography>
        )}
      </StyledTypography>
      {isLogin ? (
        <PhoneInput
          {...props}
          {...field}
          inputProps={{
            name: field.name,
            required: required,
            isError: field?.isError,
            error: touched && error !== undefined,
            autoComplete: field?.autoComplete,
            autoFocus: autoFocus,
            onkeydown: onkeyDown,
          }}
          placeholder={placeholder}
          country={"jo"}
          value={value || ""}
          onChange={onChange}
          countryCodeEditable={false}
          enableSearch
          disableSearchIcon
          searchStyle={{ width: "90%" }}
          inputStyle={customLoginInputStyle}
          buttonStyle={{
            borderColor: touched && error ? "#dc3545" : "",
          }}
          disabled={disabled}
          // enableAreaCodes={true}

          // enableAreaCodes={["us", "ca"]}
        />
      ) : (
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          className="hideSpinBtn"
        >
          <CountrySelect
            className={
              touched && error
                ? "countrySelectContainer is-invalid onError"
                : "countrySelectContainer"
            }
            onChange={(values) => onCountryChange(values)}
            shortCodeChange={(values) => shortCodeChange(values)}
            countryCode={currentCountryCode}
            disabled={disabled}
          />

          <TextField
            {...field}
            sx={
              disabled ? { ...disableColor, width: "100%" } : { width: "100%" }
            }
            inputProps={{
              style: {
                paddingLeft: isRtl() ? 0 : "4.6rem",
                paddingRight: isRtl() ? "4.6rem" : 0,
                fontFamily: FontFamily,
              },
            }}
            autoFocus={autoFocus}
            placeholder={placeholder}
            disabled={disabled}
            size="small"
            value={value || ""}
            {...props}
            onInput={handleInputChange}
            onChange={onChange}
            variant="outlined"
            autoComplete="off"
            error={touched && error !== undefined}
          />
        </Stack>
      )}
      {touched && error && (
        <StyledTypography sx={ErroLabelStyle} component="span">
          {error}
        </StyledTypography>
      )}
    </Stack>
  );
};

export default PhoneInputField;
