import React from "react";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { colors } from "../../../../common/Theme/Colors";
import Translate from "../../../../translation/utils/translateFunction";
import { convertString } from "../../../../common/Utils/commonFunction";

export const StatusCheck = ({ historyData }) => {
  return (
    <div className="card pb-4">
      <div className="card-header">
        <StyledTypography
          variant="subtitle1"
          sx={{ fontWeight: "500", color: colors.black }}
        >
          {Translate("leaveRequest:status")}
        </StyledTypography>
      </div>
      <div className="card-body">
        <ul className="new_timeline mt-3">
          {historyData?.map((statusItem, idx) => {
            const { label, approvalDate } = statusItem;
            return (
              <li key={idx}>
                <div>
                  <div
                    className={`bullet primary ${
                      approvalDate ? "shadow" : "inactiveBullet"
                    }`}
                  />
                  <div className="desc pt-2">
                    <h3>{convertString(label) || ""}</h3>
                    <span className="time text-pink">{approvalDate || ""}</span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
