import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { moduleTypes } from "../../../common/users/userTypes";
import { getFilterNavigate } from "../../../common/Utils/commonFunction";
import { StyledCard } from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useGetSharedListMutation } from "../../../request/ContactRepo";
import Translate from "../../../translation/utils/translateFunction";
import Searchbar from "../../common/Searchbar";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import Loader from "../../staff/Owner/LoadingComp";
import { getContactRepoColumns } from "../component/column.data";

const SharedList = ({ fixNavbar }) => {
  const { userData, selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const [loading, setLoading] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [sharedListDataFetched, setSharedListDataFetched] = useState();
  const [roleFilter, setRoleFilter] = useState("Sharedwithme");
  const navigate = useNavigate();
  const lastPage = useRef(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const [staffMenuList, setStaffMenuList] = useState([]);

  const companies = selectedCompanyList?.length
    ? selectedCompanyList?.map((item) => item?.value)
    : [];
  const [
    getSharedListApi,
    {
      data: sharedlistData,
      isLoading: isLoadingSharedList,
      isSuccess: isSuccessSharedList,
      isError: isErrorSharedList,
      error: errorSharedList,
    },
  ] = useGetSharedListMutation();

  useEffect(() => {
    if (sharedlistData && isSuccessSharedList) {
      const { nodes, pageInfo } = sharedlistData.data;
      setSharedListDataFetched(nodes);

      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
    }
    if (isErrorSharedList && errorSharedList) {
      showToast({ message: "Failed to fetch the data", type: "error" });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSharedList, sharedlistData]);
  useEffect(() => {
    if (userData) {
      const data = userData?.moduleAccess?.find(
        (item) => Object.keys(item)[0] === moduleTypes.Contactshare
      );
      setStaffMenuList(
        Object.values(data)[0]?.filter((item) => item?.isEnable)
      );
    }
  }, [userData]);
  let requestObject = useMemo(() => {
    let requestObject = {
      pageNo: currentPageNo,

      searchValue: search,
      sharedContactFilter: roleFilter,
    };
    return requestObject;
  }, [search, currentPageNo, roleFilter]);

  const debouncedSearch = useDebouncedCallback(() => {
    getSharedListApi(requestObject);
  }, 500);

  useEffect(() => {
    if (requestObject?.searchValue?.length) {
      debouncedSearch();
    } else {
      getSharedListApi(requestObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNo, search, requestObject, roleFilter]);

  const CustomCellActionRender = (params) => {
    const isRowSelected = selectedRow?.includes(params?.id);

    return (
      <td>
        <i
          className={`m-2 fa fa-eye cursor-pointer manageIconColor ${
            isRowSelected ? "disabled-icon" : ""
          }`}
          data-toggle="tooltip"
          title={Translate("contacts:view")}
          onClick={() =>
            navigate("/contactRepository/sharedListing/viewBusinessCard", {
              state: params?.id,
            })
          }></i>
      </td>
    );
  };

  const rowsPerPage = 10;
  const startSerialNumber = (currentPageNo - 1) * rowsPerPage + 1;

  const rowsWithSerialNumbers =
    sharedListDataFetched &&
    sharedListDataFetched.map((row, index) => ({
      ...row,
      serialNumber: startSerialNumber + index,
    }));
  const filters = ["Sharedwithme", "shareByMe"];

  const getFilterNavigateList = (selectedFilter) => {
    switch (selectedFilter) {
      case 0: {
        setRoleFilter("Sharedwithme");
        break;
      }
      case 1: {
        setRoleFilter("shareByMe");
        break;
      }
      default:
        break;
    }
  };
  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearch}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="mt-1">
                        <a
                          href="/#"
                          className="nav-link dropdown-toggle "
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <span
                            data-toggle="tooltip"
                            title={Translate("contacts:filter")}>
                            <IconifyIcon
                              icon="bx:filter"
                              width="28"
                              className="dateRangeFilterIcon"
                            />
                          </span>
                        </a>
                        <div className="dropdown-menu dropdownAlignment">
                          {filters.map((filterItem, index) => (
                            <>
                              <div onClick={() => getFilterNavigateList(index)}>
                                <a
                                  // href="!#"
                                  className={`dropdown-item cursor-pointer ${
                                    filterItem === roleFilter
                                      ? "selected unclickable"
                                      : ""
                                  }`}>
                                  {Translate(`contacts:${filterItem}`)}
                                </a>
                              </div>
                              {filters.length - 1 !== index && (
                                <div className="dropdown-divider" />
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                      {!!staffMenuList?.length && (
                        <div style={{ marginTop: "0.6rem" }}>
                          <a
                            href="/#"
                            className="nav-link dropdown-toggle px-0"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <i
                              className="fa fa-ellipsis-v manageIconColor filterIconSize"
                              data-toggle="tooltip"
                              title={Translate("contacts:filter")}
                            />
                          </a>
                          <div className="dropdown-menu dropdownAlignment">
                            {staffMenuList?.map((filterItem) => (
                              <>
                                <div
                                  onClick={() =>
                                    getFilterNavigate({
                                      selectedFilter: filterItem,
                                      navigate: navigate,
                                      companies: companies,
                                      isShare: true,
                                    })
                                  }>
                                  <a className="dropdown-item">
                                    {Translate(`contacts:${filterItem?.label}`)}
                                  </a>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getContactRepoColumns(CustomCellActionRender)}
                    rows={rowsWithSerialNumbers}
                    autoHeight
                    onPaginationChange={setCurrentPageNo}
                    currentPage={currentPageNo}
                    showShimmer={isLoadingSharedList}
                    showLoader={loading || isLoadingSharedList}
                    totalPageCount={lastPage.current}
                    // checkboxSelection={true}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedRow(newRowSelectionModel);
                    }}
                    disableRowSelectionOnClick
                    disableColumnSelector={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Loader loading={isLoadingSharedList} />
      </StyledCard>
    </Grid>
  );
};

export default SharedList;
