import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../../assets/images/Location.svg";
import {
  formatDateYYYYMMDD,
  formatDiffDuration,
  formatGetMonth,
  formatGetMonthWithValue,
  formatGetTime,
  formatGetTimeWithValue,
  formatGetYearsDiff,
} from "../../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  convertToB64,
  getMoment,
} from "../../../../common/Utils/commonFunction";
import {
  useAddStaffOwnerMutation,
  useDeleteStaffOwnerMutation,
  useEditStaffOwnerMutation,
} from "../../../../request/Staff/Owner";
import { useLazyGetCompanyListQuery } from "../../../../request/company";
import Translate from "../../../../translation/utils/translateFunction";
import AlertPermission from "../../../AlertPermission";
import Dropzone from "../../../CommonFileUpload/Dropzone";
import FormikDatePicker from "../../../FormikForm/component/FormikDatePicker";
import FormikMultiSelectDropDown from "../../../FormikForm/component/FormikMultiSelectDropDown";
import { FormikPhoneInput } from "../../../FormikForm/component/FormikPhoneInput";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import { Persona } from "../../../Persona";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import RadioButtons from "../../../RadioButtons";
import PageHeader from "../../../common/pageHeader";
import Loader from "../LoadingComp";
import { InitialValues, radioOptions } from "./constant";
import { AddOwnerSchema } from "./utils";
import { getAddressError } from "../../../../common/Utils/commonFunction";
import { FormikPhoneInputWithExtField } from "../../../FormikForm/component/FormikPhoneInputWithExt";
import { handleEventKeySubmit } from "../../../../common/Utils/commonFunction";
import FormikSearchableDropDown from "../../../FormikForm/component/FormikSearchableDropDown";
import { CustomUserItem } from "../../../common/CustomCellRender";

const AddOwner = () => {
  const { validations, userData } = useSelector((state) => state.formanagement);

  const [filesAdded, setfilesAdded] = useState([]);
  const [show, setshow] = useState(false);
  const [images, setImages] = useState([]);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const { state } = useLocation();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [addMoreManager, setAddMoreManager] = useState(false);
  const [reportTo, setReportTo] = useState({});
  const initialImage = [state?.profileImageDetails];
  const [showRemoveProfileAlert, setShowRemoveProfileAlert] = useState(false);
  const [base64Img, setBase64Imge] = useState("");

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse && !state) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );
      setAllCompanyData(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  const [
    addOwner,
    {
      data: addOwnerData,
      isSuccess: successfullyAddedOwner,
      isError: errorInAddingOwner,
      error: errorOwner,
      isLoading: isAdding,
    },
  ] = useAddStaffOwnerMutation();

  const [
    editStaffOwnerApi,
    {
      data: editOwner,
      isSuccess: successfulEditOwner,
      isError: errorInEdit,
      error: errorStaffOwner,
      isLoading: isEditing,
    },
  ] = useEditStaffOwnerMutation();

  const [
    deleteStaffOwnerApi,
    {
      data: deleteStaffOwnerData,
      isSuccess: successfullyDeleteStaffOwner,
      isLoading: isOwnerDeleteLoading,
      isError: isErrorDeleteStaffOwner,
      error: errorDeleteStaffOwner,
    },
  ] = useDeleteStaffOwnerMutation();

  const onDobChange = (value) => {
    const todaysDate = getMoment().toDate();
    const diff = formatDiffDuration(todaysDate, value);
    const getDate = formatGetTime();
    const getSelectedDate = formatGetTimeWithValue(value);
    const getMonth = formatGetMonth();
    const getSelectedMonth = formatGetMonthWithValue(value);

    const getYearsDiff = formatGetYearsDiff(value);
    if (
      diff >= 18 ||
      (getYearsDiff >= 18 &&
        getDate === getSelectedDate &&
        getMonth === getSelectedMonth)
    ) {
      formikRef.current.setFieldValue("dob", new Date(value));
      InitialValues.dob = formatDateYYYYMMDD(value);
    } else {
      showToast({ message: Translate("addOwner:ageAlert"), type: "error" });
      formikRef.current.setFieldValue("dob", "");
      InitialValues.dob = "";
    }
  };

  useEffect(() => {
    return () => {
      InitialValues.dob = "";
    };
  }, []);

  useEffect(() => {
    if (state) {
      formikRef.current.setFieldValue(
        "companyId",
        state?.company.map((ele) => ele._id)
      );

      formikRef.current.setFieldValue("name", state.name);

      if (state?.alternateMobile) {
        formikRef.current.setFieldValue(
          "alternateMobile",

          `${state?.alternateMobileCountryCode}-${state?.alternateMobile}`
        );
      }

      formikRef.current.setFieldValue(
        "alternateMobileShortCode",
        state.alternateMobileShortCode ? state?.alternateMobileShortCode : "JO"
      );
      formikRef.current.setFieldValue(
        "alternateMobileCountryCode",
        state?.alternateMobileCountryCode
          ? state?.alternateMobileCountryCode
          : "962"
      );

      formikRef.current.setFieldValue("dob", new Date(state?.dob));
      formikRef.current.setFieldValue("designation", state.designation);
      formikRef.current.setFieldValue("department", state.department);

      formikRef.current.setFieldValue(
        "mobile",
        `${state?.login?.countryCode}-${state?.login?.mobile}`
      );

      formikRef.current.setFieldValue("countryCode", state?.login.countryCode);
      formikRef.current.setFieldValue("companyNumber", state.companyNumber);
      formikRef.current.setFieldValue(
        "companyExtension",
        state.companyExtension
      );

      if (state?.hrMobile) {
        formikRef.current.setFieldValue(
          "hrMobile",

          `${state?.hrMobileCountryCode}-${state?.hrMobile}`
        );
      }
      formikRef.current.setFieldValue(
        "hrMobileCountryCode",

        state?.hrMobileCountryCode ? state?.hrMobileCountryCode : "962"
      );
      formikRef.current.setFieldValue(
        "hrMobileShortCode",
        state.hrMobileShortCode ? state?.hrMobileShortCode : "JO"
      );
      formikRef.current.setFieldValue(
        "mobileShortCode",
        state?.login?.mobileShortCode
      );
      formikRef.current.setFieldValue("email", state?.login?.email);
      formikRef.current.setFieldValue(
        "registerAddress",
        state?.residenceAddress?.address
      );
      formikRef.current.setFieldValue(
        "resApartment",
        state?.residenceAddress?.flat
      );
      formikRef.current.setFieldValue(
        "resCountry",
        state?.residenceAddress?.country
      );
      formikRef.current.setFieldValue(
        "resState",
        state?.residenceAddress?.state
      );
      formikRef.current.setFieldValue("resCity", state?.residenceAddress?.city);
      formikRef.current.setFieldValue(
        "resZipCode",
        state?.residenceAddress?.zipCode
      );
      formikRef.current.setFieldValue(
        "workAddress",
        state?.workAddress?.address
      );
      formikRef.current.setFieldValue(
        "workApartment",
        state?.workAddress?.flat
      );
      formikRef.current.setFieldValue(
        "workCountry",
        state?.workAddress?.country
      );
      formikRef.current.setFieldValue("workState", state?.workAddress?.state);
      formikRef.current.setFieldValue("workCity", state?.workAddress?.city);
      formikRef.current.setFieldValue(
        "workZipCode",
        state?.workAddress?.zipCode
      );
      formikRef.current.setFieldValue("gender", state.gender);

      if (
        state?.profileImageDetails &&
        Object.keys(state?.profileImageDetails).includes("url")
      ) {
        setfilesAdded([state?.profileImageDetails]);
        setImages([state?.profileImageDetails?.url]);
      }
      if (state?.company.length) {
        let companyData = [...state?.company];

        let dropDownData = companyData?.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        setAllCompanyData(dropDownData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (state) {
      const { id, name, role, profileUrl, email } = state?.reportTo;

      setReportTo({
        id: id,
        name,
        role: role,
        profileUrl,
        value: name,
        label: (
          <CustomUserItem
            userName={name}
            title={role ? `${name} | ${role}` : name}
            profileUrl={profileUrl}
            email={email}
          />
        ),
      });
    } else {
      const { _id, name, role, profileUrl, login } = userData;

      setReportTo({
        id: _id,
        name,
        role: role?.type,
        profileUrl,
        value: name,
        label: (
          <CustomUserItem
            userName={name}
            title={role?.type ? `${name} | ${role?.type}` : name}
            profileUrl={profileUrl}
            email={login?.email}
          />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, userData]);

  useEffect(() => {
    if (errorInAddingOwner && errorOwner) {
      errorOwner?.data?.error
        ? errorOwner?.data?.error.length > 0 &&
          errorOwner.data.error.map((errorItem) => {
            const isPresent = getAddressError(
              errorItem.param,
              formikRef.current?.setFieldError
            );
            !isPresent &&
              formikRef.current.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({ message: errorOwner?.data?.message, type: "error" });
    }

    if (successfullyAddedOwner && addOwnerData) {
      showToast({ message: addOwnerData.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      if (addMoreManager) {
        formikRef.current.resetForm();
        InitialValues.dob = "";
        formikRef.current.setFieldValue("dob", "");
        InitialValues.alternateMobile = "962";
        InitialValues.mobile = "962";
        InitialValues.hrMobile = "962";
        setAddMoreManager(false);
      } else {
        navigate("/ownerListing");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOwnerData, errorOwner]);

  useEffect(() => {
    if (errorStaffOwner && errorInEdit) {
      errorStaffOwner.data.error
        ? errorStaffOwner.data.error.length > 0 &&
          errorStaffOwner.data.error.map((errorItem) => {
            const isPresent = getAddressError(
              errorItem.param,
              formikRef.current?.setFieldError
            );
            !isPresent &&
              formikRef.current.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({
            message: errorStaffOwner?.data?.message,
            type: "error",
          });
    }

    if (successfulEditOwner && editOwner) {
      showToast({ message: editOwner.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      formikRef.current.resetForm();
      navigate("/ownerListing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOwner, errorInEdit, errorStaffOwner]);

  useEffect(() => {
    if (errorDeleteStaffOwner && isErrorDeleteStaffOwner) {
      errorDeleteStaffOwner.data.error
        ? errorDeleteStaffOwner.data.error.length > 0 &&
          errorDeleteStaffOwner.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({
            message: errorDeleteStaffOwner?.data?.message,
            type: "error",
          });
    }

    if (successfullyDeleteStaffOwner && deleteStaffOwnerData) {
      showToast({ message: deleteStaffOwnerData.message, type: "success" });
      navigate("/ownerListing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDeleteStaffOwner, deleteStaffOwnerData]);

  const onSubmit = async (companyFormValues) => {
    const reportToObj = { ...reportTo };

    // delete labels and value
    delete reportToObj["value"];
    delete reportToObj["label"];

    let requestObjectEditOwner = {
      companyId: state
        ? state?.company.map((ele) => ele._id)
        : companyFormValues?.companyId,
      name: companyFormValues?.name,

      dob:
        moment(companyFormValues.dob).creationData().format === "YYYY-MM-DD"
          ? companyFormValues.dob
          : formatDateYYYYMMDD(companyFormValues.dob),
      designation: companyFormValues?.designation,
      department: companyFormValues?.department,
      mobile: companyFormValues?.mobile?.split("-")[1],
      countryCode: companyFormValues?.countryCode,
      mobileShortCode: companyFormValues?.mobileShortCode,
      email: companyFormValues?.email,
      emailVerified: "YES",
      mobileVerified: "YES",
      residenceAddress: {
        latitude: companyFormValues?.resLat,
        longitude: companyFormValues?.resLng,
        latlong: {
          latitude: companyFormValues?.resLat,
          longitude: companyFormValues?.resLng,
        },
        flat: companyFormValues?.resApartment,
        address: companyFormValues?.registerAddress,
        country: companyFormValues?.resCountry,
        state: companyFormValues?.resState,
        city: companyFormValues?.resCity,
        zipCode: companyFormValues?.resZipCode,
      },
      workAddress: {
        latitude: companyFormValues?.workLat,
        longitude: companyFormValues?.workLng,
        latlong: {
          latitude: companyFormValues?.workLat,
          longitude: companyFormValues?.workLng,
        },
        flat: companyFormValues?.workApartment,
        address: companyFormValues?.workAddress,
        country: companyFormValues?.workCountry,
        state: companyFormValues?.workState,
        countryISO: "IN",
        city: companyFormValues?.workCity,
        zipCode: companyFormValues?.workZipCode,
      },
      gender: companyFormValues?.gender?.toUpperCase(),
      role: state
        ? state?.role
        : {
            type: "OWNER",
          },
      primary: "NO",
      system: "NO",
      businessCard: {
        type: "",
        fileURL: "",
        desc: "",
      },
      reportTo: reportToObj,
    };

    if (filesAdded.length > 0) {
      if (filesAdded.every((value) => initialImage?.includes(value))) {
        requestObjectEditOwner = {
          ...requestObjectEditOwner,
          profileUrl: state?.profileUrl,
          profileImageDetails: state?.profileImageDetails,
        };
      } else {
        const b64Object = {
          ...filesAdded.map((ele) => ({
            url: ele?.b64,
            type: ele?.type,
            profileFileName: ele?.name,
            profileFileExt: ele?.name.split(".").pop(),
          }))[0],
        };

        requestObjectEditOwner = {
          ...requestObjectEditOwner,
          webProfileUrl: b64Object,
        };
      }
    } else {
      requestObjectEditOwner = {
        ...requestObjectEditOwner,
      };
    }

    if (companyFormValues?.companyNumber?.length > 0) {
      requestObjectEditOwner = {
        ...requestObjectEditOwner,
        companyNumber: companyFormValues.companyNumber,
        companyExtension: companyFormValues?.companyExtension,
      };
    }
    if (
      companyFormValues?.alternateMobile?.length > 0 &&
      companyFormValues?.alternateMobile?.includes("-")
    ) {
      requestObjectEditOwner = {
        ...requestObjectEditOwner,

        alternateMobile: companyFormValues?.alternateMobile?.split("-")[1],

        alternateMobileCountryCode:
          companyFormValues?.alternateMobileCountryCode,
        alternateMobileShortCode: companyFormValues.alternateMobileShortCode,
      };
    } else {
      requestObjectEditOwner = {
        ...requestObjectEditOwner,

        alternateMobile: "",

        alternateMobileCountryCode: "",
        alternateMobileShortCode: "",
      };
    }

    if (
      companyFormValues?.hrMobile?.length > 0 &&
      companyFormValues?.hrMobile?.includes("-")
    ) {
      requestObjectEditOwner = {
        ...requestObjectEditOwner,
        hrMobile: companyFormValues?.hrMobile?.split("-")[1],

        hrMobileCountryCode: companyFormValues?.hrMobileCountryCode,

        hrMobileShortCode: companyFormValues?.hrMobileShortCode,
      };
    } else {
      requestObjectEditOwner = {
        ...requestObjectEditOwner,
        hrMobile: "",

        hrMobileCountryCode: "",

        hrMobileShortCode: "",
      };
    }

    if (state) {
      await editStaffOwnerApi({
        body: requestObjectEditOwner,
        id: state._id,
      });
    } else {
      await addOwner(requestObjectEditOwner);
    }
  };

  const addFiles = async (file) => {
    if (file) {
      const base64 = await convertToB64(file);
      setBase64Imge(base64);
    }

    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
        ? URL.createObjectURL(file)
        : null;
    if (url) {
      file.preview = url;
    }
    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setfilesAdded([...filesAdded]);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );
      setImages(newImages);
    }
  }, [filesAdded]);

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };

  const removeFile = () => {
    setfilesAdded([]);
    setBase64Imge("");
    onCancelProfile();
  };

  const onCancelProfile = () => setShowRemoveProfileAlert(false);

  const currentLocationData = (places, addressType) => {
    if (addressType) {
      formikRef.current.setFieldValue("resLat", places.geometry.location.lat());
      formikRef.current.setFieldValue("resLng", places.geometry.location.lng());
      formikRef.current.setFieldValue(
        "registerAddress",
        places.formatted_address
      );
      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("resCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("resState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("resCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("resZipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("resZipCode", "");
        }
      });
    } else {
      formikRef.current.setFieldValue("workAddress", places.formatted_address);
      formikRef.current.setFieldValue(
        "workLat",
        places.geometry.location.lat()
      );
      formikRef.current.setFieldValue(
        "workLng",
        places.geometry.location.lng()
      );
      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("workCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("workState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("workCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("workZipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("workZipCode", "");
        }
      });
    }
  };
  const deleteStaffOwner = () => {
    setshow(false);
    deleteStaffOwnerApi(state._id);
  };
  const onCancel = () => setshow(false);

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddOwnerSchema}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <div className="row container_spacing">
            <div className="card card_spacing">
              <PageHeader
                title={
                  state
                    ? Translate("addOwner:editOwner")
                    : Translate("homePage:addOwner")
                }
                onClick={() => {
                  state
                    ? navigate("/viewOwner", { state: state._id })
                    : navigate("/ownerListing");
                }}
                RightContainer={() =>
                  state?.isDeleteable && (
                    <div className="card-options">
                      <i
                        className="fe fe-trash-2 cursor-pointer"
                        onClick={() => setshow(true)}
                        data-toggle="tooltip"
                        title={Translate("common:deleteToolTip")}
                      ></i>
                    </div>
                  )
                }
              />
              <div
                className="card-body"
                onKeyDown={(e) => handleEventKeySubmit(e, submitForm)}
              >
                <div className="row imageDisplay mb-2">
                  <div className="form-group col-md-4 col-sm-8">
                    <Persona
                      isProfile={!state}
                      name={state?.name}
                      isBase64={base64Img ? true : false}
                      image={
                        filesAdded?.length && images?.length
                          ? images[0].includes(VALIDATION_BUCKETURL)
                            ? images[0]
                            : base64Img
                          : ""
                      }
                      size={96}
                    />
                    <Dropzone
                      onfileSelect={(filesArray) => addFiles(filesArray)}
                      acceptedImages={acceptedFileType}
                      maximumFiles={1}
                      fileLength={filesAdded.length}
                      maxFileSize={50 * 1000000}
                      className="staffProfileIcon"
                      isProfile
                      deleteAlert={() => {
                        setShowRemoveProfileAlert(true);
                      }}
                      isShowCameraDeleteIcon={filesAdded?.length}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikMultiSelectDropDown
                      options={allCompanyData}
                      name="companyId"
                      label={Translate("addOwner:companyDropdownPlaceholder")}
                      placeholder={Translate(
                        "addOwner:companyDropdownPlaceholder"
                      )}
                      onSelect={(value) => {
                        setFieldValue("companyId", value);
                      }}
                      disabled={state?.isEditable}
                      required
                      autoFocus={!state}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="name"
                      label={Translate("addOwner:name")}
                      placeholder={Translate("addOwner:name")}
                      autoComplete={"off"}
                      maxLength={50}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikDatePicker
                      label={Translate("addOwner:dateOfBirth")}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0 "
                      format="yyyy-MM-dd"
                      name="dob"
                      value={values.dob}
                      onChange={onDobChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="designation"
                      label={Translate("addOwner:designation")}
                      placeholder={Translate("addOwner:designation")}
                      autoComplete={"off"}
                      maxLength={100}
                      required
                    />
                  </div>

                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="department"
                      label={Translate("addOwner:department")}
                      placeholder={Translate("addOwner:department")}
                      autoComplete={"off"}
                      required
                      maxLength={100}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      label={Translate("addOwner:contactNumber")}
                      name="mobile"
                      value={values.mobile}
                      placeholder={Translate(
                        "addOwner:contactNumberPlaceholder"
                      )}
                      maxLength={validations?.contactPhoneLength.MAX}
                      onChange={(value, country) => {
                        setFieldValue("mobile", value);
                        setFieldValue(
                          "mobileShortCode",
                          country?.countryCode?.toUpperCase()
                        );
                        setFieldValue("countryCode", country?.dialCode);
                      }}
                      autoComplete={"off"}
                      required
                      isLogin
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      label={Translate("addOwner:alternateMobile")}
                      placeholder={Translate(
                        "addOwner:contactNumberPlaceholder"
                      )}
                      name="alternateMobile"
                      value={values.alternateMobile}
                      maxLength={validations?.contactPhoneLength.MAX}
                      onChange={(value, country) => {
                        setFieldValue("alternateMobile", value);
                        setFieldValue(
                          "alternateMobileShortCode",
                          country?.countryCode?.toUpperCase()
                        );
                        setFieldValue(
                          "alternateMobileCountryCode",
                          country?.dialCode
                        );
                      }}
                      autoComplete={"off"}
                      isLogin
                    />
                  </div>

                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInputWithExtField
                      name="companyNumber"
                      extName="companyExtension"
                      label={Translate("addOwner:extensionNumber")}
                      placeholder={Translate(
                        "addOwner:extensionNumberPlaceholder"
                      )}
                      extPlaceholder={Translate(
                        "addOwner:companyPhoneExtensionPlaceholder"
                      )}
                      autoComplete={"off"}
                      maxLength={validations?.telephoneLength.MAX}
                      extMaxLength={validations?.companyExtension.MAX}
                    />
                  </div>

                  <div className="form-group col-md-4 col-sm-12">
                    {/* <FormikPhoneInput
                      label={Translate("addOwner:hrContact")}
                      name="hrMobile"
                      value={values.hrMobile}
                      onChange={(value) => setFieldValue("hrMobile", value)}
                      onCountryChange={(hrCountry) =>
                        setFieldValue("hrMobileCountryCode", hrCountry.slice(1))
                      }
                      placeholder={Translate("addOwner:hrContactPlaceholder")}
                      maxLength={validations?.contactPhoneLength.MAX}
                      shortCodeChange={(item) =>
                        setFieldValue("hrMobileShortCode", item)
                      }
                      currentCountryCode={values?.hrMobileShortCode}
                    /> */}
                    <FormikPhoneInput
                      placeholder={Translate("addOwner:hrContactPlaceholder")}
                      label={Translate("addOwner:hrContact")}
                      name="hrMobile"
                      value={values.hrMobile}
                      maxLength={validations?.contactPhoneLength.MAX}
                      onChange={(value, country) => {
                        setFieldValue("hrMobile", value);
                        setFieldValue(
                          "hrMobileShortCode",
                          country?.countryCode?.toUpperCase()
                        );
                        setFieldValue("hrMobileCountryCode", country?.dialCode);
                      }}
                      autoComplete={"off"}
                      isLogin
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikSearchableDropDown
                      selected={reportTo}
                      options={[]}
                      label={Translate("addOwner:reportTo")}
                      placeholder={Translate("addOwner:reportTo")}
                      name="reportTo"
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="email"
                      label={Translate("addOwner:workEmail")}
                      placeholder={Translate("addOwner:workEmail")}
                      autoComplete={"off"}
                      required
                      maxLength={100}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <div className="AddressLabel">
                      <StyledTypography variant="subtitle1">
                        {Translate("addEmployee:address")}
                        <StyledTypography
                          sx={{ color: "red" }}
                          component="span"
                        >
                          {" *"}
                        </StyledTypography>
                      </StyledTypography>
                    </div>

                    <FormikTextField
                      name="registerAddress"
                      placeholder={Translate(
                        "addOwner:residenceAddressPlaceholder"
                      )}
                      autoComplete={"off"}
                      isLocation={true}
                      RenderRightContainer={
                        <LocationIcon className="formikLocationIcon" />
                      }
                      locationValue={(currentLocation) => {
                        currentLocationData(currentLocation, true);
                      }}
                      maxLength={100}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="resApartment"
                      placeholder={Translate(
                        "addOwner:residenceAddressPlaceholder2"
                      )}
                      autoComplete={"off"}
                      maxLength={validations?.landmarkLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="resCountry"
                      placeholder={Translate("addOwner:country")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="resState"
                      placeholder={Translate("addOwner:state")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="resCity"
                      placeholder={Translate("addOwner:city")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="resZipCode"
                      maxLength={6}
                      placeholder={Translate("addOwner:zipcode")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <div className="AddressLabel">
                      <StyledTypography variant="subtitle1">
                        {Translate("addOwner:workAddress")}
                        <StyledTypography
                          sx={{ color: "red" }}
                          component="span"
                        >
                          {" *"}
                        </StyledTypography>
                      </StyledTypography>
                    </div>
                    <FormikTextField
                      name="workAddress"
                      placeholder={Translate(
                        "addOwner:workAddressPlaceholder2"
                      )}
                      isLocation={true}
                      RenderRightContainer={
                        <LocationIcon className="formikLocationIcon" />
                      }
                      locationValue={(currentLocation) => {
                        currentLocationData(currentLocation, false);
                      }}
                      autoComplete={"off"}
                      maxLength={100}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="workApartment"
                      placeholder={Translate("addOwner:workAddressPlaceholder")}
                      autoComplete={"off"}
                      maxLength={validations?.landmarkLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="workCountry"
                      maxLength={validations?.cityLimit.MAX}
                      placeholder={Translate("addOwner:country")}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="workState"
                      maxLength={validations?.cityLimit.MAX}
                      placeholder={Translate("addOwner:state")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="workCity"
                      placeholder={Translate("addOwner:city")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="workZipCode"
                      maxLength={6}
                      placeholder={Translate("addOwner:zipcode")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <RadioButtons
                      radioOptions={radioOptions}
                      defaultChecked={
                        state ? (state.gender === "MALE" ? 0 : 1) : 0
                      }
                      label={Translate("addOwner:gender")}
                      onPress={(value) => {
                        setFieldValue("gender", value.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="submit"
                    className="btn btn-primary saveButton"
                    onClick={async () => {
                      await submitForm();

                      setAddMoreManager(false);
                    }}
                  >
                    {Translate("addOwner:save")}
                  </button>
                  {!state && (
                    <button
                      type="submit"
                      className="btn btn-white addButton"
                      onClick={() => {
                        submitForm();
                        setAddMoreManager(true);
                      }}
                    >
                      {Translate("addOwner:addMore")}
                    </button>
                  )}
                </div>
              </div>
              <Loader loading={isAdding || isEditing || isOwnerDeleteLoading} />
              <AlertPermission
                show={show}
                subTitle={Translate("addOwner:deleteAlertMsg")}
                onOk={deleteStaffOwner}
                onCanel={onCancel}
              />
              <AlertPermission
                show={showRemoveProfileAlert}
                subTitle={Translate("common:deleteProfileAlert")}
                onOk={removeFile}
                onCanel={onCancelProfile}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddOwner;
