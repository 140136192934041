import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import CompanyHeader from "../../../common/CompanyHeader";
import { colors } from "../../../common/Theme/Colors";
import { userTypes } from "../../../common/users/userTypes";
import { checkModuleAccess } from "../../../common/Utils/commonFunction";
import {
  StyledCard,
  StyledTypography
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useGetStaffOwnerListingMutation } from "../../../request/Staff/Owner";
import Translate from "../../../translation/utils/translateFunction";
import Searchbar from "../../common/Searchbar";
import CompanyListing from "../../ContactRepository/component";
import ListView from "../../ListView";
import { getOwnerColumns } from "./Components/columns.data";

const OwnerListing = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState();
  const [ownerDataFetched, setownerDataFetched] = useState();
  const [showModal, setShowModal] = useState(false);
  const lastPage = useRef(0);

  const [
    getownerListApi,
    {
      data: ownerDataList,
      isSuccess: successOwnerList,
      error: Error,
      isError: isOwnerError,
      isLoading: loadingOwnerData,
    },
  ] = useGetStaffOwnerListingMutation();

  let requestObject = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];
    return sortBy !== null && sortBy !== undefined
      ? {
        companies,
        searchValue: search,
        sortCoulmn: selectedColumn,
        sortDirection: sortBy ? 1 : -1,
      }
      : {
        companies,
        searchValue: search,
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sortBy, currentPageNo, selectedCompanyList]);

  useEffect(() => {
    if (successOwnerList && ownerDataList) {
      const { nodes, pageInfo } = ownerDataList.data;
      setownerDataFetched(nodes);
      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
    }
    if (isOwnerError && Error) {
      Error?.originalStatus !== 500 &&
        showToast({
          message: Translate("common:apiFailedMessage"),
          type: "error",
        });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successOwnerList, isOwnerError]);
  const debouncedSearch = useDebouncedCallback(() => {
    sortBy !== undefined &&
      getownerListApi({ requestBody: requestObject, pageNo: currentPageNo });
  }, 500);

  useEffect(() => {
    if (selectedCompanyList?.length) {
      sortBy === null && setLoading(true);
      if (requestObject?.searchValue?.length) {
        debouncedSearch();
      } else {
        const finalRequestObject = {
          requestBody: requestObject,
          pageNo: currentPageNo,
        };
        sortBy !== undefined && getownerListApi(finalRequestObject);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("addOwner:selectComapnyMessage"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObject]);

  const getSort = (columnName = "") => {
    setLoading(true);
    selectedColumn !== columnName && setSortBy(undefined);
    setTimeout(() => {
      selectedColumn !== columnName ? setSortBy(true) : setSortBy(!sortBy);
    }, 500);
    setSelectedColumn(columnName);
  };

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() => navigate("/viewOwner", { state: params?.row?.userId })}
        ></i>
      </td>
    );
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.userId) +
          1 +
          (currentPageNo - 1) * 10}
      </StyledTypography>
    );
  };
  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${fixNavbar ? "marginTop" : ""
        } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearch}
                      />
                    </div>
                    {checkModuleAccess(userData?.add, userTypes?.Owner) && (
                      <div className="header-action pull-right ml-2">
                        <button
                          type="button"
                          className="btn btn-primary py-2"
                          onClick={() => navigate("/addOwner")}
                        >
                          <i className="fe fe-plus" />
                          <StyledTypography className="mx-1" component="span">
                            {Translate("common:add")}
                          </StyledTypography>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <ListView
                  columns={getOwnerColumns(
                    CustomSrCellRender,
                    CustomActionCellRender
                  )}
                  rows={ownerDataFetched}
                  autoHeight
                  sortingMode="server"
                  sortBy={sortBy}
                  onPaginationChange={setCurrentPageNo}
                  currentPage={currentPageNo}
                  showShimmer={loadingOwnerData}
                  showLoader={loading || loadingOwnerData}
                  totalPageCount={lastPage.current}
                  onSortModelChange={(data) =>
                    data?.length && getSort(data[0]?.field)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <CompanyListing
          handleModal={(value) => {
            setCurrentPageNo(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompanyList}
        />
      </StyledCard>
    </Grid>
  );
};

export default OwnerListing;
