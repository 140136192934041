import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useAddVendorDataMutation } from "../../../request/VendorsSuppliers";
import Translate from "../../../translation/utils/translateFunction";
import VendorForm from "../Components/VendorForm";
import { InitialValues } from "../Components/constants";
import {
  AddVendorSchema,
  validateAddressFields,
} from "../Components/validators";
import AlertPermission from "../../AlertPermission";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { colors } from "../../../common/Theme/Colors";
const textStyle = {
  fontSize: "1.1rem",
  color: colors.black,
};
const AddVendor = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const [reportTo, setReportTo] = useState("");
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [addMoreVendor, setAddMoreVendor] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedCompanyLabel, setSelectedCompanyLabel] = useState();

  const [
    addVendorApi,
    {
      data: vendorData,
      isSuccess: isVendorSuccess,
      isLoading: isVendorLoading,
      isError: isVendorError,
      error: vendorError,
    },
  ] = useAddVendorDataMutation();
  const openModal = () => setShowModal(true);
  const hideModal = () => setShowModal(false);
  useEffect(() => {
    if (isVendorError && vendorError) {
      const vendorErrorObj = vendorError?.data;

      if (!vendorErrorObj) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        vendorErrorObj?.error?.length
          ? vendorErrorObj.error.map((errorItem) => {
              if (!InitialValues.hasOwnProperty(errorItem?.param)) {
                const tempParam = validateAddressFields(errorItem?.param);

                if (tempParam) {
                  return formikRef.current.setFieldError(
                    tempParam,
                    errorItem?.msg
                  );
                }

                return showToast({ message: errorItem?.msg, type: "error" });
              }

              return formikRef.current.setFieldError(
                errorItem?.param,
                errorItem?.msg
              );
            })
          : showToast({
              message:
                vendorErrorObj?.message ||
                Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }

    if (isVendorSuccess && vendorData) {
      if (!vendorData?.success) {
        showToast({ message: vendorData?.message, type: "error" });
      } else {
        showToast({ message: vendorData?.message, type: "success" });
      }

      formikRef.current.resetForm();
      InitialValues.dob = "";
      InitialValues.gender = "MALE";
      InitialValues.alternateMobile = "962";
      InitialValues.mobile = "962";
      setFilesAdded([]);
      setImages([]);
      setReportTo("");

      if (!addMoreVendor) {
        navigate("/vendors");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorData, vendorError]);

  const onSubmit = (formValues) => {
    hideModal();
    const {
      companyId,
      countryCode,
      alternateMobile,
      alternateMobileCountryCode,
    } = formValues;

    const updatedReportTo = { ...reportTo };

    if (updatedReportTo?.id) {
      delete updatedReportTo["label"];
      delete updatedReportTo["value"];
    }

    let reqObj = {
      companyId: [companyId],
      companyName: formValues?.companyName,
      name: formValues.name,
      gender: formValues?.gender.toUpperCase(),
      designation: formValues.designation,
      department: formValues.department,
      reportTo: updatedReportTo,
      countryCode: countryCode ? countryCode : "962",
      mobile: formValues?.mobile?.split("-")[1],
      mobileShortCode: formValues.mobileShortCode,
      companyExtension: formValues.companyExtension,
      companyNumber: formValues.companyNumber,
      email: formValues.email,
      workAddress: {
        latitude: formValues.latitude,
        longitude: formValues.longitude,
        latlong: {
          latitude: formValues.latitude,
          longitude: formValues.longitude,
        },
        flat: formValues.flat,
        address: formValues.workAddress,
        country: formValues.country,
        state: formValues.state,
        countryISO: "IN",
        city: formValues.city,
        zipCode: formValues.zipCode,
      },
      emailVerified: "YES",
      mobileVerified: "YES",
      primary: "NO",
      system: "NO",
      role: {
        type: "VENDOR",
      },
      // profileUrl: "",
    };

    // let b64Array;

    // if (filesAdded.length) {
    //   if (filesAdded[0]?.b64?.length) {
    //     b64Array = filesAdded[0].b64;
    //     reqObj["webProfileUrl"] = b64Array;
    //   } else {
    //     reqObj["profileUrl"] = state?.profileUrl;
    //   }
    // }

    const b64Object = {
      ...filesAdded.map((ele) => ({
        url: ele?.b64,
        type: ele?.type,
        profileFileName: ele?.name,
        profileFileExt: ele?.name.split(".").pop(),
      }))[0],
    };

    reqObj = {
      ...reqObj,
      webProfileUrl: b64Object,
    };
    if (
      formValues?.alternateMobile?.length > 0 &&
      formValues?.alternateMobile?.includes("-")
    ) {
      reqObj = {
        ...reqObj,

        alternateMobile: formValues?.alternateMobile?.split("-")[1],

        alternateMobileCountryCode: formValues?.alternateMobileCountryCode,
        alternateMobileShortCode: formValues.alternateMobileShortCode,
      };
    } else {
      reqObj = {
        ...reqObj,

        alternateMobile: "",

        alternateMobileCountryCode: "",
        alternateMobileShortCode: "",
      };
    }

    addVendorApi(reqObj);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={showModal ? onSubmit : openModal}
      validationSchema={AddVendorSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <>
            <VendorForm
              formikRef={formikRef}
              isAdd
              isLoading={isVendorLoading}
              values={values}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              reportTo={reportTo}
              setReportTo={setReportTo}
              filesAdded={filesAdded}
              setFilesAdded={setFilesAdded}
              images={images}
              setImages={setImages}
              setAddMoreVendor={setAddMoreVendor}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              setSelectedCompanyLabel={setSelectedCompanyLabel}
            />
            {showModal && (
              <AlertPermission
                show={showModal}
                title={Translate("addManager:confirmation")}
                okTitle={Translate("common:confirm")}
                subTitle={
                  <StyledTypography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 500,
                      ...textStyle,
                    }}
                  >
                    {Translate("addManager:alertText1")}
                    {selectedCompanyLabel && (
                      <StyledTypography
                        variant="span"
                        sx={{
                          fontWeight: 600,
                          ...textStyle,
                        }}
                      >
                        {selectedCompanyLabel}
                      </StyledTypography>
                    )}
                    {Translate("addManager:alertText2")}
                    {values.name && (
                      <StyledTypography
                        variant="span"
                        sx={{
                          fontWeight: 600,
                          ...textStyle,
                        }}
                      >
                        {values.name}
                      </StyledTypography>
                    )}
                    {Translate("addManager:alertText3")}
                  </StyledTypography>
                }
                onOk={handleSubmit}
                onCanel={hideModal}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default AddVendor;
