import * as yup from "yup";
import Translate from "../../../../translation/utils/translateFunction";
import { validateMobileSchema } from "../../../../common/Utils/commonFunction";

export const AddProcurementOfficerSchema = () => {
  const schema = yup.object().shape({
    name: yup.string().trim().required(Translate("addpo:nameError")),
    dob: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:dobError")),
    designation: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:designationError")),
    department: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:departmentError")),
    ...validateMobileSchema(
      "mobile",
      Translate("addPersonalAssistant:mobileError")
    ),
    email: yup
      .string()
      .email(Translate("addPersonalAssistant:workEmailErrorInvalid"))
      .trim()
      .required(Translate("addPersonalAssistant:workEmailError")),
    resAddress: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:addressError")),
    workAddress: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:workAddressError")),
    resCountry: yup
      .string()
      .trim()
      .required(Translate("addManager:countryError")),
    resState: yup.string().trim().required(Translate("addManager:stateError")),
    workCountry: yup
      .string()
      .trim()
      .required(Translate("addManager:countryError")),
    workState: yup.string().trim().required(Translate("addManager:stateError")),
  });

  return schema;
};

export const validateAddressFields = (params) => {
  let param = "";

  switch (params) {
    case "residenceAddress.address":
      param = "resAddress";
      break;
    case "residenceAddress.country":
      param = "resCountry";
      break;
    case "residenceAddress.state":
      param = "resState";
      break;
    case "residenceAddress.zipCode":
      param = "resZipCode";
      break;
    case "workAddress.address":
      param = "workAddress";
      break;
    case "workAddress.country":
      param = "workCountry";
      break;
    case "workAddress.state":
      param = "workState";
      break;
    case "workAddress.zipCode":
      param = "workZipCode";
      break;
    default:
      break;
  }

  return param;
};
