import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import NotificationTitleBody from "./common/Notification";
import { colors } from "./common/Theme/Colors";
import { StyledTypography } from "./common/Utils/StyledComponents";
import { showToast } from "./common/Utils/ToastMessage";
import AlertPermission from "./components/AlertPermission";
import { Ability } from "./components/Shared/Ability";
import UnAuthRoutes from "./components/Shared/UnAuthRoutes";
// import { _getToken, onMessageListener } from "./firebase"; //enable after webview done
import { useUpdateFcmTokenMutation } from "./request/Authentication";
import {
  selectCompanyAction,
  setFcmTokenAction,
  setNotificationBody,
  setNotificationLogout,
  userDataAction,
  userTypeAction,
} from "./store/Reducer";
import Translate from "./translation/utils/translateFunction";

const App = () => {
  const {
    isDarkMode,
    isBoxLayout,
    isDarkSidebar,
    isIconColor,
    isGradientColor,
    isRtl,
    isFont,
    userData,
    notificationLogout,
    notificationBody,
    fcmToken: fcmTokenStorage,
  } = useSelector((state) => state.formanagement);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isTokenFound, setTokenFound] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [fcmToken, setFcmToken] = useState("");
  const [showLogoutModal, setLogoutModal] = useState(
    notificationLogout == true ? true : false
  );

  const logOut = async () => {
    localStorage.removeItem("userData");
    dispatch(userDataAction(undefined));
    dispatch(userTypeAction(undefined));
    dispatch(setNotificationBody(undefined));
    dispatch(selectCompanyAction([]));
    dispatch(setFcmTokenAction(""));
  };
  const [updateFmcToken, { data: serverResponse, isSuccess }] =
    useUpdateFcmTokenMutation();
  useEffect(() => {
    //enable after webview done
    location.pathname !== "/taskdependancy" &&
      import("./firebase").then((module) => {
        module._getToken(setTokenFound, setFcmToken);
      });
  }, [fcmTokenStorage]);
  useEffect(() => {
    if (location.pathname !== "/taskdependancy" && window.innerWidth <= 767) {
      setIsMobileView(true);
      navigate("/");
    } else {
      setIsMobileView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  useEffect(() => {
    if (fcmToken?.length) {
      !fcmTokenStorage && updateFmcToken({ fcmToken: fcmToken });
      dispatch(setFcmTokenAction(fcmToken));
    }
    if (
      location.pathname !== "/taskdependancy" &&
      Notification?.permission !== "granted"
    ) {
      fcmTokenStorage && updateFmcToken({ fcmToken: fcmToken });
      dispatch(setFcmTokenAction(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fcmToken, fcmTokenStorage]);
  useEffect(() => {
    if (serverResponse?.success && isSuccess) {
      showToast({ message: serverResponse?.message, type: "success" });
    }
  }, [isSuccess]);

  const [notification, setNotification] = useState(null);
  location.pathname !== "/taskdependancy" &&
    import("./firebase").then((module) => {
      module
        .onMessageListener() //enable after webview done
        .then((payload) => {
          // console.log("notification body", payload);
          // setShow(true);
          setNotification(payload?.data);
        })
        .catch((err) => console.log("failed: ", err));
    });

  useEffect(() => {
    if (notification) {
      showToast({
        message: (
          <NotificationTitleBody
            title={notification?.title}
            body={notification?.body}
            detail={notification?.detail}
            onNotificationClick={setLogoutModal}
          />
        ),
        type: "success",
      });
    }
  }, [notification]);
  return (
    <div //enable after webview done
      className={`${isDarkMode ? "dark-mode" : ""}${
        isDarkSidebar ? "sidebar_dark" : ""
      } ${isIconColor ? "iconcolor" : ""} ${
        isGradientColor ? "gradient" : ""
      } ${isRtl ? "rtl" : ""} ${isFont ? isFont : ""}${
        isBoxLayout ? "boxlayout" : ""
      }`}
    >
      {userData && userData?.accessToken ? <Ability /> : <UnAuthRoutes />}
      <ToastContainer hideProgressBar rtl={isRtl} autoClose={3000} />

      <Modal
        show={isMobileView}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onBackdropClick={() => {}}
        scrollable={false}
        className="col-span-3 col-md-12"
      >
        <Modal.Body>
          <StyledTypography variant="h6" color={colors.primary} p={2}>
            {Translate("common:ScreenViewalertMsg")}
          </StyledTypography>
        </Modal.Body>
      </Modal>
      {showLogoutModal && (
        <AlertPermission
          show={showLogoutModal}
          onOk={() => {
            dispatch(setNotificationLogout(false));
            logOut();
            setLogoutModal(false);
          }}
          hideCancel
          title={JSON.parse(notificationBody)?.title}
          subTitle={JSON.parse(notificationBody)?.body}
        />
      )}
    </div>
  );
};

export default App;
