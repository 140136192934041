import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HrOfficerForm from "../Components/HrOfficerForm";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { InitialValues } from "../Components/constants";
import { useEditHrOfficerMutation } from "../../../../request/Staff/HrOfficer";
import { Formik } from "formik";
import Translate from "../../../../translation/utils/translateFunction";
import { AddHrOfficerSchema } from "../Components/validators";
import { formatDateYYYYMMDD } from "../../../../common/Utils/DateFormater";
import moment from "moment";
import { getAddressError } from "../../../../common/Utils/commonFunction";

const EditHrOfficer = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [reportTo, setReportTo] = useState({});
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState();
  const initialImage = [state?.profileImageDetails];

  const [
    editHrOfficerApi,
    {
      data: hrOfficerData,
      isSuccess: isEditSuccess,
      isLoading: isEditLoading,
      isError: isEditError,
      error: errorEdit,
    },
  ] = useEditHrOfficerMutation();

  useEffect(() => {
    if (state) {
      formikRef.current.setFieldValue(
        "companyId",
        state?.company?.map((item) => item?._id)
      );
      formikRef.current.setFieldValue("name", state?.name);
      formikRef.current.setFieldValue("staff", state?.role?.type);
      setSelectedStaff(state?.role?.type);
      formikRef.current.setFieldValue("dob", new Date(state?.dob));
      formikRef.current.setFieldValue("designation", state?.designation);
      formikRef.current.setFieldValue("department", state?.department);
      setReportTo(state?.reportTo);
      formikRef.current.setFieldValue(
        "mobile",
        `${state?.login?.countryCode}-${state?.login?.mobile}`
      );
      formikRef.current.setFieldValue(
        "mobileShortCode",
        state?.login.mobileShortCode
      );
      formikRef.current.setFieldValue("countryCode", state?.login.countryCode);
      if (state?.alternateMobile) {
        formikRef.current.setFieldValue(
          "alternateMobile",

          `${state?.alternateMobileCountryCode}-${state?.alternateMobile}`
        );
      }
      formikRef.current.setFieldValue(
        "alternateMobileCountryCode",
        state?.alternateMobileCountryCode
          ? state?.alternateMobileCountryCode
          : "962"
      );
      formikRef.current.setFieldValue(
        "alternateMobileShortCode",
        state?.alternateMobileShortCode ? state?.alternateMobileShortCode : "JO"
      );
      formikRef.current.setFieldValue("companyNumber", state?.companyNumber);
      formikRef.current.setFieldValue(
        "companyExtension",
        state?.companyExtension
      );
      formikRef.current.setFieldValue("email", state?.login.email);
      formikRef.current.setFieldValue("resFlat", state?.residenceAddress.flat);
      formikRef.current.setFieldValue(
        "resState",
        state?.residenceAddress.state
      );
      formikRef.current.setFieldValue(
        "resCountry",
        state?.residenceAddress.country
      );
      formikRef.current.setFieldValue("resCity", state?.residenceAddress.city);
      formikRef.current.setFieldValue(
        "resZipCode",
        state?.residenceAddress.zipCode
      );
      formikRef.current.setFieldValue(
        "resAddress",
        state?.residenceAddress.address
      );
      formikRef.current.setFieldValue(
        "resLat",
        state?.residenceAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "resLng",
        state?.residenceAddress.latlong.longitude
      );
      formikRef.current.setFieldValue("workFlat", state?.workAddress.flat);
      formikRef.current.setFieldValue("workState", state?.workAddress.state);
      formikRef.current.setFieldValue(
        "workCountry",
        state?.workAddress.country
      );
      formikRef.current.setFieldValue("workCity", state?.workAddress.city);
      formikRef.current.setFieldValue(
        "workZipCode",
        state?.workAddress.zipCode
      );
      formikRef.current.setFieldValue(
        "workAddress",
        state?.workAddress.address
      );
      formikRef.current.setFieldValue(
        "workLat",
        state?.workAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "workLng",
        state?.workAddress.latlong.longitude
      );
      formikRef.current.setFieldValue("gender", state?.gender);

      if (
        state?.profileImageDetails &&
        Object.keys(state?.profileImageDetails).includes("url")
      ) {
        setFilesAdded([state?.profileImageDetails]);
        setImages([state?.profileImageDetails?.url]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (errorEdit && isEditError) {
      const hrOfficerError = errorEdit?.data;

      if (!hrOfficerError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        hrOfficerError?.error?.length
          ? hrOfficerError.error.map((errorItem) => {
              const isPresent = getAddressError(
                errorItem.param,
                formikRef.current?.setFieldError
              );
              !isPresent &&
                formikRef.current.setFieldError(errorItem.param, errorItem.msg);
            })
          : showToast({
              message:
                hrOfficerError?.message ||
                Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }

    if (isEditSuccess && hrOfficerData) {
      showToast({
        message: hrOfficerData.message,
        type: "success",
      });

      formikRef.current.resetForm();
      InitialValues.dob = "";
      setFilesAdded([]);
      setImages([]);
      navigate("/staff/hrOfficer");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hrOfficerData, errorEdit, isEditError]);

  const onSubmit = (formValues) => {
    const { companyId, dob, countryCode } = formValues;

    let reqObj = {
      companyId,
      name: formValues.name,
      gender: formValues?.gender.toUpperCase(),
      dob:
        moment(dob).creationData().format === "YYYY-MM-DD"
          ? dob
          : formatDateYYYYMMDD(dob),
      designation: formValues.designation,
      department: formValues.department,
      reportTo,
      countryCode: countryCode ? countryCode : "962",
      mobile: formValues?.mobile?.split("-")[1],
      mobileShortCode: formValues.mobileShortCode,
      companyExtension: formValues.companyExtension,
      companyNumber: formValues.companyNumber,
      email: formValues.email,
      residenceAddress: {
        latitude: formValues.resLat,
        longitude: formValues.resLng,
        latlong: {
          latitude: formValues.resLat,
          longitude: formValues.resLng,
        },
        flat: formValues.resFlat,
        address: formValues.resAddress,
        country: formValues.resCountry,
        state: formValues.resState,
        city: formValues.resCity,
        zipCode: formValues.resZipCode,
      },
      workAddress: {
        latitude: formValues.workLat,
        longitude: formValues.workLng,
        latlong: {
          latitude: formValues.workLat,
          longitude: formValues.workLng,
        },
        flat: formValues.workFlat,
        address: formValues.workAddress,
        country: formValues.workCountry,
        state: formValues.workState,
        countryISO: "IN",
        city: formValues.workCity,
        zipCode: formValues.workZipCode,
      },
      emailVerified: "YES",
      mobileVerified: "YES",
      primary: "NO",
      system: "NO",
      role: {
        type: selectedStaff,
      },
    };
    if (
      formValues?.alternateMobile?.length > 0 &&
      formValues?.alternateMobile?.includes("-")
    ) {
      reqObj = {
        ...reqObj,
        alternateMobile: formValues?.alternateMobile?.split("-")[1],
        alternateMobileCountryCode: formValues?.alternateMobileCountryCode,
        alternateMobileShortCode: formValues?.alternateMobileShortCode,
      };
    } else {
      reqObj = {
        ...reqObj,
        alternateMobile: "",
        alternateMobileCountryCode: "",
        alternateMobileShortCode: "",
      };
    }
    if (filesAdded.length > 0) {
      if (filesAdded.every((value) => initialImage?.includes(value))) {
        reqObj = {
          ...reqObj,
          profileUrl: state?.profileUrl,
          profileImageDetails: state?.profileImageDetails,
        };
      } else {
        const b64Object = {
          ...filesAdded.map((ele) => ({
            url: ele?.b64,
            type: ele?.type,
            profileFileName: ele?.name,
            profileFileExt: ele?.name.split(".").pop(),
          }))[0],
        };

        reqObj = {
          ...reqObj,
          webProfileUrl: b64Object,
        };
      }
    } else {
      reqObj = {
        ...reqObj,
      };
    }

    editHrOfficerApi({
      body: reqObj,
      id: state?._id,
    });
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddHrOfficerSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <HrOfficerForm
            isEdit
            isLoading={isEditLoading}
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            hrOfficerData={state}
            handleSubmit={handleSubmit}
            setReportTo={setReportTo}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
            images={images}
            setImages={setImages}
            selectedStaff={selectedStaff}
            setSelectedStaff={setSelectedStaff}
          />
        );
      }}
    </Formik>
  );
};

export default EditHrOfficer;
