import dayjs from "dayjs";
import { Formik } from "formik";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTimeFormats } from "../../common/Utils/DateFormater";
import { showToast } from "../../common/Utils/ToastMessage";
import {
  convertStringForHRManager,
  getDayJs,
  getMoment,
  getNameAndExtFromFile,
  validateDuplicateFiles,
} from "../../common/Utils/commonFunction/index.js";
import {
  useAddTaskMutation,
  useAssignTaskFromRejectedMutation,
  useEditTaskCollectionMutation,
  useLazyGetAssignToCollectionQuery,
  useLazyGetRelatedTaskCollectionQuery,
  useLazyGetReportToCollectionQuery,
  useReAllocateTaskToAssigneeMutation,
} from "../../request/Task";
import { useLazyGetCompanyListQuery } from "../../request/company";
import Translate from "../../translation/utils/translateFunction";
import Dropzone from "../CommonFileUpload/Dropzone";
import FormikDatePicker from "../FormikForm/component/FormikDatePicker.js";
import { FormikDropdownPicker } from "../FormikForm/component/FormikDropDownPicker";
import FormikSearchableDropDown from "../FormikForm/component/FormikSearchableDropDown";
import { FormikTextField } from "../FormikForm/component/FormikTextField";
import CommonImage from "../ImageGallery/CommonImage";
import { tabType as messageModuleType } from "../Message/Components/constants";
import SubtaskPermissionModal from "../Task/Subtask/AddSubtask/components/PermissionModal";
import PageHeader from "../common/pageHeader";
import Loader from "../staff/Owner/LoadingComp";
import Priority from "./Priority";
import { InitialValues, defaultProjectOptions } from "./constants";
import { addTaskSchema } from "./utils";
import {
  handleEventKeySubmit,
  stopEventPropogation,
  getDropdownOptions,
} from "../../common/Utils/commonFunction/index.js";
import { CustomUserItem } from "../common/CustomCellRender.js";
import { userDefaultDropdownItem } from "../../common/Utils/ApiConstants/index.js";
import { userTypes } from "../../common/users/userTypes.js";
import { useGetProjectDropdownListMutation } from "../../request/Project/index.js";

const AddTask = () => {
  const { userData, validations } = useSelector((state) => state.formanagement);
  const formikRef = useRef(null);
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [relatedTaskData, setRelatedTaskData] = useState([]);
  const [assignTo, setAssignTo] = useState("");
  const [assignToOptionList, setAssignToOptionList] = useState([]);
  const [reportTo, setReportTo] = useState("");
  const [reportToOptionList, setReportToOptionList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [searchTextAssign, setSearchTextAssign] = useState("");
  const [searchTextReport, setSearchTextReport] = useState("");
  const [startDateTZ, setStartDateTZ] = useState("");
  const [dueDateTZ, setDueDateTZ] = useState("");
  const { state } = useLocation();
  const [startDateForDue, setStartDateForDue] = useState(false);
  const [addSubtask, setAddSubtask] = useState(false);
  const [addSubtaskLoading, setAddSubtaskLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [projectOptions, setProjectOptions] = useState([defaultProjectOptions]);
  const navigate = useNavigate();
  const [initialEdit, setInitialEdit] = useState(true);

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg", ".pdf", ".xls", ".xlsx"],
  };

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();

  const [
    getAssignTo,
    {
      data: assignToData,
      isLoading: loadingAssigneToData,
      isSuccess: issuccessAssignToList,
    },
  ] = useLazyGetAssignToCollectionQuery();

  const [getReportTo, { data: reportToData, isLoading: loadingReportToData }] =
    useLazyGetReportToCollectionQuery();

  const [
    getRelatedTaskList,
    {
      data: relatedTaskList,
      isLoading: loadingRelatedTask,
      isFetching: fetchRelatedTask,
    },
  ] = useLazyGetRelatedTaskCollectionQuery();

  const [
    addTask,
    {
      data: addTaskData,
      isLoading: addTaskLoading,
      isSuccess: addTaskSuccess,
      isError: addTaskIsError,
      error: addTaskError,
    },
  ] = useAddTaskMutation();

  const [
    editTask,
    {
      data: editTaskData,
      isLoading: editTaskLoading,
      isSuccess: editTaskSuccess,
      isError: editTaskIsError,
      error: editTaskError,
    },
  ] = useEditTaskCollectionMutation();

  const [
    reAllocateTaskToAssignee,
    {
      data: reAllocatedTaskData,
      isLoading: isReAllocateLoading,
      isSuccess: isReAllocateSuccess,
      isError: isReAllocateError,
      error: reAllocateError,
    },
  ] = useReAllocateTaskToAssigneeMutation();

  const [
    reAssignTask,
    {
      data: reAssignedTaskData,
      isLoading: isReAssignTaskLoading,
      isSuccess: isReAssignTaskSuccess,
      isError: isReAssignTaskError,
      error: reAssignTaskError,
    },
  ] = useAssignTaskFromRejectedMutation();

  const [
    getProjectList,
    {
      data: projectData,
      isLoading: isProjectLoading,
      isSuccess: isProjectSuccess,
      isError: isProjectError,
      error: projectError,
    },
  ] = useGetProjectDropdownListMutation();

  let reg2 = /[a-zA-Z]/;
  /********************************************************************** Add-Image UseEffect ****************************************************/
  const addFiles = (file) => {
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/gif" ||
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
        ? URL.createObjectURL(file)
        : null;

    const checkDuplicates = validateDuplicateFiles(file, filesAdded);

    if (checkDuplicates?.isDuplicate) {
      showToast({
        message: `File ${checkDuplicates?.fileName} ${Translate(
          "common:duplicateFileError"
        )}`,
        type: "error",
      });
      return;
    }

    if (url) {
      file.preview = url;
    }

    if (filesAdded.length <= 10) {
      filesAdded.push(file);
      setFilesAdded([...filesAdded]);
    } else {
      filesAdded.pop(file);
    }
  };

  const convertDueDateUTC = (startDate, dueDate) => {
    let startDatePostFix = startDate.toString().split("T")[0];
    let dueDatePostFix = dueDate.toString().split("T")[1];
    let finalDueDate = startDatePostFix + "T" + dueDatePostFix;
    return finalDueDate;
  };

  useEffect(() => {
    getCompanyList();
    if (state?.edit) {
      const {
        company,
        startDate,
        startTime,
        dueDate,
        dueTime,
        priority,
        isCritical,
        title,
        type,
        description,
        attachment,
        dueDateObject,
        startDateObject,
        relatedTaskName,
        projectId,
        projectTitle,
        projectDetails,
      } = state?.taskDetail;

      formikRef.current.setFieldValue("relatedTaskName", relatedTaskName?._id);
      const startTimeArr = getMoment().toDate().toString().split(" ");
      startTimeArr.splice(4, 1, startTime);
      const dueTimeArr = getMoment().toDate().toString().split(" ");
      dueTimeArr.splice(4, 1, dueTime);
      const attachmentArray = [];
      attachment.map((attachmentItem) => {
        const { url, type, taskFileName, taskFileExt } = attachmentItem;
        const obj = {
          name: attachmentItem?.taskFileName,
          path: taskFileName,
          type,
          url,
          taskFileExt,
          taskFileName,
        };
        attachmentArray.push(obj);
      });

      setFilesAdded(attachmentArray);
      setImages(attachment?.map((item) => item?.url));
      formikRef.current.setFieldValue("startDate", new Date(startDate));
      formikRef.current.setFieldValue(
        "startTime",
        dayjs(startTimeArr.join(" ").trim())
      );
      setStartDateTZ(startDateObject);
      setDueDateTZ(dueDateObject);
      formikRef.current.setFieldValue("dueDate", new Date(dueDate));
      formikRef.current.setFieldValue(
        "dueTime",
        dayjs(dueTimeArr.join(" ").trim())
      );
      formikRef.current.setFieldValue(
        "company",
        state?.edit ? company.name : company._id
      );
      formikRef.current.setFieldValue("markAsCritical", isCritical);
      formikRef.current.setFieldValue("priority", priority);
      formikRef.current.setFieldValue("title", title);
      formikRef.current.setFieldValue(
        "projectId",
        projectDetails ? projectDetails?._id : "null"
      );
      formikRef.current.setFieldValue("projectTitle", projectTitle);
      formikRef.current.setFieldValue("description", description);

      setSelectedCompany(company._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.isMyDocument) {
      const { title, description, attachment, company } = state;
      formikRef.current.setFieldValue("title", title);
      formikRef.current.setFieldValue("description", description);
      formikRef.current.setFieldValue("company", company);
      formikRef.current.setFieldValue("startTime", getDayJs());
      formikRef.current.setFieldValue("dueTime", getDayJs());
      setSelectedCompany(company);
      resetOnCompanyChange();
      if (attachment?.url) {
        const { url, type, documentFileName, documentFileExt } = attachment;
        const obj = {
          path: documentFileName,
          type,
          url,
          taskFileExt: documentFileExt,
        };
        setFilesAdded([...filesAdded, obj]);
        setImages([url]);
      }
    }

    if (state?.isEmailCreateTask) {
      const { title, description, attachmentsDetails } = state;
      formikRef.current.setFieldValue("title", title);
      formikRef.current.setFieldValue("description", description);

      const attachmentArray = [];
      attachmentsDetails &&
        attachmentsDetails.map((attachmentItem) => {
          const { url, type } = attachmentItem;
          const obj = {
            name: attachmentItem?.name,
            path: attachmentItem?.name,
            type,
            url,
          };
          attachmentArray.push(obj);
        });
      setFilesAdded(attachmentArray);
      setImages(attachmentsDetails?.map((item) => item?.url));
    }

    if (state?.isMessage) {
      formikRef.current.setFieldValue("title", state?.title);
      formikRef.current.setFieldValue("description", state?.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );
      setImages(newImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAdded]);

  const resetFormikForm = () => {
    formikRef.current.resetForm();
    formikRef.current.setFieldValue("title", "");
    setFilesAdded([]);
    setImages([]);
    resetOnCompanyChange();
    formikRef.current.setFieldValue("markAsCritical", false);

    if (state?.isMyDocument) return navigate(-1);

    if (state?.edit) return navigate(handleEditNavigation());

    return navigate("/manageTask");
  };

  const handleEditNavigation = () => {
    if (state?.parentState?.navigateFrom) {
      return state?.parentState?.navigateFrom;
    } else {
      return "/manageTask";
    }
  };

  /********************************************************************** Add-Task UseEffect ****************************************************/

  useEffect(() => {
    if (
      (addTaskIsError && addTaskError) ||
      (editTaskError && editTaskIsError)
    ) {
      setAddSubtaskLoading(false);
      const taskError = state?.edit ? editTaskError : addTaskError;
      taskError.data.error &&
        taskError.data.error.length > 0 &&
        taskError.data.error.map((errorItem) =>
          errorItem?.param === "startDateObject" ||
          errorItem?.param === "dueDateObject"
            ? showToast({ message: errorItem?.msg, type: "error" })
            : formikRef.current.setFieldError(errorItem.param, errorItem.msg)
        );
    }

    if ((addTaskSuccess && addTaskData) || (editTaskData && editTaskSuccess)) {
      showToast({
        type: "success",
        message: state?.edit ? editTaskData.message : addTaskData.message,
      });

      if (addSubtaskLoading) {
        navigate("/subtask/add", {
          state: {
            isAddTask: true,
            data: addTaskData?.data,
            projectData: selectedProject,
          },
        });
      } else {
        resetFormikForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTaskError, addTaskData, editTaskData, editTaskError]);

  /**********************************************************************ReAllocate Task UseEffect ****************************************************/
  useEffect(() => {
    if (reAllocateError && isReAllocateError) {
      showToast({
        type: "error",
        message:
          JSON.stringify(reAllocateError?.data?.message) ||
          Translate("common:unknownServerError"),
      });
    }

    if (reAllocatedTaskData && isReAllocateSuccess) {
      showToast({
        type: "success",
        message: reAllocatedTaskData?.message,
      });
      resetFormikForm();
    }
  }, [
    reAllocatedTaskData,
    reAllocateError,
    isReAllocateSuccess,
    isReAllocateError,
  ]);

  /**********************************************************************ReAssign Task UseEffect ****************************************************/
  useEffect(() => {
    if (reAssignTaskError && isReAssignTaskError) {
      showToast({
        type: "error",
        message:
          JSON.stringify(reAssignTaskError?.data?.message) ||
          Translate("common:unknownServerError"),
      });
    }

    if (reAssignedTaskData && isReAssignTaskSuccess) {
      showToast({
        type: "success",
        message: reAssignedTaskData?.message,
      });
      resetFormikForm();
    }
  }, [
    reAssignedTaskData,
    reAssignTaskError,
    isReAssignTaskSuccess,
    isReAssignTaskError,
  ]);

  /**********************************************************************SelectCompany UseEffect ****************************************************/
  useEffect(() => {
    if (companyResponse) {
      const companyData = getDropdownOptions(companyResponse, "name", "_id");
      setAllCompanyData(companyData);
    }
    setAssignAndReportToMe(true);
    return () => {
      setAllCompanyData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  const setAssignAndReportToMe = () => {
    const assignToObj = {
      id: userData?._id,
      value: userDefaultDropdownItem.ASSIGN_TO_ME,
      label: (
        <CustomUserItem
          userName={userData?.name}
          title={userDefaultDropdownItem.ASSIGN_TO_ME}
          profileUrl={userData?.profileUrl}
          customClass={"addtaskUserEllipsisTitle"}
        />
      ),
    };

    let userReport = {};

    if (userData?.role?.type === userTypes.persoalAssistant) {
      const { reportToUser } = userData;

      userReport = {
        ...reportToUser,
        id: reportToUser?._id,
        role: reportToUser?.role?.type,
        email: reportToUser?.login?.email,
      };
    } else {
      userReport = {
        id: userData?._id,
        name: userData?.name,
        role: convertStringForHRManager(userData?.role?.type),
        profileUrl: userData?.profileUrl,
        email: userData?.login?.email,
      };
    }

    const reportToObj = {
      id: userReport?.id,
      value: userReport?.name,
      label: (
        <CustomUserItem
          userName={userReport?.name}
          title={userReport?.name}
          role={userReport?.role}
          profileUrl={userReport?.profileUrl}
          email={userReport?.email}
          customClass={"addtaskUserEllipsisTitle"}
        />
      ),
    };
    setReportTo(reportToObj);
    formikRef.current.setFieldValue("reportTo", reportToObj.id);
    setAssignToOptionList([assignToObj]);
    setReportToOptionList([reportToObj]);
  };

  /**********************************************************************RelatedTask UseEffect ****************************************************/
  useEffect(() => {
    if (selectedCompany) {
      const relatedTaskAPIQuery = {
        companyId: selectedCompany,
        type: "relatedtask",
        taskId: state?.taskDetail?._id,
      };

      getRelatedTaskList(relatedTaskAPIQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    if (relatedTaskList?.length) {
      const relatedTaskOptions = getDropdownOptions(
        relatedTaskList,
        "title",
        "_id"
      );
      setRelatedTaskData(relatedTaskOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedTaskList]);

  /**********************************************************************Assign To Report To UseEffect ****************************************************/
  useEffect(() => {
    if (assignToData && issuccessAssignToList) {
      setSearchableData(assignToData, true);
    }
    if (reportToData) {
      setSearchableData(reportToData, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignToData, issuccessAssignToList, reportToData]);

  useEffect(() => {
    const assigneeObj = {
      searchText: searchTextAssign,
      companyId: selectedCompany,
    };
    const reportObj = {
      searchText: searchTextReport,
      companyId: selectedCompany,
    };
    if (selectedCompany) {
      getAssignTo(assigneeObj);
      getReportTo(reportObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, searchTextAssign, searchTextReport]);

  /**********************************************************************Searchable Function****************************************************/
  const setSearchableData = (res, isAssignee = false) => {
    const assignToList = [];
    const reportToList = [];
    const assignToObj = {
      id: userData?._id,
      value: isAssignee ? userDefaultDropdownItem.ASSIGN_TO_ME : userData?.name,
      label: isAssignee ? (
        <CustomUserItem
          userName={userData?.name}
          title={userDefaultDropdownItem.ASSIGN_TO_ME}
          profileUrl={userData?.profileUrl}
          customClass={"addtaskUserEllipsisTitle"}
        />
      ) : (
        <CustomUserItem
          userName={userData?.name}
          title={userData?.name}
          role={convertStringForHRManager(userData?.role?.type)}
          profileUrl={userData?.profileUrl}
          email={userData?.login?.email}
          customClass={"addtaskUserEllipsisTitle"}
        />
      ),
    };

    isAssignee
      ? assignToList.push(assignToObj)
      : reportToList.push(assignToObj);
    res.length > 0 &&
      res.map((assigneeItem) => {
        const { name, _id, profileUrl, email, role } = assigneeItem;
        const obj = {
          id: _id,
          value: name,
          label: (
            <CustomUserItem
              userName={name}
              title={name}
              role={role?.type}
              profileUrl={profileUrl}
              email={email}
              customClass={"addtaskUserEllipsisTitle"}
            />
          ),
        };
        isAssignee ? assignToList.push(obj) : reportToList.push(obj);
      });
    isAssignee && setAssignToOptionList([...assignToList]);

    if (state?.edit && initialEdit) {
      const { assignee, reporter } = state?.taskDetail;

      const assigneeOption = (isAssignee ? assignToList : reportToList)?.find(
        (item) =>
          isAssignee ? item?.id === assignee?._id : item?.id === reporter?._id
      );

      if (assigneeOption) {
        if (isAssignee) {
          formikRef.current.setFieldValue("assignTo", assignee?._id);

          setAssignTo({ ...assigneeOption });
        } else {
          formikRef.current.setFieldValue("reportTo", reporter?._id);
          setReportTo({ ...assigneeOption });
        }
      }
      setInitialEdit(false);
    }
    // : setReportToOptionList(reportToList);
  };
  /********************************************************************** RemoveFile Function ****************************************************/
  const removeFile = (file) => {
    const deletedFiles = filesAdded.findIndex(
      (fileName) => fileName.path === file.path
    );
    if (deletedFiles !== -1) {
      filesAdded.splice(deletedFiles, 1);
      setFilesAdded([...filesAdded]);
    }
  };

  useEffect(() => {
    if (
      !state ||
      state?.isMyDocument ||
      state?.isEmailCreateTask ||
      state?.isMessage
    ) {
      const updatedStartDate = InitialValues.startDate;
      const updatedDueDate = InitialValues.dueDate;

      setStartDateTZ(getMoment(updatedStartDate).toISOString());
      setDueDateTZ(getMoment(updatedDueDate).toISOString());
      formikRef.current.setFieldValue("startTime", getDayJs());
      formikRef.current.setFieldValue("dueTime", getDayJs());
    }
  }, [state]);

  useEffect(() => {
    selectedCompany && getProjectList({ companyId: [selectedCompany] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    if (projectData?.data && isProjectSuccess) {
      const updatedOptions = projectData?.data.map((item) => ({
        label: item?.projectNumber
          ? `#${item?.projectNumber} ${item?.title}`
          : item?.title,
        value: item?._id,
      }));
      setProjectOptions([defaultProjectOptions, ...updatedOptions]);
      if (state?.edit && formikRef?.current?.values.projectId) {
        const getProject = updatedOptions.find(
          (item) => item.value === formikRef?.current?.values.projectId
        );
        setSelectedProject(getProject);
      }
    }
    if (isProjectError) {
      showToast({
        message:
          projectError?.message || Translate("common:unknownServerError"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, isProjectSuccess, isProjectError]);

  /********************************************************************** OnSubmit Function ****************************************************/
  const onSubmit = (taskFormValues) => {
    let b64Array = [];
    let oldAttachment = [];
    if (state?.isReAllocate) {
      if (state?.taskDetail?.assignee?._id === taskFormValues?.assignTo) {
        return showToast({
          message: Translate("taskDetails:reAssignUserTaskError"),
          type: "error",
        });
      } else {
        const reqBody = {
          taskId: state?.taskDetail?._id,
          assignTo: taskFormValues?.assignTo,
        };

        return reAllocateTaskToAssignee(reqBody);
      }
    }

    // ReAssignee flow for rejected status
    if (state?.isRejected) {
      const reqBody = {
        taskId: state?.taskDetail?._id,
        assignTo: taskFormValues?.assignTo,
      };

      return reAssignTask(reqBody);
    }

    if (filesAdded.length > 0) {
      filesAdded.map((fileItem) => {
        if (fileItem.b64) {
          b64Array.push({
            url: fileItem?.b64 ? fileItem?.b64 : fileItem?.url,
            type: fileItem?.type,
            taskFileName: fileItem?.name || fileItem?.path,
            taskFileExt: fileItem?.taskFileExt
              ? fileItem?.taskFileExt
              : getNameAndExtFromFile(fileItem?.name)?.length &&
                getNameAndExtFromFile(fileItem?.name)[1],
          });
        } else {
          const fileObj = {
            url: fileItem?.url,
            type: fileItem?.type,
            taskFileName: fileItem?.name || fileItem?.path,
            taskFileExt: fileItem?.taskFileExt
              ? fileItem?.taskFileExt
              : getNameAndExtFromFile(fileItem?.name)?.length &&
                getNameAndExtFromFile(fileItem?.name)[1],
          };
          oldAttachment.push(fileObj);
          b64Array.push(fileObj);
        }
      });
    }
    // if (filesAdded?.length > 0) {
    //   b64Array = filesAdded.map((ele) => ({
    //     url: ele?.b64,
    //     type: ele?.type,
    //     taskFileName: ele?.name,
    //     taskFileExt: ele?.name.split(".").pop(),
    //   }));
    // }

    let requestObj = {
      company: state?.edit
        ? state?.taskDetail?.company?._id
        : taskFormValues.company,
      title: taskFormValues.title,
      projectId:
        taskFormValues.projectId === "null" ? null : taskFormValues.projectId,
      description: taskFormValues.description,
      assignTo: taskFormValues.assignTo,
      startDate: moment(taskFormValues.startDate).format(
        DateTimeFormats.YearMonthDay
      ),
      dueDate: moment(taskFormValues.dueDate).format(
        DateTimeFormats.YearMonthDay
      ),
      startTime: dayjs(taskFormValues.startTime).format(DateTimeFormats.Time),
      dueTime: dayjs(taskFormValues.dueTime).format(DateTimeFormats.Time),
      priority: taskFormValues.priority,
      reportTo: taskFormValues.reportTo,
      relatedTaskName: taskFormValues.relatedTaskName,
      isCritical: taskFormValues.markAsCritical,
      attachment: state?.edit ? oldAttachment : [],
      webAttachment: b64Array,
      startDateObject: state?.edit
        ? state?.taskDetail?.startDateObject
        : startDateTZ,
      dueDateObject: dueDateTZ,
      // voiceNote: {
      //   link: "",
      //   timeLength: "",
      //   buffer: [],
      // },
    };

    if (state?.isMyDocument) {
      requestObj["documentId"] = state?.documentId;
    }

    if (state?.isEmailCreateTask) {
      requestObj["emailId"] = state?.emailId;
    }

    if (state?.isMessage) {
      requestObj["messageId"] = state?.messageId;

      if (state?.moduleType === messageModuleType?.CHAT_LIST) {
        requestObj["chatId"] = state?.chatId;
      }

      if (state?.moduleType === messageModuleType?.GROUP_LIST) {
        requestObj["groupId"] = state?.chatId;
      }
    }

    let isDueDateError = false;

    if (state?.edit) {
      //const currentDate = formatDateYYYYMMDD();
      //const dueDate = formatDateYYYYMMDD(taskFormValues?.dueDate);

      /* if (dueDate <= currentDate) {
        setStartDateForDue(true);
        showToast({
          message: Translate("addTask:beforeDateError"),
          type: "error",
        });
        isDueDateError = true;
      } else {
        formikRef.current.setFieldValue("dueDate", taskFormValues?.dueDate);
      } */

      formikRef.current.setFieldValue("dueDate", taskFormValues?.dueDate);
      requestObj = {
        ...requestObj,
        taskId: state?.taskDetail?._id,
        taskNumber: state?.taskDetail?.taskNumber,
      };
    }

    if (!isDueDateError) {
      if (addSubtask) {
        setAddSubtask(false);
      }

      if (reg2.test(taskFormValues.description)) {
        state?.edit ? editTask(requestObj) : addTask(requestObj);
      } else {
        showToast({
          message: Translate("addTask:descriptionInputError"),
          type: "error",
        });
      }
    }
  };

  /********************************************************************** UpdateDate Function ****************************************************/
  const updateDate = (dateValue, fromStartDate) => {
    const dueDate = dateValue ? moment(dateValue).add(1, "days").toDate() : "";

    if (fromStartDate) {
      formikRef.current.setFieldValue("startDate", dateValue);
      let tempStartDateTZ = moment(dateValue).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );

      formikRef.current.setFieldValue("dueDate", dueDate);
      let tempDueDateTZ = moment(dueDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      // update time
      formikRef.current.setFieldValue("startTime", getDayJs());
      formikRef.current.setFieldValue("dueTime", getDayJs());

      setStartDateTZ(
        convertDueDateUTC(
          tempStartDateTZ,
          getMoment().add(1, "minute").toDate().toISOString()
        )
      );

      setDueDateTZ(
        convertDueDateUTC(
          tempDueDateTZ,
          getMoment().add(1, "minute").toDate().toISOString()
        )
      );
    } else {
      // from dueDate
      if (
        dateValue &&
        moment(dateValue).format("YYYY-MM-DD") ===
          moment(formikRef.current.values.startDate).format("YYYY-MM-DD")
      ) {
        // if both date is same date
        showToast({
          message: Translate("addTask:sameDateError"),
          type: "error",
        });

        formikRef.current.setFieldValue("dueDate", dueDate);
        setDueDateTZ(moment(dueDate).toISOString());
      } else {
        formikRef.current.setFieldValue("dueDate", dateValue);
        const tempDueTime = state?.edit
          ? dueDateTZ
          : dayjs(formikRef.current.values.dueTime).toISOString();

        const dueDateWithTz = convertDueDateUTC(
          moment(dateValue).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          tempDueTime
        );

        setDueDateTZ(dueDateWithTz);
      }
    }
  };

  /********************************************************************** Return Start ****************************************************/
  const resetOnCompanyChange = () => {
    setAssignTo("");
    setAssignToOptionList([assignToOptionList[0]]);
    formikRef.current.setFieldValue("assignTo", "");
    // setReportTo("");
    // formikRef.current.setFieldValue("reportTo", "");
    setRelatedTaskData([]);
    formikRef.current.setFieldValue("relatedTaskName", "");
    setSelectedProject("");
    setProjectOptions([]);
  };

  const handleSubtask = () => {
    setAddSubtaskLoading(true);
    formikRef.current.submitForm();
  };

  return (
    <>
      <Formik
        initialValues={InitialValues}
        innerRef={formikRef}
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={addTaskSchema}
      >
        {({ values, setFieldValue, handleSubmit, errors, isValid }) => {
          return (
            <div className="row container_spacing">
              <div className="card card_spacing">
                <PageHeader
                  title={
                    state?.edit
                      ? Translate("addTask:editTaskHeading")
                      : Translate("addTask:addTaskHeading")
                  }
                  onClick={() => navigate(-1)}
                />

                <div
                  className="card-body"
                  onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
                >
                  <div className="row">
                    <div className="form-group col-md-6 col-sm-12">
                      <FormikTextField
                        name="title"
                        label={Translate("addTask:taskName")}
                        placeholder={Translate("addTask:taskName")}
                        autoComplete={"off"}
                        maxLength={validations?.taskName.MAX}
                        required
                        disabled={state?.isReAllocate || state?.isRejected}
                        autoFocus={!state}
                      />
                    </div>

                    <div className="form-group col-md-3 col-sm-12">
                      {state?.edit ? (
                        <FormikTextField
                          name="company"
                          label={Translate(
                            "addTask:companyDropdownPlaceholder"
                          )}
                          placeholder={Translate(
                            "addTask:companyDropdownPlaceholder"
                          )}
                          editable={false}
                          disabled={state?.edit}
                          required
                        />
                      ) : (
                        <FormikDropdownPicker
                          options={allCompanyData}
                          value={selectedCompany}
                          name="company"
                          label={Translate(
                            "addTask:companyDropdownPlaceholder"
                          )}
                          placeholder={Translate(
                            "addTask:companyDropdownPlaceholder"
                          )}
                          onSelect={(value) => {
                            setSelectedCompany(value);
                            selectedCompany !== value && resetOnCompanyChange();
                          }}
                          required
                        />
                      )}
                    </div>

                    {state?.edit &&
                    (!values.projectId || values.projectId == "null") ? null : (
                      <div className="form-group col-md-3 col-sm-12 projectTask">
                        <FormikSearchableDropDown
                          selected={selectedProject}
                          onSelect={(item) => {
                            setSelectedProject(item);
                            setFieldValue("projectId", item?.value);
                          }}
                          options={projectOptions}
                          placeholder={Translate("addTask:projectPlaceholder")}
                          label={Translate("addTask:project")}
                          name="projectId"
                          required
                          menuStyle={{ direction: "ltr" }}
                          disabled={state?.edit}
                        />
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <FormikTextField
                      name="description"
                      label={Translate("addTask:description")}
                      placeholder={Translate("addTask:descriptionPlaceholder")}
                      autoComplete={"off"}
                      multiline
                      noOfRows={3}
                      maxLength={validations?.taskDescription.MAX}
                      required
                      disabled={state?.isReAllocate || state?.isRejected}
                    />
                  </div>

                  <div className="row">
                    <div className="form-group col-md-4 col-sm-12">
                      <FormikSearchableDropDown
                        selected={assignTo}
                        onSelect={(value) => {
                          setAssignTo(value);
                          setFieldValue("assignTo", value.id);
                        }}
                        options={assignToOptionList}
                        onChange={(text) => setSearchTextAssign(text)}
                        placeholder={Translate("addTask:assignToPlaceholder")}
                        label={Translate("addTask:assignTo")}
                        name="assignTo"
                        required
                      />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                      <FormikSearchableDropDown
                        selected={reportTo}
                        onSelect={(value) => {
                          setReportTo(value);
                          setFieldValue("reportTo", value.id);
                        }}
                        onChange={(text) => setSearchTextReport(text)}
                        options={reportToOptionList}
                        placeholder={Translate("addTask:reportTo")}
                        label={Translate("addTask:reportTo")}
                        name="reportTo"
                        disabled
                        required
                      />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                      <FormikDropdownPicker
                        options={relatedTaskData}
                        // value={state ? state?.taskDetail?.relatedTaskName : ""}
                        name="relatedTaskName"
                        label={Translate("addTask:relatedTask")}
                        placeholder={Translate(
                          "addTask:relatedTaskPlaceholder"
                        )}
                        onSelect={(value) =>
                          setFieldValue("relatedTaskName", value)
                        }
                        disabled={state?.isReAllocate || state?.isRejected}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-3 col-sm-12">
                      <FormikDatePicker
                        label={Translate("addTask:startDate")}
                        containerStyle="datePickerContainer"
                        className="form-control bw-0"
                        format="yyyy/MM/dd"
                        name="startDate"
                        value={values.startDate}
                        onChange={(value) => {
                          updateDate(value, true);
                        }}
                        minDate={
                          state?.edit
                            ? new Date(state.startDate)
                            : getMoment().toDate()
                        }
                        disabled={state?.edit}
                        isprofile
                        required
                      />
                    </div>
                    <div className="form-group col-md-3 col-sm-12">
                      <FormikDatePicker
                        label={Translate("addTask:startTime")}
                        format="HH:mm"
                        name="startTime"
                        value={values.startTime}
                        onChange={(event) => {
                          if (dayjs(event).format() !== "Invalid Date") {
                            setFieldValue("startTime", dayjs(event));
                            setFieldValue("dueTime", dayjs(event));
                            const utcDate = dayjs(event).toISOString();
                            const startDateWithTz = convertDueDateUTC(
                              startDateTZ,
                              utcDate
                            );
                            setStartDateTZ(startDateWithTz);

                            const dueDateWithTz = convertDueDateUTC(
                              dueDateTZ,
                              utcDate
                            );
                            setDueDateTZ(dueDateWithTz);
                          }
                        }}
                        isTimePicker
                        disabled={state?.edit}
                        isRowError={
                          !!errors.dueTime ||
                          !!errors.startDate ||
                          !!errors.dueDate
                        }
                        required
                      />
                    </div>

                    <div className="form-group col-md-3 col-sm-12">
                      <FormikDatePicker
                        label={Translate("addTask:dueDate")}
                        containerStyle="datePickerContainer"
                        className="form-control bw-0"
                        format="yyyy/MM/dd"
                        name="dueDate"
                        required
                        value={values.dueDate}
                        onChange={(value) => {
                          updateDate(value, false);
                        }}
                        minDate={
                          startDateForDue
                            ? getMoment().toDate()
                            : values.startDate
                        }
                        disabled={state?.isReAllocate || state?.isRejected}
                      />
                    </div>

                    <div className="form-group col-md-3 col-sm-12">
                      <FormikDatePicker
                        label={Translate("addTask:dueTime")}
                        format="HH:mm"
                        name="dueTime"
                        value={values.dueTime}
                        onChange={(event) =>
                          setFieldValue("dueTime", dayjs(event))
                        }
                        isTimePicker
                        disabled
                        isRowError={
                          !!errors.startTime ||
                          !!errors.startDate ||
                          !!errors.dueDate
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <Priority
                      label={Translate("addTask:priority")}
                      value={values.priority}
                      onPress={(value) => setFieldValue("priority", value)}
                      defaultCheck={values.markAsCritical}
                      markAsCriticalState={
                        state?.isMyDocument ||
                        state?.isEmailCreateTask ||
                        state?.isMessage
                          ? false
                          : state
                      }
                      disabled={state?.isReAllocate || state?.isRejected}
                    />
                  </div>

                  {(!state || state?.isMyDocument || state?.isMessage) && (
                    <div className="row">
                      <div className="form-group subtaskPreviewBtn">
                        <div
                          className={`addSubtaskFormBox ${
                            isValid && "addSubtaskFormBox--active"
                          }`}
                          onClick={() => isValid && setAddSubtask(true)}
                        >
                          <i className="fa fa-plus-square-o subtaskIcon" />
                          <span>{Translate("addTask:subtaskButton")}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {!state?.isReAllocate && !state?.isRejected && (
                    <div className="display" onKeyDown={stopEventPropogation}>
                      <Dropzone
                        translate={Translate("addTask:attach")}
                        icon={"icon-paper-clip"}
                        onfileSelect={(filesArray) => addFiles(filesArray)}
                        acceptedImages={acceptedFileType}
                        maximumFiles={10}
                        maxFileSize={52428800}
                        fileLength={filesAdded.length}
                      />
                    </div>
                  )}
                  <div className="AttachmentBoxStyling">
                    <CommonImage
                      images={images}
                      files={filesAdded}
                      removeFile={removeFile}
                      icon={state?.isReAllocate || state?.isRejected}
                    />
                  </div>
                </div>

                <div className="card-footer text-right line_spacing">
                  <button
                    type="submit"
                    className="btn btn-primary saveButton"
                    onClick={() => {
                      handleSubmit();
                      setAddSubtask(false);
                    }}
                  >
                    {state?.edit
                      ? Translate("addTask:save")
                      : Translate("addTask:add")}
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
      <Loader
        loading={
          addTaskLoading ||
          editTaskLoading ||
          loadingAssigneToData ||
          loadingReportToData ||
          loadingRelatedTask ||
          fetchRelatedTask ||
          isReAllocateLoading ||
          isReAssignTaskLoading ||
          isProjectLoading
        }
      />

      <SubtaskPermissionModal
        show={addSubtask}
        title={Translate("addTask:AddSubtaskAlert")}
        onOk={handleSubtask}
        onCancel={() => setAddSubtask(false)}
      />
    </>
  );
};

export default AddTask;
