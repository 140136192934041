import Translate from "../../../translation/utils/translateFunction";

export const getMyLeaveRequestColumns = (
  CustomSrCellRender,
  CustomActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },

    {
      field: "date",
      headerName: Translate("reports:appliedFor"),
      flex: 0.35,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) =>
        `${params.row?.formattedFromDate} to ${params.row?.formattedEndDate}`,
    },
    {
      field: "duration",
      headerName: Translate("leaveRequest:leaveDur"),
      flex: 0.25,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) =>
        params?.row?.duration === "1"
          ? `${params.row?.duration} Day`
          : `${params.row?.duration} Days`,
    },
    {
      field: "createdDate",
      headerName: Translate("leaveRequest:requestedDate"),
      flex: 0.33,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { formattedCreationDate, formattedTime } = params?.row;
        return (
          <div>
            <div className="text-info">{formattedCreationDate}</div>
            <div className="text-pink">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      field: "leaveStatus",
      headerName: Translate("leaveRequest:status"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: Translate("common:actionTitle"),
      align: "center",
      headerAlign: "center",
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomActionCellRender,
    },
  ];

  return columns;
};
