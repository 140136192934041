import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import CompanyHeader from "../../../common/CompanyHeader";
import { userTypes } from "../../../common/users/userTypes";
import {
  getFilterNavigate,
  moduleTypes,
} from "../../../common/Utils/commonFunction";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  useDeleteContactMutation,
  useGetPrivateListingQuery,
  useGetShareContactRequestMutation,
  useMarkAsPublicMutation,
} from "../../../request/ContactRepo";
import Translate from "../../../translation/utils/translateFunction";
import AlertPermission from "../../AlertPermission";
import Searchbar from "../../common/Searchbar";
import ListView from "../../ListView";
import ShareWithUser from "../../ShareWithUser";
import { shareModuleType } from "../../ShareWithUser/components/constants";
import Loader from "../../staff/Owner/LoadingComp";
import CompanyListing from "../component";
import { getContactRepoColumns } from "../component/column.data";
import {
  contactypePrivateList,
  PrivateListType,
  UserTypes,
} from "../component/constant";
import RequestContact from "../component/RequestContact";

const ContactRepoPrivateListing = ({ fixNavbar }) => {
  const [loading, setLoading] = useState(true);

  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [privateListDataFetched, setPrivateListDataFetched] = useState();
  const lastPage = useRef(0);
  const contactType = PrivateListType;
  const [debouncedSearch] = useDebounce(search, 500);
  const [selectedRow, setSelectedRow] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [show, setshow] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const [isDeletable, setIsDeletable] = useState();
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const companies = selectedCompanyList?.length
    ? selectedCompanyList?.map((item) => item?.value)
    : [];

  const [selectedContactId, setSelectedContactId] = useState();
  const [showMarkAsPublicPrivate, setShowMarkAsPublicPrivate] = useState(false);
  const [onmarkAsPublic, setMarkAsPublic] = useState();
  const [showRequestContact, setShowRequestContact] = useState(false);
  const [staffMenuList, setStaffMenuList] = useState([]);
  console.log("staffMenuList", staffMenuList);
  const {
    data: privateContactList,
    isError: isErrorPrivateContactList,
    error: errorPrivateContactList,
    refetch: refetchListing,
    isSuccess: successfullPrivateContactList,
    isFetching: isFetchingList,
  } = useGetPrivateListingQuery(
    { currentPageNo, contactType, debouncedSearch },
    { refetchOnMountOrArgChange: true }
  );

  const [
    markAsPublicApi,
    {
      data: markAsPublic,
      isSuccess: successfullyMarkAsPublic,
      isError: isErrorMarkAsPublic,
      error: errorMarkAsPublic,
      isLoading: isLoadingMarkAsPublic,
    },
  ] = useMarkAsPublicMutation();

  const [
    deleteContactApi,
    {
      data: deleteContact,
      isSuccess: successfullyDeleteContact,
      isLoading: isContactDeleteLoading,
    },
  ] = useDeleteContactMutation();

  const [
    shareContactApi,
    {
      data: shareContactData,
      isSuccess: isShareContactSuccess,
      isLoading: isShareContactLoading,
      isError: isShareContactError,
      error: shareContactError,
    },
  ] = useGetShareContactRequestMutation();

  useEffect(() => {
    if (privateContactList && successfullPrivateContactList) {
      const { nodes, pageInfo } = privateContactList.data;
      setPrivateListDataFetched(nodes);

      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
    }
    if (isErrorPrivateContactList && errorPrivateContactList) {
      showToast({ message: "Failed to fetch the data", type: "error" });
      setLoading(false);
    }
  }, [successfullPrivateContactList, privateContactList]);

  useEffect(() => {
    if (errorMarkAsPublic && isErrorMarkAsPublic) {
      errorMarkAsPublic.data.error &&
        showToast({
          message: errorMarkAsPublic?.data?.message,
          type: "error",
        });
    }

    if (successfullyMarkAsPublic && markAsPublic) {
      showToast({ message: markAsPublic.message, type: "success" });
      refetchListing();
      setMarkAsPublic("");
      if (selectedRow.length) {
        setSelectedRow([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    markAsPublic,
    errorMarkAsPublic,
    isErrorMarkAsPublic,
    successfullyMarkAsPublic,
  ]);

  useEffect(() => {
    if (successfullyDeleteContact && deleteContact) {
      showToast({ message: deleteContact.message, type: "success" });
      refetchListing();

      if (selectedRow?.length) {
        setSelectedRow([]);
      } else {
        setDeleteId([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteContact]);

  useEffect(() => {
    if (shareContactData && isShareContactSuccess) {
      showToast({ message: shareContactData?.message, type: "success" });

      refetchListing();
      setSelectedRow([]);
    }

    if (shareContactError && isShareContactError) {
      showToast({
        message:
          shareContactError?.message || Translate("document:apiFailedMessage"),
        type: "error",
      });
    }
  }, [shareContactData, isShareContactSuccess, isShareContactError]);
  useEffect(() => {
    if (userData) {
      const data = userData?.moduleAccess?.find(
        (item) => Object.keys(item)[0] === moduleTypes.Contactprivate
      );
      setStaffMenuList(
        Object.values(data)[0]?.filter((item) => item?.isEnable)
      );
    }
  }, [userData]);
  const deleteContacts = () => {
    if (selectedRow?.length) {
      const requestObject = {
        contacts: selectedRow,
      };
      deleteContactApi(requestObject);
    } else if (isDeletable) {
      const requestObject = {
        contacts: [deleteId],
      };
      deleteContactApi(requestObject);
    } else {
      showToast({
        message: "You don't have access to delete this contact",
        type: "error",
      });
    }

    setshow(false);
  };

  const markAsPublicFunction = () => {
    if (selectedRow?.length) {
      const requestObject = {
        contacts: selectedRow,
      };
      markAsPublicApi({
        body: requestObject,
        contactType: "PUBLIC",
      });
    } else {
      const requestObject = {
        contacts: [onmarkAsPublic],
      };
      markAsPublicApi({
        body: requestObject,
        contactType: "PUBLIC",
      });
    }

    setShowMarkAsPublicPrivate(false);
  };
  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };

  const onCancel = () => setshow(false);
  const onCancelMarkAsPublic = () => setShowMarkAsPublicPrivate(false);

  const customActionCellRender = (params) => {
    const isRowSelected = selectedRow?.includes(params?.id);
    return (
      <>
        <div className="headerMargin">
          <a
            href="/#"
            className={`nav-link dropdown-toggle ${
              isRowSelected ? "disabled-icon" : ""
            }`}
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i
              className={`fa fa-ellipsis-v cursor-pointer manageIconColor ${
                isRowSelected ? "disabled-icon" : ""
              }`}
            />
          </a>
          <div className="dropdown-menu dropdownAlignment dropdownIconMenu">
            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() =>
                navigate("/contactRepository/privateListing/viewBusinessCard", {
                  state: params?.id,
                })
              }
            >
              <span className="manageIconColor fe fe-eye" />
              {Translate("contacts:view")}
            </div>
            {params?.row?.isEditable && (
              <div
                className="manageIconColor ml-3 cursor-pointer dropdown-item"
                onClick={() =>
                  navigate("/contactRepository/privateList/editContact", {
                    state: params?.row,
                  })
                }
              >
                <span className="manageIconColor fa fa-pencil" />
                {Translate("contacts:edit")}
              </div>
            )}

            {params?.row?.isDeletable && (
              <div
                className="manageIconColor ml-3 cursor-pointer dropdown-item"
                onClick={() => {
                  setshow(true);
                  setDeleteId(params?.id);
                  setIsDeletable(params?.row?.isDeletable);
                }}
              >
                <span className="manageIconColor fa fa-trash" />
                {Translate("contacts:delete")}
              </div>
            )}
            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() => {
                setShowMarkAsPublicPrivate(true);
                setMarkAsPublic(params?.id);
              }}
            >
              <span className="manageIconColor fa fa-shield" />
              {Translate("contacts:markAsPublic")}
            </div>
            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() => {
                setShowShareModal(true);
                setSelectedContactId(params?.id);
              }}
            >
              <span className="manageIconColor fe fe-share-2" />
              {Translate("contacts:share")}
            </div>
            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() =>
                navigate("/contactRepository/privateListing/accessLogs", {
                  state: params?.id,
                })
              }
            >
              <span className="manageIconColor fa fa-file-text" />
              {Translate("contacts:accesslogs")}
            </div>
          </div>
        </div>
      </>
    );
  };

  const rowsPerPage = 10;
  const startSerialNumber = (currentPageNo - 1) * rowsPerPage + 1;

  const rowsWithSerialNumbers =
    privateListDataFetched &&
    privateListDataFetched.map((row, index) => ({
      ...row,
      serialNumber: startSerialNumber + index,
    }));
  // const filters = ["OWNER", "PA", "GM", "MANAGER", "EMPLOYEE"];
  // const filteredFilters = userData?.contact.map((item) => item.user);
  const getRoles = (userName) => {
    switch (userName) {
      case "owner":
        return userTypes.Owner.toUpperCase();
      case "personalAssistant":
        return userTypes.persoalAssistant;
      case "generalManager":
        return userTypes.GeneralManager;
      case "manager":
        return userTypes.Manager.toUpperCase();
      case "employee":
        return userTypes.Employee.toUpperCase();
    }
  };
  // const getFilterNavigate = (selectedFilter, index) => {
  //   if (companies.length) {
  //     switch (selectedFilter?.label) {
  //       case UserTypes.OWNER: {
  //         navigate("/contactRepository/privateListing/selectMember", {
  //           state: { userType: UserTypes.OWNER, selectedCompany: companies },
  //         });
  //         break;
  //       }
  //       case UserTypes.PA: {
  //         navigate("/contactRepository/privateListing/selectMember", {
  //           state: { userType: UserTypes.PA, selectedCompany: companies },
  //         });
  //         break;
  //       }
  //       case UserTypes.GM: {
  //         navigate("/contactRepository/privateListing/selectMember", {
  //           state: { userType: UserTypes.GM, selectedCompany: companies },
  //         });
  //         break;
  //       }
  //       case UserTypes.MANAGER: {
  //         navigate("/contactRepository/privateListing/selectMember", {
  //           state: { userType: UserTypes.MANAGER, selectedCompany: companies },
  //         });

  //         break;
  //       }
  //       case UserTypes.EMPLOYEE: {
  //         navigate("/contactRepository/privateListing/selectMember", {
  //           state: { userType: UserTypes.EMPLOYEE, selectedCompany: companies },
  //         });
  //         break;
  //       }

  //       default:
  //         break;
  //     }
  //   } else {
  //     showToast({
  //       type: "info",
  //       message: Translate("contacts:selectComapnyMessage"),
  //     });
  //   }
  // };

  const onCloseShareModal = () => setShowShareModal(false);
  const onCloseRequestContactModal = () => setShowRequestContact(false);

  const handleShareDocument = (users) => {
    const reqObj = {
      contactId: selectedRow?.length ? selectedRow : [selectedContactId],
      users: users?.map((ele) => ele._id),
    };

    shareContactApi(reqObj);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />
                  <div className="card-options">
                    {selectedRow.length > 0 && (
                      <div style={{ display: "flex" }}>
                        <div className="mr-2">
                          <span className=" ml-1">
                            <button
                              className="btn btn-sm btn-default  cutomButtonBorder"
                              type="button"
                              onClick={() => setShowMarkAsPublicPrivate(true)}
                            >
                              <span
                                className="fa fa-shield manageIconColor"
                                style={{ fontSize: 15 }}
                              />
                              <span className="ml-2 buttonTextFontWeight">
                                {Translate("contacts:markAsPublic")}
                              </span>
                            </button>
                          </span>
                        </div>
                        <div className="mr-2">
                          <span className=" ml-1">
                            <button
                              className="btn btn-sm btn-default  cutomButtonBorder"
                              type="button"
                              onClick={() => setShowShareModal(true)}
                            >
                              <span
                                className="fe fe-share-2 manageIconColor"
                                style={{ fontSize: 15 }}
                              />
                              <span className="ml-2 buttonTextFontWeight">
                                {Translate("contacts:share")}
                              </span>
                            </button>
                          </span>
                        </div>
                        <div className="mr-2">
                          <span className=" ml-1">
                            <button
                              className="btn btn-sm btn-default  cutomButtonBorder"
                              type="button"
                              onClick={() => setshow(true)}
                            >
                              <span
                                className="fa fa-trash manageIconColor"
                                style={{ fontSize: 15 }}
                              />
                              <span className="ml-2 buttonTextFontWeight">
                                {Translate("contacts:deleteContact")}
                              </span>
                            </button>
                          </span>
                        </div>
                      </div>
                    )}

                    {userData?.primary === "NO" ||
                    (userData?.primary === "YES" &&
                      userData?.role.type === UserTypes?.PA) ? (
                      <div className="header-action pull-right mx-2">
                        <button
                          type="button"
                          className="btn btn-primary py-2"
                          onClick={() => setShowRequestContact(true)}
                        >
                          <StyledTypography className="mx-1" component="span">
                            {Translate("contacts:requestContact")}
                          </StyledTypography>
                        </button>
                      </div>
                    ) : null}

                    <div>
                      <div className="input-group">
                        <Searchbar
                          handleSearchText={handleSearchText}
                          setSearch={setSearch}
                        />
                      </div>
                    </div>
                    <div className="header-action pull-right ml-2">
                      <button
                        type="button"
                        className="btn btn-primary py-2"
                        onClick={() =>
                          navigate(
                            "/contactRepository/privateListing/addNewContact",
                            {
                              state: { listType: PrivateListType },
                            }
                          )
                        }
                      >
                        <i className="fe fe-plus" />
                        <StyledTypography className="mx-1" component="span">
                          {Translate("common:add")}
                        </StyledTypography>
                      </button>
                    </div>
                    {!!staffMenuList?.length && (
                      <div className="d-flex ml-2">
                        <div className="mt-2 headerMargin">
                          <a
                            href="/#"
                            className="nav-link dropdown-toggle "
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-v manageIconColor filterIconSize"
                              data-toggle="tooltip"
                              title={Translate("contacts:filter")}
                            />
                          </a>
                          <div className="dropdown-menu dropdownAlignment">
                            {staffMenuList?.map((filterItem, index) => (
                              <>
                                <div
                                  onClick={() =>
                                    getFilterNavigate({
                                      selectedFilter: filterItem,
                                      navigate: navigate,
                                      companies: companies,
                                    })
                                  }
                                >
                                  <a className="dropdown-item">
                                    {Translate(`contacts:${filterItem?.label}`)}
                                  </a>
                                </div>
                                {staffMenuList?.length - 1 !== index && (
                                  <div className="dropdown-divider" />
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getContactRepoColumns(customActionCellRender)}
                    rows={rowsWithSerialNumbers}
                    autoHeight
                    onPaginationChange={setCurrentPageNo}
                    currentPage={currentPageNo}
                    showShimmer={isFetchingList}
                    showLoader={
                      loading || isShareContactLoading || isFetchingList
                    }
                    totalPageCount={lastPage.current}
                    checkboxSelection={true}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedRow(newRowSelectionModel);
                    }}
                    disableRowSelectionOnClick
                    disableColumnSelector={false}
                    rowSelectionModel={selectedRow}
                    isRowSelectable={(params) => params?.row?.isDeletable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CompanyListing
          handleModal={(value) => {
            setCurrentPageNo(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompanyList}
        />
        {showShareModal && (
          <ShareWithUser
            currentModule={shareModuleType?.CONTACT_REPO}
            showModal={showShareModal}
            closeModal={onCloseShareModal}
            onSubmit={handleShareDocument}
            showMobile
          />
        )}
        {showRequestContact && (
          <RequestContact
            showModal={showRequestContact}
            closeModal={onCloseRequestContactModal}
            onSuccessfulRequestContact={(event) => setShowRequestContact(event)}
            contactListType={contactypePrivateList}
          />
        )}

        <Loader loading={isLoadingMarkAsPublic || isContactDeleteLoading} />

        <AlertPermission
          show={show}
          subTitle={Translate("contacts:deleteSingleContactAlertMsg")}
          onOk={deleteContacts}
          onCanel={onCancel}
        />
        <AlertPermission
          show={showMarkAsPublicPrivate}
          subTitle={Translate("contacts:markAsPublicAlertMessage")}
          onOk={markAsPublicFunction}
          onCanel={onCancelMarkAsPublic}
        />
      </StyledCard>
    </Grid>
  );
};

export default ContactRepoPrivateListing;
